import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../../../utils'
import axios from 'axios'
import { toast } from 'react-toastify'

export default function CourseCatalog() {
  const navigate = useNavigate()
  const baseUrl = BASE_URL
  const token = localStorage.getItem('token')

  const [courses, setCourses] = useState([])

  useEffect(() => {
    if (!courses.length) {
      getCourses()
    }
  })

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const getCourses = () => {
    axios
      .get(baseUrl + '/courses', config)
      .then(response => {
        setCourses(response.data.courses)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  return (
    <div>
      <div className="row mt-5">
        <div className="cursor-pointer col-lg-12 col-md-12 col-12">
          <div className="mb-5">
            <h2 className="mb-1">All Courses</h2>
          </div>
        </div>
      </div>
      <div className="row">
        {courses.map(el => (
          <div
            onClick={() => navigate(`/dashboard/course-details/${el.id}`)}
            key={el.name}
            className="col-lg-3 col-md-6 col-12 cursor-pointer"
          >
            <div className="card mb-4 card-hover">
              <div className="card-body">
                <h3 className="h4 mb-2 text-truncate-line-2">
                  <Link
                    className="text-inherit"
                    to={`/dashboard/course-details/${el.id}`}
                  >
                    {el.name}
                  </Link>
                </h3>
                <ul className="mb-3 list-inline">
                  <li className="list-inline-item">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="currentColor"
                        className="bi bi-clock align-baseline"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                      </svg>
                    </span>
                    <span>&nbsp; {el.duration}</span>
                  </li>
                </ul>
                <div className="lh-1">
                  <span className="align-text-top">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      fill="currentColor"
                      className="bi bi-star-fill text-warning"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                    </svg>
                  </span>
                  &nbsp;
                  <span className="text-warning">{Math.ceil(el.rating)}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
