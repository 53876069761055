import { Outlet, useNavigate } from "react-router-dom";
import DashBoardHeader from "./DashBoardHeader";
import NavBar from "./NavBar";
import { useState } from "react";

export default function DashBoardLayout() {
  useNavigate();
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div id="db-wrapper" className={toggleMenu ? "toggled" : ""}>
      <NavBar />
      <main id="page-content">
        <DashBoardHeader
          toggleMenu={toggleMenu}
          setToggleMenu={setToggleMenu}
        />
        <section className="container-fluid p-4">
          <Outlet />
        </section>
      </main>
    </div>
  );
}
