import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import axios from "axios";
import Select from "react-select";
import { BASE_URL } from "../../../../utils";

function ManageSubmissions() {
  const baseUrl = BASE_URL;
  const token = localStorage.getItem("token");

  const [mode, setMode] = useState("add");
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState("");
  const [submissions, setSubmissions] = useState([]);
  const [submission, setSubmission] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [due, setDue] = useState(new Date().toISOString().substr(0, 16));
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState("");
  const [studentName, setStudentName] = useState("");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    if (!courses.length) {
      getCourses();
      getSubmissions();
    }
  });

  const getCourses = () => {
    axios
      .get(baseUrl + "/courses", config)
      .then((response) => {
        let crs = [];
        for (const course of response.data.courses) {
          crs.push({ value: course.id, label: course.name });
        }
        setCourses(crs);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const getSubmissions = () => {
    axios
      .get(baseUrl + "/submissions", config)
      .then((response) => {
        setSubmissions(response.data.submissions);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const clearFields = () => {
    setCourse("");
    setTitle("");
    setDescription("");
    setDue(new Date().toISOString().substr(0, 16));
    setFile(null);
    setStatus("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (mode === "add") addSubmission();
    else updateSubmission();
  };

  const addSubmission = () => {
    const formData = new FormData();
    formData.append("course_id", course);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("due", due);
    formData.append("file", file);
    formData.append("status", status);

    axios
      .post(baseUrl + "/submission", formData, config)
      .then((response) => {
        toast.success(response.data.message);
        getSubmissions();
        clearFields();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const updateSubmission = () => {
    const formData = new FormData();
    formData.append("course_id", course);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("due", due);
    formData.append("grade", status.value);
    formData.append("feedback", description);

    axios
      .post(baseUrl + "/submission/" + submission.id, formData, config)
      .then((response) => {
        toast.success(response.data.message);
        getSubmissions();
        clearFields();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const editSubmission = (ass) => {
    console.log("ass", ass);
    setMode("edit");
    setSubmission(ass);
    setCourse(ass.course_id);
    setTitle(ass.assignment.title);
    setDescription(ass.feedback);
    setDue(ass.due);
    setStatus(ass.grade);
    setFile(ass.file);
    setStudentName(ass.student.name);
  };

  const options = [
    { value: "A", label: "A" },
    { value: "A+", label: "A+" },
    { value: "B", label: "B" },
    { value: "B+", label: "B+" },
    { value: "C", label: "C" },
    { value: "C+", label: "C+" },
  ];

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-header">
        <h3 className="mb-0">Manage Submissions</h3>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form>
          <div className="row">
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Submission</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  disabled
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Student Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setStudentName(e.target.value)}
                  value={studentName}
                  disabled
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Submission File
                </label>
                <br />
                <Link
                  to={file}
                  target="_blank"
                  className="btn btn-primary mt-1 mb-0 font-xsss p-2"
                  style={{ width: "100%" }}
                  download
                >
                  Download File
                </Link>
              </div>
            </div>
            <div className="col-lg-8 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Feedback</label>
                <textarea
                  className="w-100 h100 style2-textarea p-3 form-control"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Grade</label>
                <Select
                  value={status}
                  onChange={(e) => setStatus(e)}
                  options={options}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-right">
              <button
                type="button"
                className="btn btn-primary mt-3 mb-0"
                onClick={handleSubmit}
              >
                {mode === "add" ? "Add" : "Update"} Submission
              </button>
            </div>
          </div>
        </form>
      </div>
      {submissions.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th>Submission</th>
                <th>Student Name</th>
                <th>File</th>
                <th>Feedback</th>
                <th>Grade</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {submissions.map((assign) => (
                <tr key={assign.id}>
                  <td>{assign.id}</td>
                  <td>{assign?.student?.name}</td>
                  <td>
                    <Link
                      className="text-primary"
                      to={assign.file}
                      target="_blank"
                    >
                      Download
                    </Link>
                  </td>{" "}
                  <td>{assign.feedback || " Yet to be reviewed"}</td>
                  <td>{assign.grade || "Yet to be reviewed"}</td>
                  <td className="text-center">
                    <Link onClick={() => editSubmission(assign)}>Edit</Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default ManageSubmissions;
