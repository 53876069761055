import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Select from "react-select";
import { BASE_URL } from "../../../../utils";

export default function StudentPerformanceData() {
  const baseUrl = BASE_URL;
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));

  const [reports, setReports] = useState(null);
  const [reportName, setReportName] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filters, setFilters] = useState([]);
  const [filter, setFilter] = useState("");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const filterTypes = [
    { value: "assignments", label: "Assignments" },
    { value: "exams", label: "Exams" },
    { value: "students", label: "Students" },
  ];

  useEffect(() => {
    if (!reports) {
      getReports();
    }
  });

  const getReports = () => {
    axios
      .get(baseUrl + "/reports", config)
      .then((response) => {
        setReports(response.data.reports);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isError = false;
    if (!reportName.length) {
      isError = true;
      toast.error("Report Name is Required");
    }
    if (!filterType?.value) {
      isError = true;
      toast.error("Filter Type is Required");
    }
    if (!filter?.value) {
      isError = true;
      toast.error("Filter is Required");
    }
    if (isError) {
      return;
    }
    addReport();
  };

  const addReport = () => {
    axios
      .post(
        baseUrl + "/report",
        {
          user_id: user.id,
          name: reportName,
          filter_id: filter.value,
          filter: filterType.value,
        },
        config
      )
      .then((response) => {
        toast.success(response.data.message);
        getReports();
        clearFields();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const clearFields = () => {
    setReportName("");
    setFilterType("");
    setFilter("");
  };

  const handleFilterTypeChange = (obj) => {
    setFilter("");
    setFilterType(obj);
    populateFilters(obj);
  };

  const populateFilters = (filter_type) => {
    if (filter_type.value === "assignments") {
      axios
        .get(baseUrl + "/assignments", config)
        .then((response) => {
          let assignments = [];
          response.data.assignments.forEach((assignment) => {
            assignments.push({ value: assignment.id, label: assignment.title });
          });
          setFilters(assignments);
        })
        .catch((error) => {
          const tempdata = error.response.data;
          toast.error(`${tempdata.message}`);
        });
    } else if (filter_type.value === "exams") {
      axios
        .get(baseUrl + "/exams", config)
        .then((response) => {
          let exams = [];
          response.data.exams.forEach((exam) => {
            exams.push({ value: exam.id, label: exam.name });
          });
          setFilters(exams);
        })
        .catch(function (error) {
          const tempdata = error.response.data;
          toast.error(`${tempdata.message}`);
        });
    } else if (filter_type.value === "students") {
      axios
        .get(baseUrl + "/users", config)
        .then((response) => {
          let students = [];
          response.data.users.forEach((user) => {
            if (user.role === "student") {
              students.push({ value: user.id, label: user.name });
            }
          });
          setFilters(students);
        })
        .catch((error) => {
          const tempdata = error.response.data;
          toast.error(`${tempdata.message}`);
        });
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">
          {/* page header */}
          <div className="border-bottom pb-3 mb-3 d-lg-flex justify-content-between align-items-center">
            <div className="mb-3 mb-lg-0">
              <h1 className="mb-0 h2 fw-bold">DashBoard</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header">
          <h3 className="mb-0">Student Performance Data </h3>
        </div>
        <div className="card-body">
          <form className="row">
            <div className="mb-3 col-12 col-md-6">
              <label className="form-label" htmlFor="fname">
                Report Name
              </label>
              <input
                type="text"
                name="report-name"
                className="form-control"
                onChange={(e) => setReportName(e.target.value)}
                value={reportName}
                placeholder="Report Name"
                required
              />
            </div>
            <div className="mb-3 col-12 col-md-6">
              <label className="form-label" htmlFor="lname">
                Filter Type
              </label>
              <Select
                isSearchable={false}
                options={filterTypes}
                value={filterType}
                onChange={(obj) => handleFilterTypeChange(obj)}
                placeholder="Select Filter Type"
              />
            </div>
            <div className="mb-3 col-12 col-md-6">
              <label className="form-label" htmlFor="lname">
                Filter
              </label>
              <Select
                options={filters}
                value={filter}
                onChange={(obj) => setFilter(obj)}
                placeholder="Select Filter"
              />
            </div>
            <div className="col-12">
              <Link
                type="submit"
                onClick={(e) => handleSubmit(e)}
                className="btn btn-primary"
              >
                Generate Report
              </Link>
            </div>
          </form>
        </div>
        {reports?.length > 0 && (
          <div className="table-responsive p-4">
            <table className="table table-bordered text-nowrap mb-0 table-centered">
              <thead>
                <tr>
                  <th>Report ID</th>
                  <th>Report Name</th>
                  <th>Filter Type</th>
                  <th>Filter</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {reports.map((report) => (
                  <tr key={report.id}>
                    <td>{report.id}</td>
                    <td>{report.name}</td>
                    <td className="text-capitalize">{report.filter}</td>
                    <td>{report.filter_name}</td>
                    <td className="text-center">
                      <Link
                        className="feather-download text-green"
                        style={{ cursor: "pointer" }}
                        to={report.file}
                        target="_blank"
                      >
                        Download
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
