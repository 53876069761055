import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";

import axios from "axios";
import { BASE_URL } from "../../../../utils";

function CourseContent() {
  const baseUrl = BASE_URL;
  const token = localStorage.getItem("token");

  const [allCourses, setAllCourses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState("");
  const [sectionTitle, setSectionTitle] = useState("");
  const [sections, setSections] = useState([]);
  const [lessonTitle, setLessonTitle] = useState([]);
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    if (!courses.length) {
      getCourses();
    }
  });

  const getCourses = () => {
    axios
      .get(baseUrl + "/courses", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let crs = [];
        for (const course of response.data.courses) {
          crs.push({ value: course.id, label: course.name });
        }
        setAllCourses(response.data.courses);
        setCourses(crs);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handleCourseChange = (id) => {
    axios
      .get(baseUrl + "/course/" + id, config)
      .then((response) => {
        setCourse(id);
        setSections(response.data.course.sections);
        setLessons(response.data.course.lessons || []);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const saveCourseContent = (updatedSections) => {
    const activeCourse = allCourses.find((crs) => crs.id === course);
    const newCourse = {
      ...activeCourse,
      sections:
        updatedSections.length > 0
          ? updatedSections.map((section) => section.id)
          : [],
    };

    axios
      .post(baseUrl + "/course/" + course, newCourse, config)
      .then((response) => {
        toast.success(`${response.data.message}`);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const addSection = () => {
    if (!course) {
      toast.error("Please select a Course!");
      return;
    } else if (!sectionTitle.length) {
      toast.error("Section Title is Required!");
      return;
    } else {
      axios
        .post(
          baseUrl + "/section/" + course,
          {
            name: sectionTitle,
          },
          config
        )
        .then((response) => {
          const updatedSections = [
            ...sections,
            {
              id: response.data.section_id,
              name: sectionTitle,
            },
          ];
          setSections(updatedSections);
          setSectionTitle("");
          saveCourseContent(updatedSections);
        });
    }
  };

  const deleteSection = (id, sectionIndex) => {
    axios
      .delete(baseUrl + "/section/" + id, config)
      .then((response) => {
        const updatedLessons = [...lessons];
        updatedLessons[sectionIndex] = [];
        setLessons(updatedLessons);
        setSections((prevSections) =>
          prevSections.filter((section) => section.id !== id)
        );
        const updatedSections = sections.filter((section) => section.id !== id);
        saveCourseContent(updatedSections);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleLessonTitleChange = (value, sectionIndex) => {
    const newTitle = [...lessonTitle];
    newTitle[sectionIndex] = value;
    setLessonTitle(newTitle);
  };

  const addLesson = (section_id, sectionIndex) => {
    axios
      .post(
        baseUrl + "/lesson/" + section_id,
        {
          name: lessonTitle[sectionIndex],
        },
        config
      )
      .then((response) => {
        setLessonTitle("");
        const updatedLessons = [...lessons];
        for (let i = 0; i < sections.length; i++) {
          if (!updatedLessons[i]) {
            updatedLessons[i] = [];
          }
          if (i === sectionIndex) {
            updatedLessons[i].push({
              section_id: section_id,
              id: response.data.lesson_id,
              name: lessonTitle[i],
            });
          }
        }
        setLessons(updatedLessons);
        saveCourseContent(sections);

        const newTitle = [...lessonTitle];
        newTitle[sectionIndex] = "";
        setLessonTitle(newTitle);
      })
      .catch((error) => {
        console.log(error);
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const deleteLesson = (id) => {
    axios
      .delete(baseUrl + "/lesson/" + id, config)
      .then((response) => {
        handleCourseChange(course);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };
  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-header">
        <h3 className="mb-0">Course Content</h3>
      </div>
      <div className="card-body row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="form-label">Course Name</label>
                <Select
                  options={courses}
                  value={courses.find((option) => option.value === course)}
                  onChange={(e) => (e ? handleCourseChange(e.value) : "")}
                  placeholder="Select a Course"
                />
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="dashboard__form__wraper">
              <div className="dashboard__form__input">
                <label className="form-label">Section Title</label>
                <input
                  type="text"
                  name="section-title"
                  className="form-control"
                  onChange={(e) => setSectionTitle(e.target.value)}
                  value={sectionTitle}
                  placeholder="Enter Section Title"
                />
              </div>
              <button onClick={addSection} className="btn btn-primary mt-5">
                Add Section
              </button>
            </div>
          </div>
          <div className="col-xs-12">
            <div className="row px-3">
              {sections.map((section, sectionIndex) => (
                <div
                  key={"section-" + section.title}
                  className="col-lg-6  video-title-form mt-4 p-4"
                  style={{
                    border: "1px dashed",
                  }}
                >
                  <div>
                    <label className="form-label">
                      Classes for Section:{" "}
                      <span className="fw-600">{section.name}</span>
                    </label>
                    <div
                      style={{
                        float: "right",
                      }}
                    >
                      <button
                        onClick={() => deleteSection(section.id, sectionIndex)}
                        className="btn btn-danger"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-trash-2"
                        >
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                          <line x1="10" y1="11" x2="10" y2="17"></line>
                          <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div className="dashboard__form__input">
                    <label className="form-label">Class Title</label>
                    <input
                      type="text"
                      name="section-title"
                      className="form-control"
                      onChange={(e) =>
                        handleLessonTitleChange(e.target.value, sectionIndex)
                      }
                      value={lessonTitle[sectionIndex]}
                      placeholder="Enter Class Title"
                    />
                  </div>

                  <button
                    onClick={() => addLesson(section.id, sectionIndex)}
                    className="btn btn-primary mt-3"
                  >
                    Add Class
                  </button>

                  <div className="card d-block border-0 rounded-lg overflow-hidden mt-4">
                    <div className="mb-0">
                      {lessons.length > 0 &&
                        lessons[sectionIndex]?.map((lesson, lessonIndex) => (
                          <div
                            className="card shadow-xss mb-0"
                            key={lessonIndex}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              className="card-header bg-greylight theme-dark-bg"
                              id="headingOne"
                            >
                              <button
                                className="btn font-xsss fw-600"
                                style={{ cursor: "default" }}
                              >
                                {lesson.name}
                              </button>
                              <Link
                                className="float-right mt-2"
                                onClick={() => deleteLesson(lesson.id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-trash-2"
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </Link>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseContent;
