import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import validator from "validator";
import { BASE_URL } from "../../../utils";
import axios from "axios";
export default function ResetPassword() {
  const baseUrl = BASE_URL;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCpassword] = useState("");
  let isError = false;

  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!email.length) {
      fieldName.push("Email");
    }
    if (!password.length) {
      fieldName.push("Password ");
    }
    if (!cPassword.length) {
      fieldName.push("Confirm Password ");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }

    if (email.length && !validator.isEmail(email)) {
      toast.error("Email is Invalid!");
      isError = true;
    }
    if (password !== cPassword) {
      toast.error("Passwords do not match!");
      isError = true;
    }

    if (isError) {
      return;
    }
    ResetPassword();
  };
  async function ResetPassword() {
    const resetPasswordUrl = baseUrl + "/reset-password";
    const body = {
      email,
      password,
      token,
      password_confirmation: cPassword,
    };
    try {
      const response = await axios.post(resetPasswordUrl, body);
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.response?.data?.message);
    }
  }
  return (
    <div>
      <section className="container d-flex flex-column vh-100">
        <div className="row align-items-center justify-content-center g-0 h-lg-100 py-8">
          <div className="col-lg-5 col-md-8 py-8 py-xl-0">
            <div className="card shadow">
              <div className="card-body p-6">
                <div className="mb-4">
                  <Link to="/">
                    <img
                      src="../assets/images/brand/logo/logobz.png"
                      className="mb-4"
                      alt="logo-icon"
                    />
                  </Link>
                  <h1 className="mb-1 fw-bold">Reset Password</h1>
                  <span>
                    Don't have an account?
                    <Link
                      to="/auth/signup"
                      className="ms-1"
                      style={{ color: "#009688" }}
                    >
                      Sign up
                    </Link>
                  </span>
                </div>
                <form className="needs-validation" noValidate>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      className="form-control"
                      name="email"
                      placeholder="Email address here"
                      required
                    />
                    <div className="invalid-feedback">
                      Please enter valid Email.
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Enter New Password
                    </label>
                    <input
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      className="form-control"
                      name="password"
                      placeholder="**************"
                      required
                    />
                    <div className="invalid-feedback">
                      Please enter valid password.
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Confirm new Password
                    </label>
                    <input
                      type="password"
                      name="cpassword"
                      className="form-control"
                      onChange={(e) => setCpassword(e.target.value)}
                      value={cPassword}
                      placeholder="**************"
                      required
                    />
                    <div className="invalid-feedback">
                      Please enter valid password.
                    </div>
                  </div>

                  <div>
                    <div className="d-grid">
                      <Link
                        type="submit"
                        className="btn btn-primary"
                        onClick={(e) => handleSubmit(e)}
                      >
                        Change Password
                      </Link>
                    </div>
                  </div>
                  <hr className="my-4" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
