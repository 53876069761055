import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../../utils";

export default function Security() {
  const baseUrl = BASE_URL;
  const token = localStorage.getItem("token");

  const [password, setPassword] = useState({
    old: "",
    new: "",
    confirm: "",
  });

  const handleSubmitPassword = async (e) => {
    e.preventDefault();

    if (password.new !== password.confirm) {
      toast.error("Passwords do not match");
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(
        baseUrl + "/change-password",
        {
          current_password: password.old,
          new_password: password.new,
        },
        {
          headers,
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        clearFields();
      })
      .catch(function (error) {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const clearFields = () => {
    setPassword({
      old: "",
      new: "",
      confirm: "",
    });
  };

  const handleChangePasswords = (event) => {
    const { name, value } = event.target;
    setPassword({ ...password, [name]: value });
  };

  return (
    <div className="col-lg-12 col-md-12 col-12">
      <div className="card">
        <div className="card-header">
          <h3 className="mb-0">Security</h3>
          <p className="mb-0">You can change your password here.</p>
        </div>
        <div className="card-body">
          <h4 className="mb-0">Email Address</h4>
          <p>
            Your current email address is{" "}
            <span className="text-success">
              {localStorage.getItem("email")}
            </span>
          </p>
          <hr className="my-5" />
          <div>
            <h4 className="mb-0">Change your Password</h4>
            <p></p>
            <form className="row needs-validation" noValidate>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-3">
                  <label className="form-label" htmlFor="currentpassword">
                    Current password
                  </label>
                  <input
                    id="currentpassword"
                    type="password"
                    name="old"
                    value={password.old}
                    onChange={handleChangePasswords}
                    className="form-control"
                    placeholder
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="newpassword">
                    New password
                  </label>
                  <input
                    id="newpassword"
                    type="password"
                    name="new"
                    value={password.new}
                    onChange={handleChangePasswords}
                    className="form-control mb-2"
                    placeholder
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="confirmpassword">
                    Confirm New Password
                  </label>
                  <input
                    id="confirmpassword"
                    type="password"
                    name="confirm"
                    value={password.confirm}
                    onChange={handleChangePasswords}
                    className="form-control mb-2"
                    placeholder
                    required
                  />
                </div>
                <button
                  onClick={handleSubmitPassword}
                  type="submit"
                  className="btn btn-primary"
                >
                  Change Password
                </button>
                <div className="col-6" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
