import Layout from "./components/layout/Layout";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./components/pages/Home";
import Courses from "./components/pages/Courses";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import Blog from "./components/pages/Blog";
import Login from "./components/pages/auth/Login";
import SignUp from "./components/pages/auth/SignUp";
import ForgotPassword from "./components/pages/auth/ForgotPassword";
import DashBoardLayout from "./components/layout/DashBoard/DashBoardLayout";
import Admin from "./components/pages/DashBoard/Admin";

import ManageUsers from "./components/pages/DashBoard/ManageUsers";
import ManageCourses from "./components/pages/DashBoard/ManageCourses";

import Reports from "./components/pages/DashBoard/Reports";
import Analytics from "./components/pages/DashBoard/Analytics";
import Chat from "./components/pages/DashBoard/Chat";
import Student from "./components/pages/DashBoard/student/Student";
import CourseDetails from "./components/pages/DashBoard/student/CourseDetails";
import LiveClassess from "./components/pages/DashBoard/student/LiveClasses";

import ProgramImplementation from "./components/pages/DashBoard/coordinator/ProgramImplementation";
import SupportEnquiries from "./components/pages/DashBoard/coordinator/SupportEnquiries";
import StudentPerformanceData from "./components/pages/DashBoard/coordinator/StudentPerformanceData";

import GradeStudents from "./components/pages/DashBoard/instructor/GradeStudents";
import CreateExam from "./components/pages/DashBoard/instructor/CreateExam";
import "react-toastify/dist/ReactToastify.css";

import EditProfile from "./components/pages/DashBoard/Settings/EditProfile";
import Security from "./components/pages/DashBoard/Settings/Security";
import ResetPassword from "./components/pages/auth/ResetPassword";
import { ToastContainer } from "react-toastify";
import VerifyEmail from "./components/pages/auth/VerifyEmail";
import AuthLayout from "./components/layout/Auth/AuthLayout";
import Auth from "./components/pages/Auth";
import { AuthProvider } from "./context/authProvider";
import StudentProgressManagement from "./components/pages/DashBoard/instructor/StudentProgressManagement";
import CurriculumDevelopmentUpdates from "./components/pages/DashBoard/coordinator/CurriculumDevelopmentUpdates";
import ProgramEvaluation from "./components/pages/DashBoard/coordinator/ProgramEvalution";
import Exam from "./components/pages/DashBoard/student/Exam";
import CourseContent from "./components/pages/DashBoard/instructor/CourseContent";
import Services from "./components/pages/Services";
import CreateCourses from "./components/pages/DashBoard/instructor/CreateCourses";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./redux/store";
import CourseCatalog from "./components/pages/DashBoard/student/CourseCatalog";
import CourseMaterials from "./components/pages/DashBoard/instructor/CourseMaterials";
import ManageAssignments from "./components/pages/DashBoard/instructor/ManageAssignments";
import ManageSubmissions from "./components/pages/DashBoard/instructor/ManageSubmissions";
import StudentProgress from "./components/pages/DashBoard/student/StudentProgress";

function App() {
  return (
    <ReduxProvider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="courses" element={<Courses />} />
              <Route path="services" element={<Services />} />
              <Route path="about" element={<About />} />
              <Route path="contact" element={<Contact />} />
              <Route path="blog" element={<Blog />} />
            </Route>
            <Route path="/auth" element={<AuthLayout />}>
              <Route path="login" element={<Login />} />
              <Route path="signup" element={<SignUp />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route path="verify-email" element={<VerifyEmail />} />
            </Route>
            <Route
              element={
                <Auth
                  allowedRoles={[
                    "admin",
                    "instructor",
                    "coordinator",
                    "qa",
                    "student",
                  ]}
                />
              }
            >
              <Route path="/dashboard" element={<DashBoardLayout />}>
                <Route
                  path="reports-and-performance"
                  element={<StudentPerformanceData />}
                />
                <Route element={<Auth allowedRoles={["admin"]} />}>
                  <Route path="admin" element={<Admin />} />

                  <Route
                    path="manage-students"
                    element={<ManageUsers manage="Student" />}
                  />
                  <Route
                    path="manage-courses"
                    element={
                      <ManageCourses
                        title="Manage Courses"
                        role="Student"
                        dis="Courses"
                      />
                    }
                  />
                  <Route path="reports" element={<Reports />} />
                  <Route
                    path="manage-instructors"
                    element={<ManageUsers manage="Instructor" />}
                  />
                  <Route path="handle-courses" element={<CreateCourses />} />
                  <Route
                    path="manage-course-content"
                    element={<CourseContent />}
                  />
                  <Route path="handle-exams" element={<CreateExam />} />
                  <Route
                    path="course-materials"
                    element={<CourseMaterials />}
                  />
                  <Route
                    path="manage-assignments"
                    element={<ManageAssignments />}
                  />
                  {/* <Route path="reviews" element={<Reviews />} /> */}
                  <Route
                    path="manage-coordinators"
                    element={<ManageUsers manage="Coordinator" />}
                  />
                  <Route
                    path="assign-supporttickets"
                    element={<SupportEnquiries />}
                  />
                  <Route
                    path="handle-programs"
                    element={<ProgramImplementation />}
                  />
                  <Route path="analytics" element={<Analytics />} />
                </Route>
                <Route element={<Auth allowedRoles={["instructor"]} />}>
                  <Route
                    path="instructor"
                    element={<Navigate to={"/dashboard/create-courses"} />}
                  />
                  <Route
                    path="student-progressmanagement"
                    element={<StudentProgressManagement />}
                  />
                  <Route path="course-content" element={<CourseContent />} />
                  <Route path="create-courses" element={<CreateCourses />} />
                  <Route path="create-exam" element={<CreateExam />} />
                  <Route path="course-material" element={<CourseMaterials />} />
                  <Route
                    path="manage-assignment"
                    element={<ManageAssignments />}
                  />
                  <Route
                    path="manage-submissions"
                    element={<ManageSubmissions />}
                  />

                  <Route path="grade-students" element={<GradeStudents />} />
                </Route>
                <Route element={<Auth allowedRoles={["student"]} />}>
                  <Route path="student" element={<Student />} />
                  <Route path="exam" element={<Exam />} />
                  <Route path="course-catalog" element={<CourseCatalog />} />
                  <Route path="studs-progress" element={<StudentProgress />} />
                  <Route path="live-classes" element={<LiveClassess />} />
                  <Route
                    path="course-details/:id"
                    element={<CourseDetails />}
                  />
                </Route>
                <Route element={<Auth allowedRoles={["coordinator"]} />}>
                  <Route
                    path="coordinator"
                    element={<ProgramImplementation />}
                  />
                  <Route
                    path="program-implementation"
                    element={<ProgramImplementation />}
                  />
                  <Route
                    path="program-evaluation"
                    element={<ProgramEvaluation />}
                  />
                  <Route
                    path="support-enquiries"
                    element={<SupportEnquiries />}
                  />
                  <Route
                    path="student-performancedata"
                    element={<StudentPerformanceData />}
                  />
                  <Route
                    path="curriculum-development"
                    element={<CurriculumDevelopmentUpdates />}
                  />
                </Route>

                <Route path="chat" element={<Chat />} />
                <Route path="edit-profile" element={<EditProfile />} />
                <Route path="security" element={<Security />} />
              </Route>
            </Route>
          </Routes>
          <ToastContainer />
        </AuthProvider>
      </BrowserRouter>
    </ReduxProvider>
  );
}

export default App;
