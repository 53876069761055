import { Link, useLocation } from "react-router-dom";

export default function ManageAdmin() {
  const location = useLocation();

  const handleActive = (link) => {
    let className = "nav-link";
    if (location.pathname.includes(link)) {
      className += " active";
    }

    return className;
  };

  return (
    <div>
      <li className="nav-item">
        <Link
          className="nav-link collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#navCourses"
          aria-expanded="false"
          aria-controls="navCourses"
        >
          <i className="nav-icon fe fe-book me-2" />
          Instructor
        </Link>
        <div id="navCourses" className="collapse" data-bs-parent="#sideNavbar">
          <ul className="nav flex-column">
            <li className="nav-item">
              <Link
                className={handleActive("manage-instructors")}
                to="/dashboard/manage-instructors"
              >
                Manage Instructors
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={handleActive("handle-courses")}
                to="/dashboard/handle-courses"
              >
                Handle Courses
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={handleActive("manage-course-content")}
                to="/dashboard/manage-course-content"
              >
                Manage Course Content
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={handleActive("handle-exams")}
                to="/dashboard/handle-exams"
              >
                Handle Exams
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={handleActive("course-materials")}
                to="/dashboard/course-materials"
              >
                Manage Course Material
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={handleActive("manage-assignments")}
                to="/dashboard/manage-assignments"
              >
                Manage Assignments
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={handleActive("reports-and-performance")}
                to="/dashboard/reports-and-performance"
              >
                Reports & Performance
              </Link>
            </li>
          </ul>
        </div>
      </li>
      {/* Nav item */}
      <li className="nav-item">
        <Link
          className="nav-link collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#navProfile"
          aria-expanded="false"
          aria-controls="navProfile"
        >
          <i className="nav-icon fe fe-user me-2" />
          Student
        </Link>
        <div id="navProfile" className="collapse" data-bs-parent="#sideNavbar">
          <ul className="nav flex-column">
            <li className="nav-item">
              <Link
                className={handleActive("manage-students")}
                to="/dashboard/manage-students"
              >
                Manage Students
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={handleActive("manage-courses")}
                to="/dashboard/manage-courses"
              >
                Manage Courses
              </Link>
            </li>
          </ul>
        </div>
      </li>
      {/* Nav item */}
      <li className="nav-item">
        <Link
          className="nav-link collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#navCMS"
          aria-expanded="false"
          aria-controls="navCMS"
        >
          <i className="nav-icon fe fe-book-open me-2" />
          Coordinator
        </Link>
        <div id="navCMS" className="collapse" data-bs-parent="#sideNavbar">
          <ul className="nav flex-column">
            <li className="nav-item">
              <Link
                className={handleActive("manage-coordinators")}
                to="/dashboard/manage-coordinators"
              >
                Manage Coordinators
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={handleActive("handle-programs")}
                to="/dashboard/handle-programs"
              >
                Handle Programs
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={handleActive("assign-supporttickets")}
                to="/dashboard/assign-supporttickets"
              >
                Assign Support Tickets
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={handleActive("reports-and-performance")}
                to="/dashboard/reports-and-performance"
              >
                Reports & Performance
              </Link>
            </li>
          </ul>
        </div>
      </li>
    </div>
  );
}
