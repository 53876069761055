import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../../../utils'
import axios from 'axios'
import Select from 'react-select'

export default function CurriculumDevelopmentUpdates() {
  const baseUrl = BASE_URL
  const token = localStorage.getItem('token')

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  let isError = false

  const [allCourses, setAllCourses] = useState([])
  const [programs, setPrograms] = useState([])
  const [program, setProgram] = useState(null)
  const [programNames, setProgramNames] = useState([])
  const [name, setName] = useState('')
  const [curriculum, setCurriculum] = useState('')
  const [goals, setGoals] = useState('')
  const [courses, setCourses] = useState([])

  useEffect(() => {
    if (!programs.length) {
      getPrograms()
      getAllCourses()
    }
  })

  const getPrograms = () => {
    axios
      .get(baseUrl + '/programs', config)
      .then(response => {
        setPrograms(response.data.programs)
        const progs = []
        response.data.programs.forEach(program => {
          progs.push({ value: program.id, label: program.name })
        })
        setProgramNames(progs)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const getAllCourses = () => {
    axios
      .get(baseUrl + '/courses', config)
      .then(response => {
        const cours = []
        response.data.courses.forEach(course => {
          cours.push({ value: course.id, label: course.name })
        })
        setAllCourses(cours)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }

  const handleSubmit = e => {
    e.preventDefault()

    let fieldName = []
    if (!name.length) {
      fieldName.push('Program')
    }
    if (!curriculum.length) {
      fieldName.push('Curriculum')
    }
    if (!goals.length) {
      fieldName.push('Goals')
    }
    if (!courses.length) {
      fieldName.push('Course')
    }

    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + ' is Required!')
      }
      isError = true
    }

    if (isError) {
      return
    }
    updateProgram()
  }

  const clearFields = () => {
    setProgram('')
    setName('')
    setCurriculum('')
    setGoals('')
    setCourses([])
  }

  const editProgram = prog => {
    setProgram(prog.id)
    setName(prog.name)
    setCurriculum(prog.curriculum)
    setGoals(prog.goals)
    setCourses(
      prog.courses.map(course => {
        return { value: course.id, label: course.name }
      })
    )
  }

  const updateProgram = () => {
    const prog = programs.find(pro => pro.id === program)
    axios
      .post(
        baseUrl + '/program/' + program,
        {
          coordinator_id: prog.coordinator_id,
          name: name,
          description: prog.description,
          duration: prog.duration,
          alignment: prog.alignment,
          curriculum: curriculum,
          goals: goals,
          evaluation: prog.evaluation,
          courses: courses.map(course => course.value),
          status: prog.status,
        },
        config
      )
      .then(response => {
        toast.success(
          'Curriculum Development and Updates Updated Successfully!'
        )
        getPrograms()
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })

    clearFields()
  }

  const handleProgramChange = selectedValue => {
    const prog = programs.find(program => program.id === selectedValue.value)
    editProgram(prog)
  }

  const handleCourseSelection = selectedValues => {
    setCourses(selectedValues)
  }

  return (
    <div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">
          {/* page header */}
          <div className="border-bottom pb-3 mb-3 d-lg-flex justify-content-between align-items-center">
            <div className="mb-3 mb-lg-0">
              <h1 className="mb-0 h2 fw-bold">DashBoard</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header">
          <h3 className="mb-0">Curriculum Development and Updates</h3>
        </div>
        <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
          <form>
            <div className="row">
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label className="form-label">Program</label>
                  <Select
                    isSearchable={false}
                    options={programNames}
                    value={
                      programNames.find(option => option.value === program) ||
                      ''
                    }
                    onChange={handleProgramChange}
                    placeholder="Select Program"
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label className="form-label">Courses</label>
                  <Select
                    isMulti
                    options={allCourses}
                    onChange={handleCourseSelection}
                    value={courses}
                    placeholder="Select Courses"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label className="form-label">Curriculum</label>
                  <textarea
                    className="w-100 h100 style2-textarea p-3 form-control"
                    onChange={e => setCurriculum(e.target.value)}
                    value={curriculum}
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label className="form-label">Goals</label>
                  <textarea
                    className="w-100 h100 style2-textarea p-3 form-control"
                    onChange={e => setGoals(e.target.value)}
                    value={goals}
                  />
                </div>
              </div>

              <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
                <Link
                  className="btn btn-primary"
                  onClick={e => handleSubmit(e)}
                >
                  Update Program Overview and Alignment
                </Link>
              </div>
            </div>
          </form>
        </div>
        {programs.length > 0 && (
          <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
            <table className="table table-bordered data-table">
              <thead>
                <tr>
                  <th>Program ID</th>
                  <th>Program Name</th>
                  <th>Curriculum</th>
                  <th>Goals</th>
                  <th>Courses</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {programs.map(program => (
                  <tr key={program.id}>
                    <td>{program.id}</td>
                    <td>{program.name}</td>
                    <td>{program.curriculum}</td>
                    <td>{program.goals}</td>
                    <td>
                      {program.courses.map(course => course.name).join(', ')}
                    </td>
                    <td className="text-center">
                      <Link
                        className="btn btn-outline-primary"
                        onClick={() => editProgram(program)}
                      >
                        Edit
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}
