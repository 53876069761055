import { Link } from "react-router-dom";

export default function LiveClassess() {
  return (
    <div>
      <section className="mt-6 course-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* Tab content */}
              <div className="tab-content content" id="course-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="course-intro"
                  role="tabpanel"
                  aria-labelledby="course-intro-tab"
                >
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                      <h3 className="mb-0 text-truncate-line-2">
                        Introduction
                      </h3>
                    </div>
                    <div>
                      {/* Dropdown */}
                      <span className="dropdown">
                        <Link
                          className="ms-2"
                          id="dropdownInfo"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i
                            style={{ color: "#009688" }}
                            className="fe fe-help-circle"
                          />
                        </Link>
                        <span
                          className="dropdown-menu dropdown-menu-lg p-3 dropdown-menu-end"
                          aria-labelledby="dropdownInfo"
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. cupiditate consequatur rerum eius ad ut officiis
                        </span>
                      </span>
                      {/* Dropdown */}
                      <span className="dropdown">
                        <Link
                          className=" "
                          role="button"
                          id="shareDropdown2"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i
                            style={{ color: "#009688" }}
                            className="fe fe-more-vertical"
                          />
                        </Link>
                        <span
                          className="dropdown-menu dropdown-menu-end"
                          aria-labelledby="shareDropdown2"
                        >
                          <span className="dropdown-header">Share</span>
                          <Link className="dropdown-item">
                            <span className="me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                fill="currentColor"
                                className="bi bi-facebook text-secondary"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
                              </svg>
                            </span>
                            <span>Facebook</span>
                          </Link>
                          <Link className="dropdown-item">
                            <span className="me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                fill="currentColor"
                                className="bi bi-twitter text-secondary"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path>
                              </svg>
                            </span>
                            <span>Twitter</span>
                          </Link>
                          <Link className="dropdown-item">
                            <span className="me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                fill="currentColor"
                                className="bi bi-linkedin text-secondary"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"></path>
                              </svg>
                            </span>
                            <span>Linked</span>
                            In
                          </Link>
                          <Link className="dropdown-item">
                            <span className="me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                fill="currentColor"
                                className="bi bi-copy"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                                />
                              </svg>
                            </span>
                            <span>Copy</span>
                            Link
                          </Link>
                        </span>
                      </span>
                    </div>
                  </div>
                  {/* Video */}
                  <div
                    className="embed-responsive position-relative w-100 d-block overflow-hidden p-0"
                    style={{ height: 600 }}
                  >
                    <iframe
                      className="position-absolute top-0 end-0 start-0 end-0 bottom-0 h-100 w-75"
                      width={560}
                      height={315}
                      src="https://www.youtube.com/embed/CBWnBi-awSA"
                      title="What Is JavaScript | Introduction &amp; First JavaScript Program"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                    frameBorder={0}
                  </div>
                </div>
                {/* Tab pane */}
                <div
                  className="tab-pane fade"
                  id="course-development"
                  role="tabpanel"
                  aria-labelledby="course-development-tab"
                >
                  <div className="d-lg-flex align-items-center justify-content-between mb-4">
                    <div>
                      <h2 className="h3 mb-0">
                        Installing Development Software
                      </h2>
                    </div>
                    <div>
                      {/* Dropdown */}
                      <span className="dropdown">
                        <Link
                          className="ms-2"
                          id="dropdownInfo2"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i
                            style={{ color: "#009688" }}
                            className="fe fe-help-circle"
                          />
                        </Link>
                        <span
                          className="dropdown-menu dropdown-menu-lg p-3"
                          aria-labelledby="dropdownInfo2"
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. cupiditate consequatur rerum eius ad ut officiis
                        </span>
                      </span>
                      {/* Dropdown */}
                      <span className="dropdown">
                        <Link
                          className=" "
                          role="button"
                          id="shareDropdown2"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i
                            style={{ color: "#009688" }}
                            className="fe fe-more-vertical"
                          />
                        </Link>
                        <span
                          className="dropdown-menu dropdown-menu-end"
                          aria-labelledby="shareDropdown2"
                        >
                          <span className="dropdown-header">Share</span>
                          <Link className="dropdown-item">
                            <span className="me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                fill="currentColor"
                                className="bi bi-facebook text-secondary"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
                              </svg>
                            </span>
                            <span>Facebook</span>
                          </Link>
                          <Link className="dropdown-item">
                            <span className="me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                fill="currentColor"
                                className="bi bi-twitter text-secondary"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path>
                              </svg>
                            </span>
                            <span>Twitter</span>
                          </Link>
                          <Link className="dropdown-item">
                            <span className="me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                fill="currentColor"
                                className="bi bi-linkedin text-secondary"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"></path>
                              </svg>
                            </span>
                            <span>Linked</span>
                            In
                          </Link>
                          <Link className="dropdown-item">
                            <span className="me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                fill="currentColor"
                                className="bi bi-copy"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                                />
                              </svg>
                            </span>
                            <span>Copy</span>
                            Link
                          </Link>
                        </span>
                      </span>
                    </div>
                  </div>
                  {/* Video */}
                </div>
                {/* Tab pane */}
                <div
                  className="tab-pane fade"
                  id="course-project"
                  role="tabpanel"
                  aria-labelledby="course-project-tab"
                >
                  <div className="d-lg-flex align-items-center justify-content-between mb-4">
                    <div>
                      <h2 className="h3 mb-0">
                        Hello World Project from GitHub
                      </h2>
                    </div>
                    <div>
                      {/* Dropdown */}
                      <span className="dropdown">
                        <Link
                          className="ms-2"
                          id="dropdownInfo3"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i
                            style={{ color: "#009688" }}
                            className="fe fe-help-circle"
                          />
                        </Link>
                        <span
                          className="dropdown-menu dropdown-menu-lg p-3"
                          aria-labelledby="dropdownInfo3"
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. cupiditate consequatur rerum eius ad ut officiis
                        </span>
                      </span>
                      {/* Dropdown */}
                      <span className="dropdown">
                        <Link
                          className=" "
                          role="button"
                          id="shareDropdown3"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i
                            style={{ color: "#009688" }}
                            className="fe fe-more-vertical"
                          />
                        </Link>
                        <span
                          className="dropdown-menu dropdown-menu-end"
                          aria-labelledby="shareDropdown3"
                        >
                          <span className="dropdown-header">Share</span>
                          <Link className="dropdown-item">
                            <span className="me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                fill="currentColor"
                                className="bi bi-facebook text-secondary"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
                              </svg>
                            </span>
                            <span>Facebook</span>
                          </Link>
                          <Link className="dropdown-item">
                            <span className="me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                fill="currentColor"
                                className="bi bi-twitter text-secondary"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path>
                              </svg>
                            </span>
                            <span>Twitter</span>
                          </Link>
                          <Link className="dropdown-item">
                            <span className="me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                fill="currentColor"
                                className="bi bi-linkedin text-secondary"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"></path>
                              </svg>
                            </span>
                            <span>Linked</span>
                            In
                          </Link>
                          <Link className="dropdown-item">
                            <span className="me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                fill="currentColor"
                                className="bi bi-copy"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                                />
                              </svg>
                            </span>
                            <span>Copy</span>
                            Link
                          </Link>
                        </span>
                      </span>
                    </div>
                  </div>
                  {/* Video */}
                </div>
                {/* Tab pane */}
                <div
                  className="tab-pane fade"
                  id="course-website"
                  role="tabpanel"
                  aria-labelledby="course-website-tab"
                >
                  <div className="d-lg-flex align-items-center justify-content-between mb-4">
                    <div>
                      <h2 className="h3 mb-0">Our Sample Website</h2>
                    </div>
                    <div>
                      {/* Dropdown */}
                      <span className="dropdown">
                        <Link
                          className="ms-2"
                          id="dropdownInfo4"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i
                            style={{ color: "#009688" }}
                            className="fe fe-help-circle"
                          />
                        </Link>
                        <span
                          className="dropdown-menu dropdown-menu-lg p-3"
                          aria-labelledby="dropdownInfo4"
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. cupiditate consequatur rerum eius ad ut officiis
                        </span>
                      </span>
                      {/* Dropdown */}
                      <span className="dropdown">
                        <Link
                          className=" "
                          role="button"
                          id="shareDropdown4"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i
                            style={{ color: "#009688" }}
                            className="fe fe-more-vertical"
                          />
                        </Link>
                        <span
                          className="dropdown-menu dropdown-menu-end"
                          aria-labelledby="shareDropdown4"
                        >
                          <span className="dropdown-header">Share</span>
                          <Link className="dropdown-item">
                            <span className="me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                fill="currentColor"
                                className="bi bi-facebook text-secondary"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
                              </svg>
                            </span>
                            <span>Facebook</span>
                          </Link>
                          <Link className="dropdown-item">
                            <span className="me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                fill="currentColor"
                                className="bi bi-twitter text-secondary"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path>
                              </svg>
                            </span>
                            <span>Twitter</span>
                          </Link>
                          <Link className="dropdown-item">
                            <span className="me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                fill="currentColor"
                                className="bi bi-linkedin text-secondary"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"></path>
                              </svg>
                            </span>
                            <span>Linked</span>
                            In
                          </Link>
                          <Link className="dropdown-item">
                            <span className="me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                fill="currentColor"
                                className="bi bi-copy"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                                />
                              </svg>
                            </span>
                            <span>Copy</span>
                            Link
                          </Link>
                        </span>
                      </span>
                    </div>
                  </div>
                  {/* Video */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="card course-sidebar" id="courseAccordion">
        {/* List group */}

        <ul
          className="list-group list-group-flush"
          style={{ height: 850 }}
          data-simplebar
        >
          <li className="list-group-item">
            <h4 className="mb-0">Table of Content</h4>
          </li>
          {/* List group item */}
          <li className="list-group-item">
            {/* Toggle */}
            <Link
              className="d-flex align-items-center h4 mb-0"
              data-bs-toggle="collapse"
              to="#courseTwo"
              role="button"
              aria-expanded="false"
              aria-controls="courseTwo"
            >
              <div className="me-auto">Introduction to JavaScript</div>
              {/* Chevron */}
              <span className="chevron-arrow ms-4">
                <i
                  style={{ color: "#009688" }}
                  className="fe fe-chevron-down fs-4"
                />
              </span>
            </Link>
            {/* Row */}
            {/* Collapse */}
            <div
              className="collapse show"
              id="courseTwo"
              data-bs-parent="#courseAccordion"
            >
              <div
                className="py-4 nav"
                id="course-tabOne"
                role="tablist"
                aria-orientation="vertical"
                style={{ display: "inherit" }}
              >
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center"
                  id="course-intro-tab"
                  data-bs-toggle="pill"
                  to="#course-intro"
                  role="tab"
                  aria-controls="course-intro"
                  aria-selected="true"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-primary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-play fs-6"
                      />
                    </span>
                    <span>Introduction</span>
                  </div>
                  <div className="text-truncate">
                    <span>1m 7s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit"
                  id="course-development-tab"
                  data-bs-toggle="pill"
                  to="#course-development"
                  role="tab"
                  aria-controls="course-development"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-primary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-play fs-6"
                      />
                    </span>
                    <span>Installing Development Software</span>
                  </div>
                  <div className="text-truncate">
                    <span>3m 11s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit"
                  id="course-project-tab"
                  data-bs-toggle="pill"
                  to="#course-project"
                  role="tab"
                  aria-controls="course-project"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-primary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-play fs-6"
                      />
                    </span>
                    <span>Hello World Project from GitHub</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 33s</span>
                  </div>
                </Link>
                <Link
                  className="d-flex justify-content-between align-items-center text-inherit"
                  id="course-website-tab"
                  data-bs-toggle="pill"
                  to="#course-website"
                  role="tab"
                  aria-controls="course-website"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-primary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-play fs-6"
                      />
                    </span>
                    <span>Our Sample Website</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 15s</span>
                  </div>
                </Link>
              </div>
            </div>
          </li>
          {/* List group item */}
          <li className="list-group-item">
            {/* Toggle */}
            <Link
              className="d-flex align-items-center h4 mb-0"
              data-bs-toggle="collapse"
              to="#courseThree"
              role="button"
              aria-expanded="false"
              aria-controls="courseThree"
            >
              <div className="me-auto">
                {/* Title */}
                JavaScript Beginning
              </div>
              {/* Chevron */}
              <span className="chevron-arrow ms-4">
                <i
                  style={{ color: "#009688" }}
                  className="fe fe-chevron-down fs-4"
                />
              </span>
            </Link>
            {/* Row */}
            {/* Collapse */}
            <div
              className="collapse"
              id="courseThree"
              data-bs-parent="#courseAccordion"
            >
              <div
                className="py-4 nav"
                id="course-tabTwo"
                role="tablist"
                aria-orientation="vertical"
                style={{ display: "inherit" }}
              >
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-intro-tab2"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-intro"
                  aria-selected="true"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Introduction</span>
                  </div>
                  <div className="text-truncate">
                    <span>1m 41s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-development-tab2"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-development"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Adding JavaScript Code to a Web Page</span>
                  </div>
                  <div className="text-truncate">
                    <span>3m 39s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-project-tab2"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-project"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Working with JavaScript Files</span>
                  </div>
                  <div className="text-truncate">
                    <span>6m 18s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-website-tab2"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-website"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Formatting Code</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 18s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-project-tab3"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-project"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Detecting and Fixing Errors</span>
                  </div>
                  <div className="text-truncate">
                    <span>3m 14s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-website-tab3"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-website"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Case Sensitivity</span>
                  </div>
                  <div className="text-truncate">
                    <span>1m 48s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-development-tab3"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-development"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Commenting Code</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 24s</span>
                  </div>
                </Link>
                <Link
                  className="mb-0 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-intro-tab3"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-intro"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Summary</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 14s</span>
                  </div>
                </Link>
              </div>
            </div>
          </li>
          {/* List group item */}
          <li className="list-group-item">
            {/* Toggle */}
            <Link
              className="d-flex align-items-center h4 mb-0"
              data-bs-toggle="collapse"
              to="#courseFour"
              role="button"
              aria-expanded="false"
              aria-controls="courseFour"
            >
              <div className="me-auto">
                {/* Title */}
                Variables and Constants
              </div>
              {/* Chevron */}
              <span className="chevron-arrow ms-4">
                <i
                  style={{ color: "#009688" }}
                  className="fe fe-chevron-down fs-4"
                />
              </span>
            </Link>
            {/* Row */}
            {/* Collapse */}
            <div
              className="collapse"
              id="courseFour"
              data-bs-parent="#courseAccordion"
            >
              <div
                className="py-4 nav"
                id="course-tabThree"
                role="tablist"
                aria-orientation="vertical"
                style={{ display: "inherit" }}
              >
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-intro-tab4"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-intro"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Introduction</span>
                  </div>
                  <div className="text-truncate">
                    <span>1m 19s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-website-tab4"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-website"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>What Is a Variable?</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 11s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-intro-tab5"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-intro"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Declaring Variables</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 30s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-project-tab4"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-project"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Using let to Declare Variables</span>
                  </div>
                  <div className="text-truncate">
                    <span>3m 28s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-website-tab5"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-website"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Naming Variables</span>
                  </div>
                  <div className="text-truncate">
                    <span>3m 14s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-intro-tab6"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-intro"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Common Errors Using Variables</span>
                  </div>
                  <div className="text-truncate">
                    <span>3m 30s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-development-tab4"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-development"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Changing Variable Values</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 4s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-website-tab19"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-website"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Constants</span>
                  </div>
                  <div className="text-truncate">
                    <span>3m 15s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-project-tab5"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-project"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>The var Keyword</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 20s</span>
                  </div>
                </Link>
                <Link
                  className="mb-0 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-intro-tab7"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-intro"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Summary</span>
                  </div>
                  <div className="text-truncate">
                    <span>1m 49s</span>
                  </div>
                </Link>
              </div>
            </div>
          </li>
          {/* List group item */}
          <li className="list-group-item">
            {/* Toggle */}
            <Link
              className="d-flex align-items-center h4 mb-0"
              data-bs-toggle="collapse"
              to="#courseSix"
              role="button"
              aria-expanded="false"
              aria-controls="courseSix"
            >
              <div className="me-auto">
                {/* Title */}
                Program Flow
              </div>
              {/* Chevron */}
              <span className="chevron-arrow ms-4">
                <i
                  style={{ color: "#009688" }}
                  className="fe fe-chevron-down fs-4"
                />
              </span>
            </Link>
            {/* Row */}
            {/* Collapse */}
            <div
              className="collapse"
              id="courseSix"
              data-bs-parent="#courseAccordion"
            >
              <div
                className="py-4 nav"
                id="course-tabFour"
                role="tablist"
                aria-orientation="vertical"
                style={{ display: "inherit" }}
              >
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-intro-tab8"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-intro"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Introduction</span>
                  </div>
                  <div className="text-truncate">
                    <span>1m 52s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-project-tab6"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-project"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Clip Watched</span>
                  </div>
                  <div className="text-truncate">
                    <span>4m 27s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-development-tab5"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-development"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Conditionals Using if()</span>
                  </div>
                  <div className="text-truncate">
                    <span>4m 25s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-website-tab6"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-website"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Truthy and Falsy</span>
                  </div>
                  <div className="text-truncate">
                    <span>3m 30s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-project-tab7"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-project"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>if ... else</span>
                  </div>
                  <div className="text-truncate">
                    <span>3m 30s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-intro-tab9"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-intro"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Comparing === and ==</span>
                  </div>
                  <div className="text-truncate">
                    <span>1m 52s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-website-tab7"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-website"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>The Ternary Operator</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 47s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-intro-tab10"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-intro"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Block Scope Using let</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 21s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-project-tab8"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-project"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Looping with for()</span>
                  </div>
                  <div className="text-truncate">
                    <span>5m 30s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-website-tab8"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-website"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Looping with do ... while()</span>
                  </div>
                  <div className="text-truncate">
                    <span>1m 58s</span>
                  </div>
                </Link>
                <Link
                  className="mb-0 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-development-tab6"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-development"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Summary</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 21s</span>
                  </div>
                </Link>
              </div>
            </div>
          </li>
          {/* List group item */}
          <li className="list-group-item">
            {/* Toggle */}
            <Link
              className="d-flex align-items-center h4 mb-0"
              data-bs-toggle="collapse"
              to="#courseSeven"
              role="button"
              aria-expanded="false"
              aria-controls="courseSeven"
            >
              <div className="me-auto">
                {/* Title */}
                Functions
              </div>
              {/* Chevron */}
              <span className="chevron-arrow ms-4">
                <i
                  style={{ color: "#009688" }}
                  className="fe fe-chevron-down fs-4"
                />
              </span>
            </Link>
            {/* Row */}
            {/* Collapse */}
            <div
              className="collapse"
              id="courseSeven"
              data-bs-parent="#courseAccordion"
            >
              <div
                className="py-4 nav"
                id="course-tabFive"
                role="tablist"
                aria-orientation="vertical"
                style={{ display: "inherit" }}
              >
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-intro-tab11"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-intro"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Introduction</span>
                  </div>
                  <div className="text-truncate">
                    <span>1m 52s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-website-tab11"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-website"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Function Basics</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 46s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-development-tab7"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-development"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Function Expressions</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 32s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-intro-tab12"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-intro"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Passing Information to Functions</span>
                  </div>
                  <div className="text-truncate">
                    <span>3m 19s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-project-tab9"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-project"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Function Return Values</span>
                  </div>
                  <div className="text-truncate">
                    <span>3m 13s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-development-tab8"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-development"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Function Scope</span>
                  </div>
                  <div className="text-truncate">
                    <span>4m 20s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-website-tab12"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-website"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Using Functions to Modify Web Pages</span>
                  </div>
                  <div className="text-truncate">
                    <span>3m 42s</span>
                  </div>
                </Link>
                <Link
                  className="mb-0 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-project-tab10"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-project"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Summary</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 3s</span>
                  </div>
                </Link>
              </div>
            </div>
          </li>
          {/* List group item */}
          <li className="list-group-item">
            {/* Toggle */}
            <Link
              className="d-flex align-items-center h4 mb-0 justify-content-between"
              data-bs-toggle="collapse"
              to="#courseEight"
              role="button"
              aria-expanded="false"
              aria-controls="courseEight"
            >
              <div className="me-auto">
                {/* Title */}
                Objects and the DOM
              </div>
              {/* Chevron */}
              <span className="chevron-arrow ms-4">
                <i
                  style={{ color: "#009688" }}
                  className="fe fe-chevron-down fs-4"
                />
              </span>
            </Link>
            {/* Row */}
            {/* Collapse */}
            <div
              className="collapse"
              id="courseEight"
              data-bs-parent="#courseAccordion"
            >
              <div
                className="py-4 nav"
                id="course-tabSix"
                role="tablist"
                aria-orientation="vertical"
                style={{ display: "inherit" }}
              >
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-intro-tab13"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-intro"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Introduction</span>
                  </div>
                  <div className="text-truncate">
                    <span>1m 48s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-website-tab13"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-website"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Object Properties</span>
                  </div>
                  <div className="text-truncate">
                    <span>4m 28s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-project-tab11"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-project"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Object Methods</span>
                  </div>
                  <div className="text-truncate">
                    <span>3m 3s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-development-tab9"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-development"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Passing Objects to Functions</span>
                  </div>
                  <div className="text-truncate">
                    <span>3m 27s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-intro-tab14"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-intro"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Standard Built-in Objects</span>
                  </div>
                  <div className="text-truncate">
                    <span>6m 55s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-project-tab12"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-project"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>The Document Object Model (DOM)</span>
                  </div>
                  <div className="text-truncate">
                    <span>3m 29s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-website-tab14"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-website"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Styling DOM Elements</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 42s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-development-tab10"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-development"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Detecting Button Clicks</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 3s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-project-tab13"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-project"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Showing and Hiding DOM Elements</span>
                  </div>
                  <div className="text-truncate">
                    <span>4m 37s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-intro-tab15"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-intro"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Summary</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 47s</span>
                  </div>
                </Link>
              </div>
            </div>
          </li>
          {/* List group item */}
          <li className="list-group-item">
            {/* Toggle */}
            <Link
              className="d-flex align-items-center h4 mb-0"
              data-bs-toggle="collapse"
              to="#courseNine"
              role="button"
              aria-expanded="false"
              aria-controls="courseNine"
            >
              <div className="me-auto">
                {/* Title */}
                Arrays
              </div>
              {/* Chevron */}
              <span className="chevron-arrow ms-4">
                <i
                  style={{ color: "#009688" }}
                  className="fe fe-chevron-down fs-4"
                />
              </span>
            </Link>
            {/* Row */}
            {/* Collapse */}
            <div
              className="collapse"
              id="courseNine"
              data-bs-parent="#courseAccordion"
            >
              <div
                className="py-4 nav"
                id="course-tabSeven"
                role="tablist"
                aria-orientation="vertical"
                style={{ display: "inherit" }}
              >
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-intro-tab16"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-intro"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Introduction</span>
                  </div>
                  <div className="text-truncate">
                    <span>1m 48s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-project-tab14"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-project"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Creating and Initializing Arrays</span>
                  </div>
                  <div className="text-truncate">
                    <span>4m 7s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-website-tab15"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-website"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Accessing Array Items</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 4s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-development-tab11"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-development"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Manipulating Arrays</span>
                  </div>
                  <div className="text-truncate">
                    <span>4m 3s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-project-tab15"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-project"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>slice() and splice()</span>
                  </div>
                  <div className="text-truncate">
                    <span>5m 54s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-intro-tab17"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-intro"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Array Searching and Looping</span>
                  </div>
                  <div className="text-truncate">
                    <span>7m 32s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-website-tab16"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-website"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Arrays in the DOM</span>
                  </div>
                  <div className="text-truncate">
                    <span>4m 11s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-intro-tab18"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-intro"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Summary</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 28s</span>
                  </div>
                </Link>
              </div>
            </div>
          </li>
          {/* List group item */}
          <li className="list-group-item">
            {/* Toggle */}
            <Link
              className="d-flex align-items-center h4 mb-0"
              data-bs-toggle="collapse"
              to="#courseTen"
              role="button"
              aria-expanded="false"
              aria-controls="courseTen"
            >
              <div className="me-auto">
                {/* Title */}
                Scope and Hoisting
              </div>
              {/* Chevron */}
              <span className="chevron-arrow ms-4">
                <i
                  style={{ color: "#009688" }}
                  className="fe fe-chevron-down fs-4"
                />
              </span>
            </Link>
            {/* Row */}
            {/* Collapse */}
            <div
              className="collapse"
              id="courseTen"
              data-bs-parent="#courseAccordion"
            >
              <div
                className="py-4 nav"
                id="course-tabEight"
                role="tablist"
                aria-orientation="vertical"
                style={{ display: "inherit" }}
              >
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-intro-tab19"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-intro"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Introduction</span>
                  </div>
                  <div className="text-truncate">
                    <span>1m 20s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-project-tab16"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-project"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Global Scope</span>
                  </div>
                  <div className="text-truncate">
                    <span>4m 7s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-website-tab17"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-website"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Clip Watched</span>
                  </div>
                  <div className="text-truncate">
                    <span>3m 14s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-development-tab12"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-development"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Function Scope</span>
                  </div>
                  <div className="text-truncate">
                    <span>3m 45s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-project-tab17"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-project"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Var and Hoisting</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 21s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-website-tab18"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-website"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Undeclared Variables and Strict Mode</span>
                  </div>
                  <div className="text-truncate">
                    <span>2m 16s</span>
                  </div>
                </Link>
                <Link
                  className="mb-2 d-flex justify-content-between align-items-center text-inherit disableClick"
                  id="course-project-tab18"
                  data-bs-toggle="pill"
                  role="tab"
                  aria-controls="course-project"
                  aria-selected="false"
                >
                  <div className="text-truncate">
                    <span className="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                      <i
                        style={{ color: "#009688" }}
                        className="fe fe-lock fs-6"
                      />
                    </span>
                    <span>Summary</span>
                  </div>
                  <div className="text-truncate">
                    <span>1m 33s</span>
                  </div>
                </Link>
              </div>
            </div>
          </li>
          {/* List group item */}
          <li className="list-group-item">
            {/* Toggle */}
            <Link
              className="d-flex align-items-center h4 mb-0"
              data-bs-toggle="collapse"
              to="#courseEleven"
              role="button"
              aria-expanded="false"
              aria-controls="courseEleven"
            >
              <div className="me-auto">
                {/* Title */}
                Summary
              </div>
              {/* Chevron */}
              <span className="chevron-arrow ms-4">
                <i
                  style={{ color: "#009688" }}
                  className="fe fe-chevron-down fs-4"
                />
              </span>
            </Link>
            {/* / .row */}
            {/* Collapse */}
            <div
              className="collapse"
              id="courseEleven"
              data-bs-parent="#courseAccordion"
            >
              <div className="py-4">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repudiandae esse velit eos sunt ab inventore est tenetur
                  blanditiis? Voluptas eius molestiae ad itaque tempora nobis
                  minima eveniet aperiam molestias.
                </p>
                <p>
                  Maiores natus expedita dolores ea non possimus magnam corrupt
                  i quas rem unde quo enim porro culpa! Quaerat veritatis veniam
                  corrupti iusto.
                </p>
              </div>
            </div>
          </li>
        </ul>
      </section>
    </div>
  );
}
