import AuthFooter from "./AuthFooter";
import AuthHeader from "./AuthHeader";

import { Navigate, Outlet, useNavigate } from "react-router-dom";
export default function AuthLayout() {
  const userRole = localStorage.getItem("role");
  useNavigate();

  return userRole ? (
    <Navigate to={"/dashboard/" + userRole} />
  ) : (
    <div>
      <AuthHeader />
      <Outlet />
      <AuthFooter />
    </div>
  );
}
