import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import validator from "validator";
import axios from "axios";
import { BASE_URL } from "../../../utils";

export default function SignUp() {
  const baseUrl = BASE_URL;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCpassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [role, setRole] = useState("");

  const roleOptions = [
    { value: "instructor", label: "Instructor" },
    { value: "coordinator", label: "Program Coordinator" },
    { value: "qa", label: "QA Officer" },
    { value: "student", label: "Student" },
  ];

  let isError = false;
  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!name.length) {
      fieldName.push("Name");
    }

    if (!email.length) {
      fieldName.push("Email");
    }
    if (!phoneNumber.length) {
      fieldName.push("Phone");
    }
    if (!password.length) {
      fieldName.push("Password");
    }
    if (!cPassword.length) {
      fieldName.push("Confirm Password");
    }

    if (!role.length) {
      fieldName.push("Role");
    }

    if (!termsAndConditions) {
      toast.error("Please accept Terms and Conditions!");
      isError = true;
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }

    if (email.length && !validator.isEmail(email)) {
      toast.error("Email is Invalid!");
      isError = true;
    }
    if (password !== cPassword) {
      toast.error("Passwords do not match!");
      isError = true;
    }

    const phoneNumberValidation = /^[+]?\d+$/;
    if (
      phoneNumber.length &&
      !validator.isMobilePhone(phoneNumber) &&
      !phoneNumberValidation.test(phoneNumber)
    ) {
      toast.error("Phone Number is Invalid!");
      isError = true;
    }

    if (isError) {
      return;
    }
    createUser();
  };
  function createUser() {
    axios
      .post(baseUrl + "/register", {
        name: name,
        email: email,
        phone: phoneNumber,
        password: password,
        password_confirmation: cPassword,
        role: role,
      })
      .then((response) => {
        const data = response.data;
        localStorage.setItem("token", data.token);
        toast.info(
          "We've sent an email to " +
            email +
            " to verify your email address and activate your account."
        );
        toast.success("Registration Successful!");
        setName("");
        setEmail("");
        setPassword("");
        setCpassword("");
        setPhoneNumber("");
        setTermsAndConditions(false);
      })
      .catch(function (error) {
        const tempdata = error.response.data;

        if (tempdata.errors) {
          const errorKeys = Object.keys(tempdata.errors);
          for (const errorKey of errorKeys) {
            for (const error of tempdata.errors[errorKey]) {
              toast.error(`${error}`);
            }
          }
        }
      });
  }

  return (
    <div>
      <main>
        <section className="container d-flex flex-column vh-200">
          <div className="row align-items-center justify-content-center g-0 h-lg-100 py-8">
            <div className="col-lg-5 col-md-8 py-8 py-xl-0">
              {/* Card */}
              <div className="card shadow">
                {/* Card body */}
                <div className="card-body p-6">
                  <div className="mb-4">
                    <a href="../index.html">
                      <img
                        src="../assets/images/brand/logo/logobz.png"
                        className="mb-4"
                        alt="logo"
                      />
                    </a>
                    <h1 className="mb-1 fw-bold">Sign up</h1>
                    <span>
                      Already have an account?
                      <a href="/auth/login" className="ms-1">
                        Sign in
                      </a>
                    </span>
                  </div>
                  {/* Form */}
                  <form className="needs-validation" noValidate>
                    {/* Username */}
                    <div className="mb-3">
                      <label htmlFor="username" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        className="form-control"
                        name="username"
                        placeholder="Name"
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter valid username.
                      </div>
                    </div>
                    {/* Email */}
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        className="form-control"
                        name="email"
                        placeholder="Email address here"
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter valid Email.
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        value={phoneNumber}
                        className="form-control"
                        name="phone"
                        placeholder="Phone number"
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter valid Phone numbmer.
                      </div>
                    </div>
                    {/* Password */}
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        className="form-control"
                        name="password"
                        placeholder="**************"
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter valid password.
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Confirm password" className="form-label">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        onChange={(e) => setCpassword(e.target.value)}
                        value={cPassword}
                        className="form-control"
                        name="password"
                        placeholder="**************"
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter valid password.
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Confirm password" className="form-label">
                        Select Role
                      </label>
                      <select
                        className="style2-input pl-5 form-control text-grey-900 font-xsss ls-3"
                        onChange={(e) => setRole(e.target.value)}
                      >
                        <option value="Select Role">Select Role</option>
                        {roleOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Checkbox */}
                    <div className="mb-3">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="agreeCheck"
                          onChange={(e) =>
                            setTermsAndConditions(e.target.value)
                          }
                          value={termsAndConditions}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="agreeCheck"
                        >
                          <span>
                            I agree to the
                            <a href="terms-condition-page.html">
                              Terms of Service
                            </a>
                            and
                            <a href="terms-condition-page.html">
                              Privacy Policy.
                            </a>
                          </span>
                        </label>
                        <div className="invalid-feedback">
                          You must agree before submitting.
                        </div>
                      </div>
                    </div>
                    <div>
                      {/* Button */}
                      <div className="d-grid">
                        <Link
                          type="submit"
                          className="btn btn-primary"
                          onClick={(e) => handleSubmit(e)}
                        >
                          Create Free Account
                        </Link>
                      </div>
                    </div>
                    <hr className="my-4" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
