import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../utils";

export default function HandleCourses(props) {
  const baseUrl = BASE_URL;
  const token = localStorage.getItem("token");

  const [activeId, setActiveId] = useState(-1);
  const [students, setStudents] = useState([]);
  const [student, setStudent] = useState(null);
  const [courses, setCourses] = useState([]);
  const [studentCourses, setStudentCourses] = useState([]);

  const [selectedCourses, setSelectedCourses] = useState([]);
  let isError = false;
  const [mode, setMode] = useState("add");

  const title = props.title;
  const role = props.role;
  const dis = props.dis;

  useEffect(() => {
    if (!students.length) {
      getStudents();
      getCourses();
      getStudentCourses();
    }
  });

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getStudents = () => {
    axios
      .get(baseUrl + "/role-users", {
        ...config,
        params: {
          role: "student",
        },
      })
      .then((response) => {
        const studs = [];
        response.data.users.forEach((student) => {
          studs.push({ value: student.id, label: student.name });
        });
        setStudents(studs);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const getCourses = () => {
    axios
      .get(baseUrl + "/courses", config)
      .then((response) => {
        const cours = [];
        response.data.courses.forEach((course) => {
          cours.push({ value: course.id, label: course.name });
        });
        setCourses(cours);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const getStudentCourses = () => {
    axios
      .get(baseUrl + "/admin/student-courses", config)
      .then((response) => {
        setStudentCourses(response.data.studentCourses);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleStudentChange = (selectedValues) => {
    setStudent(selectedValues);
  };

  const handleCourseSelection = (selectedValues) => {
    setSelectedCourses(selectedValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let fieldName = [];
    if (!student) {
      toast.error("Please select a Student!");
      return;
    }
    if (selectedCourses.length === 0) {
      isError = true;
      fieldName.push("Courses Selection");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }
    if (isError) {
      return;
    }
    if (mode === "add") {
      addStudentCourse();
    } else {
      updateStudentCourse();
    }
  };

  const clearFields = () => {
    setStudent("");
    setSelectedCourses([]);
    setMode("add");
  };

  const addStudentCourse = () => {
    const newStudent = {
      student_id: student,
      courses: selectedCourses.map((course) => course.value),
    };
    axios
      .post(baseUrl + "/admin/student-courses", newStudent, config)
      .then((response) => {
        toast.success(response.data.message);
        getStudentCourses();
      });
    clearFields();
  };

  const editStudentCourse = (id) => {
    setMode("edit");
    setActiveId(id);
    const studentCourse = studentCourses.find((student) => student.id === id);
    setStudent(studentCourse.student_id);
    setSelectedCourses(
      studentCourse.courses.map((course) => {
        return { value: course.id, label: course.name };
      })
    );
  };

  const deleteStudentCourse = (id) => {
    axios
      .delete(baseUrl + "/admin/student-courses/" + id, config)
      .then((response) => {
        toast.success(response.data.message);
        getStudentCourses();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
    clearFields();
  };

  const updateStudentCourse = () => {
    const newStudent = {
      student_id: student,
      courses: selectedCourses.map((course) => course.value),
    };
    axios
      .post(baseUrl + "/admin/student-courses/" + activeId, newStudent, config)
      .then((response) => {
        toast.success(response.data.message);
        getStudentCourses();
      });
    clearFields();
  };

  return (
    <div>
      <div className="card mb-4">
        <div className="card-header">
          <h3 className="mb-0">{title}</h3>
        </div>
        <div className="card-body">
          <form className="row">
            <div className="mb-3 col-12 col-md-6">
              <label className="form-label" htmlFor="fname">
                {role}
              </label>
              <Select
                options={students}
                value={
                  student
                    ? students.find((option) => option.value === student)
                    : ""
                }
                onChange={(e) => (e ? handleStudentChange(e.value) : "")}
                placeholder="Select a Student"
              />
            </div>
            <div class="mb-3 col-12 col-md-4">
              <label class="form-label">Courses</label>
              <Select
                class="form-select"
                data-width="100%"
                required
                isMulti
                options={courses}
                value={selectedCourses}
                onChange={handleCourseSelection}
              ></Select>
            </div>
            <div className="col-12">
              <Link
                type="submit"
                className="btn btn-primary"
                onClick={(e) => handleSubmit(e)}
              >
                {mode === "add" ? "Add" : "Edit"} {dis}
              </Link>
            </div>
          </form>
        </div>
        {studentCourses.length > 0 && (
          <div className="table-responsive px-4" style={{ height: "44vh" }}>
            <table className="table table-bordered text-nowrap mb-0 table-centered">
              <thead>
                <tr>
                  <th>{role} ID</th>

                  <th>Name</th>

                  <th>Courses</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {studentCourses.map((student) => (
                  <tr key={student.id}>
                    <td>{student.id}</td>
                    <td>{student.student.name}</td>
                    <td>
                      {student.courses.map((course) => course.name).join(", ")}
                    </td>
                    <td>
                      <div className="dropdown">
                        <Link
                          className="text-body text-primary-hover"
                          role="button"
                          id="dropdownThirtyOne"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i
                            style={{ color: "#009688" }}
                            className="fe fe-more-vertical"
                          />
                        </Link>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownThirtyOne"
                        >
                          <Link
                            className="dropdown-item"
                            onClick={() => editStudentCourse(student.id)}
                          >
                            Edit
                          </Link>
                          <Link
                            className="dropdown-item"
                            onClick={() => deleteStudentCourse(student.id)}
                          >
                            Delete
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
