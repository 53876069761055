import { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select"

export default function StudentProgressManagement() {
   
    const [courses] = useState([
      { value: "Operating Systems", label: "Operating Systems" },
      { value: "Networking", label: "Networking" },
      { value: "Algorithms", label: "Algorithms" },
      { value: "Compiler Design", label: "Compiler Design" },
      { value: "System Design", label: "System Design" },
    ]);
    const [students] = useState([
      { value: "Chad Nicolas", label: "Chad Nicolas" },
      { value: "Noah Buckridge", label: "Noah Buckridge" },
      { value: "Sherman Herman", label: "Sherman Herman" },
      { value: "David Durgan", label: "David Durgan" },
      { value: "Hannah Dach", label: "Hannah Dach" },
      { value: "Jesus Hermiston", label: "Jesus Hermiston" },
      { value: "Douglas Hayes PhD", label: "Douglas Hayes PhD" },
      { value: "Merle Gerlach", label: "Merle Gerlach" },
    ]);
   
    
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const handleCourseSelection = (selectedValues) => {
      setSelectedCourses(selectedValues);
    };
    
  
    const handleStudentSelection = (selectedValues) => {
      setSelectedStudents(selectedValues);
    };
    const handleSubmit = (e) => {
      e.preventDefault();
      /*let fieldName = [];
      if (!instructorName.length) {
        fieldName.push("Instructor Name");
      }
      if (!selectedInstructorIds) {
        fieldName.push("Instructor ID");
      }
      if (selectedCourses.length === 0) {
        fieldName.push("Selected Courses");
      }
      if (selectedStudents.length === 0) {
        fieldName.push("Selected Students");
      }
      if (fieldName.length) {
        for (const field of fieldName) {
          toast.error(field + " is Required!");
        }
        isError = true;
      }
      if (isError) {
        return;
      }*/

    };
    return (
      <div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            {/* page header */}
            <div className="border-bottom pb-3 mb-3 d-lg-flex justify-content-between align-items-center">
              <div className="mb-3 mb-lg-0">
                <h1 className="mb-0 h2 fw-bold">DashBoard</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="card mb-4">
          <div className="card-header">
            <h3 className="mb-0">Student Progress Management</h3>
          </div>
          <div className="card-body">
            <form className="row">
              <div class="mb-3 col-12 col-md-6">
                <label class="form-label">Courses</label>
                <Select
                  class="form-select"
                  data-width="100%"
                  required
                  /*isMulti*/
                  options={courses}
                  value={selectedCourses}
                  onChange={handleCourseSelection}
                ></Select>
              </div>
              <div class="mb-3 col-12 col-md-6">
                <label class="form-label">Students</label>
                <Select
                  class="form-select"
                  data-width="100%"
                  required
                  /*isMulti*/
                  options={students}
                  value={selectedStudents}
                  onChange={handleStudentSelection}
                ></Select>
              </div>
              <div className="col-12">
                <Link
                  type="submit"
                  className="btn btn-primary"
                  onClick={(e) => handleSubmit(e)}
                >
                  Filter Students
                </Link>
              </div>
            </form>
          </div>
          
            <div className="table-responsive p-4">
              <table className="table table-bordered text-nowrap mb-0 table-centered">
                <thead>
                  <tr>
                    <th>Course Name</th>
                    <th>Student Name</th>
                    <th>Progress</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Operating Systems</td>
                    <td>John Doe</td>
                    <td><span class="badge bg-success rounded-pill">Completed</span></td>
                  </tr>
                   <tr>
                    <td>Networking</td>
                    <td>Jane Smith</td>
                    <td><span class="badge bg-warning rounded-pill">In Progress</span></td>
                   </tr>
                   <tr>
                    <td>Algorithms</td>
                    <td>Alice Johnson</td>
                    <td><span class="badge bg-danger rounded-pill">Not Started</span></td>
                   </tr>
                   <tr>
                    <td>Operating Systems</td>
                    <td>Mary Brown</td>
                    <td><span class="badge bg-info rounded-pill">On Hold</span></td>
                   </tr>
                  
                </tbody>
              </table>
            </div>
         
        </div>
      </div>
    );
  }
  