export default function About() {
  return (
    <main>
      <section className="py-8 bg-white">
        <div className="container my-lg-4">
          <div className="row">
            <div className="offset-lg-2 col-lg-8 col-md-12 col-12 mb-8">
              <h1 className="display-2 fw-bold mb-3">
                Hi there, we're
                <span className="text-primary"> UTA</span>
              </h1>
              <p className="h2 mb-3">
                EPMS simplifies the process of measuring and assessing program
                performance by seamlessly mapping Program Objectives to
                individual courses, tracking student progress, and facilitating
                real-time exam and assessment creation.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 offset-right-md-6">
              <h1 className="display-4 fw-bold mb-3">Our global reach</h1>
              <p className="lead">
                UTA CANVAS is the leading global marketplace for teaching and
                learning, connecting millions of students to the skills they
                need to succeed.
              </p>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div className="border-top pt-4 mt-6 mb-5">
                <h1 className="display-3 fw-bold mb-0">20M</h1>
                <p className="text-uppercase">Learners</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div className="border-top pt-4 mt-6 mb-5">
                <h1 className="display-3 fw-bold mb-0">57K</h1>
                <p className="text-uppercase">Instructors</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div className="border-top pt-4 mt-6 mb-5">
                <h1 className="display-3 fw-bold mb-0">21K</h1>
                <p className="text-uppercase">Courses</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div className="border-top pt-4 mt-6 mb-5">
                <h1 className="display-3 fw-bold mb-0">380M</h1>
                <p className="text-uppercase">Course enrollments</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-8">
        <div className="container my-lg-8">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-12 mb-6">
              <h2 className="display-4 mb-3 fw-bold">Our core values</h2>
              <p className="lead">
                Our core values are the fundamental beliefs of a person or
                organization UTA academy. We help people understand the
                difference between right and wrong.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-12">
              <div className="card mb-4 mb-lg-0">
                <div className="card-body p-5">
                  <div className="mb-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={40}
                      height={40}
                      fill="currentColor"
                      className="bi bi-mortarboard text-primary"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5ZM8 8.46 1.758 5.965 8 3.052l6.242 2.913L8 8.46Z" />
                      <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Zm-.068 1.873.22-.748 3.496 1.311a.5.5 0 0 0 .352 0l3.496-1.311.22.748L8 12.46l-3.892-1.556Z" />
                    </svg>
                  </div>
                  <h3 className="mb-2">Continuous Learning</h3>
                  <p className="mb-0">
                    Ensure that all course materials, including text, images,
                    and videos are provided in accessible formats, such as alt
                    text for images and captions for videos.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="card mb-4 mb-lg-0">
                <div className="card-body p-5">
                  <div className="mb-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={40}
                      height={40}
                      fill="currentColor"
                      className="bi bi-people-fill text-primary"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                    </svg>
                  </div>
                  <h3 className="mb-2">Learn and Grow</h3>
                  <p className="mb-0">
                    We provides self-directed learning at your own pace. It
                    serves as a tool for lifelong personal and professional
                    development, empowering individuals to continuously enhance
                    their knowledge and skills.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="card mb-4 mb-lg-0">
                <div className="card-body p-5">
                  <div className="mb-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={40}
                      height={40}
                      fill="currentColor"
                      className="bi bi-graph-up-arrow text-primary"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"
                      />
                    </svg>
                  </div>
                  <h3 className="mb-2">Make Education Accessible</h3>
                  <p className="mb-0">
                    Continuously test and assess the portal's accessibility to
                    ensure it remains user-friendly for all students, regardless
                    of their abilities which will help you improve your skills.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}
