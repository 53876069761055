import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";

import axios from "axios";
import { BASE_URL } from "../../../../utils";

function CourseMaterials() {
  const baseUrl = BASE_URL;
  const token = localStorage.getItem("token");

  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState("");
  const [courseMaterials, setCourseMaterials] = useState([]);
  const [materialName, setMaterialName] = useState("");
  const [materialFile, setMaterialFile] = useState(null);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    if (!courses.length) {
      getCourses();
      getCourseMaterials();
    }
  });

  const getCourses = () => {
    axios
      .get(baseUrl + "/courses", config)
      .then((response) => {
        let crs = [];
        for (const course of response.data.courses) {
          crs.push({ value: course.id, label: course.name });
        }
        setCourses(crs);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const getCourseMaterials = () => {
    axios
      .get(baseUrl + "/materials", config)
      .then((response) => {
        setCourseMaterials(response.data.materials);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const addCourseMaterial = () => {
    const formData = new FormData();
    formData.append("course_id", course);
    formData.append("name", materialName);
    formData.append("file", materialFile);

    axios
      .post(baseUrl + "/material", formData, config)
      .then((response) => {
        toast.success(response.data.message);
        getCourseMaterials();
        clearFields();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const deleteCourseMaterial = (id) => {
    axios
      .delete(baseUrl + "/material/" + id, config)
      .then((response) => {
        toast.success(response.data.message);
        getCourseMaterials();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const clearFields = () => {
    setCourse("");
    setMaterialName("");
    setMaterialFile(null);
  };
  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-header">
        <h3 className="mb-0">Course Materials</h3>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form>
          <div className="row">
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="form-label">Course</label>
                <Select
                  isSearchable={false}
                  options={courses}
                  value={courses.find((option) => option.value === course)}
                  onChange={(e) => setCourse(e.value)}
                  placeholder="Select a Course"
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="form-label">Material Name</label>
                <input
                  type="text"
                  name="material-name"
                  className="form-control"
                  onChange={(e) => setMaterialName(e.target.value)}
                  value={materialName}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="form-label">
                  Upload File{" "}
                  <small className="text-danger">(File Size {"<="} 2MB)</small>
                  <br />
                  <small className="font-xssss text-grey-500">
                    (File Types Supported : pdf, doc, docx)
                  </small>
                </label>
                <input
                  type="file"
                  name="material-file"
                  accept=".pdf, .doc, .docx"
                  onChange={(e) => setMaterialFile(e.target.files[0])}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-right">
              <button
                type="button"
                className="btn btn-primary mt-3 mb-0"
                onClick={addCourseMaterial}
              >
                Add Course Materials
              </button>
            </div>
          </div>
        </form>
      </div>
      {courseMaterials.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th>Course Material ID</th>
                <th>Course Name</th>
                <th>Material Name</th>
                <th>File</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {courseMaterials.map((courseMaterial) => (
                <tr key={courseMaterial.id}>
                  <td>{courseMaterial.id}</td>
                  <td>{courseMaterial.course.name}</td>
                  <td>{courseMaterial.name}</td>
                  <td>
                    <Link
                      className="text-primary"
                      to={courseMaterial.file}
                      target="_blank"
                    >
                      Download
                    </Link>
                  </td>
                  <td className="text-center">
                    <Link
                      className="text-danger"
                      onClick={() => deleteCourseMaterial(courseMaterial.id)}
                    >
                      Delete
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default CourseMaterials;
