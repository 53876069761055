import { Link, useLocation } from 'react-router-dom'

export default function ManageStudent() {
  const location = useLocation()

  const handleActive = link => {
    let className = 'nav-link'
    if (location.pathname.includes(link)) {
      className += ' active'
    }

    return className
  }

  return (
    <div>
      <li className="nav-item">
        <Link className={handleActive('student')} to="/dashboard/student">
          <i className="nav-icon fe fe-layout  me-2" />
          Academic Program Overview
        </Link>

        <Link
          className={handleActive('course-catalog')}
          to="/dashboard/course-catalog"
        >
          <i className="nav-icon fe fe-database  me-2" />
          Course catalog
        </Link>
        <Link
          className={handleActive('studs-progress')}
          to="/dashboard/studs-progress"
        >
          <i className="nav-icon fe fe-pie-chart  me-2" />
          Student Progress Tracking
        </Link>
        <Link className={handleActive('exam')} to="/dashboard/exam">
          <i className="nav-icon fe fe-book-open me-2" />
          Exam
        </Link>
      </li>
    </div>
  )
}
