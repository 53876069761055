import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import axios from "axios";
import Select from "react-select";
import { BASE_URL } from "../../../../utils";

function CreateExam() {
  const baseUrl = BASE_URL;
  const token = localStorage.getItem("token");

  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState("");

  const [mode, setMode] = useState("create");
  const [activeId, setActiveId] = useState(-1);
  const [exams, setExams] = useState([]);
  const [examName, setExamName] = useState("");
  const [examDuration, setExamDuration] = useState("");
  const [status, setStatus] = useState("");

  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState("");
  const [newOptions, setNewOptions] = useState(["", "", "", ""]);
  const [newCorrectAnswer, setNewCorrectAnswer] = useState("");

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];

  useEffect(() => {
    if (!courses.length) {
      getCourses();
      getAllExams();
    }
  });

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getCourses = () => {
    axios
      .get(baseUrl + "/courses", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let crs = [];
        for (const course of response.data.courses) {
          crs.push({ value: course.id, label: course.name });
        }
        setCourses(crs);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const getAllExams = () => {
    axios
      .get(baseUrl + "/exams", config)
      .then((response) => {
        setExams(response.data.exams);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleCourseChange = (id) => {
    axios
      .get(baseUrl + "/course/" + id, config)
      .then((response) => {
        setCourse(id);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const requiredFields = [];

    if (course === "") {
      requiredFields.push("Course");
    }
    if (!examName.length) {
      requiredFields.push("Exam Name");
    }
    if (!examDuration.length) {
      requiredFields.push("Exam Duration");
    }
    if (!status.length) {
      requiredFields.push("Status");
    }
    if (!questions.length) {
      requiredFields.push("Question");
    }
    if (questions.some((question) => !question.options.length)) {
      requiredFields.push("Options");
    }
    if (questions.some((question) => !question.correctAnswer.trim())) {
      requiredFields.push("Correct Answer");
    }

    if (requiredFields.length > 0) {
      for (const field of requiredFields) {
        toast.error(`${field} is Required!`);
      }
      return;
    }

    if (mode === "create") {
      createExam();
    } else if (mode === "edit") {
      updateExam();
    }
  };

  const createExam = () => {
    const newExam = {
      course_id: course,
      name: examName,
      duration: examDuration,
      questions: questions,
      status: status,
    };

    axios
      .post(baseUrl + "/exam", newExam, config)
      .then((res) => {
        toast.success(`${res.data.message}`);
        getAllExams();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });

    clearFields();
  };

  const addQuestion = () => {
    if (!newQuestion.trim()) {
      toast.error("Question is Required!");
      return;
    }
    if (!newOptions.some((option) => option.trim())) {
      toast.error("Options are Required!");
      return;
    }
    if (!newCorrectAnswer.trim()) {
      toast.error("Correct Answer is Required!");
      return;
    }
    if (
      !newOptions.some((option) => option.trim() === newCorrectAnswer.trim())
    ) {
      toast.error("Correct Answer should be one of the options!");
      return;
    }

    setQuestions((prevQuestions) => [
      ...prevQuestions,
      {
        question: newQuestion,
        options: newOptions,
        correctAnswer: newCorrectAnswer,
      },
    ]);
    setNewQuestion("");
    setNewOptions(["", "", "", ""]);
    setNewCorrectAnswer("");
  };

  const clearFields = () => {
    setCourse("");
    setExamName("");
    setExamDuration("");
    setStatus("");
    setNewQuestion("");
    setNewOptions(["", "", "", ""]);
    setNewCorrectAnswer("");
    setQuestions([]);
  };

  const editExam = (id) => {
    setMode("edit");
    setActiveId(id);
    const exam = exams.find((exam) => exam.id === id);
    setCourse(exam.course_id);
    setExamName(exam.name);
    setExamDuration(exam.duration);
    setStatus(exam.status);
    setQuestions(exam.questions);
  };

  const updateExam = () => {
    axios
      .post(
        baseUrl + "/exam/" + activeId,
        {
          course_id: course,
          name: examName,
          duration: examDuration,
          questions: questions,
          status: status,
        },
        config
      )
      .then((res) => {
        toast.success(res.data.message);
        getAllExams();
        setMode("create");
        setActiveId(-1);
        clearFields();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption.value);
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-header">
        <h3 className="mb-0">Assessment Creation</h3>
      </div>
      <div className="card-body px-lg-5 px-4 w-100 border-0 mb-0">
        <form>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="form-label">Course</label>
                <Select
                  isSearchable={false}
                  options={courses}
                  value={courses.find((option) => option.value === course)}
                  onChange={(e) => (e ? handleCourseChange(e.value) : "")}
                  placeholder="Select a Course"
                />
              </div>
            </div>
          </div>
        </form>
        <form>
          <div className="row">
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="form-label">Exam Name</label>
                <input
                  type="text"
                  name="exam-name"
                  className="form-control"
                  onChange={(e) => setExamName(e.target.value)}
                  value={examName}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="form-label">Exam Duration (in Minutes)</label>
                <input
                  type="number"
                  min="1"
                  name="exam-duration"
                  className="form-control"
                  onChange={(e) => setExamDuration(e.target.value)}
                  value={examDuration}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="form-label">Status</label>
                <Select
                  options={statusOptions}
                  value={statusOptions.find(
                    (option) => option.value === status
                  )}
                  onChange={handleStatusChange}
                  placeholder="Exam Status"
                />
              </div>
            </div>
          </div>
        </form>
        {questions.map((question, index) => (
          <div key={index} className="mb-4">
            <div className="form-group">
              <label className="form-label">Question {index + 1}</label>
              <input
                type="text"
                name={`new-question-${index}`}
                className="form-control"
                value={question.question}
                disabled
              />
            </div>
            <label className="form-label">Options</label>

            {question.options.map((option, optionIndex) => (
              <div key={optionIndex} className="form-group mb-2">
                <input
                  type="text"
                  name={`option-${index}-${optionIndex}`}
                  className="form-control"
                  value={option}
                  disabled
                />
              </div>
            ))}
            <label className="form-label">Correct Answer</label>
            <input
              type="text"
              name={`correct-answer-${index}`}
              className="form-control"
              value={question.correctAnswer}
              disabled
            />
          </div>
        ))}
        <div className="mb-4">
          <div className="form-group">
            <label className="form-label mb-1">
              Question {questions.length + 1}
            </label>
            <input
              type="text"
              name="new-question"
              className="form-control"
              onChange={(e) => setNewQuestion(e.target.value)}
              value={newQuestion}
            />
          </div>
          <label className="form-label mt-3 mb-1">Options</label>
          {newOptions.map((option, index) => (
            <div key={index} className="form-group mb-2">
              <input
                type="text"
                name={`option-${index}`}
                className="form-control"
                value={option}
                onChange={(e) => {
                  const updatedOptions = [...newOptions];
                  updatedOptions[index] = e.target.value;
                  setNewOptions(updatedOptions);
                }}
              />
            </div>
          ))}
          <label className="form-label mt-1">Correct Answer</label>
          <input
            type="text"
            name="correct-answer"
            className="form-control"
            onChange={(e) => setNewCorrectAnswer(e.target.value)}
            value={newCorrectAnswer}
          />
          <button onClick={addQuestion} className="btn btn-primary mt-4">
            Add Question
          </button>
        </div>
        <div className="col-lg-12 d-flex align-items-end justify-content-end">
          <Link
            type="submit"
            className="btn btn-primary"
            onClick={(e) => handleSubmit(e)}
          >
            {mode === "create" ? "Create" : "Update"} Exam
          </Link>
        </div>
      </div>
      {exams.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th>Exam ID</th>
                <th>Course Name</th>
                <th>Exam Name</th>
                <th>Exam Duration (in Minutes)</th>
                <th>Questions</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {exams.map((exam) => (
                <tr key={exam.id}>
                  <td>{exam.id}</td>
                  <td>{exam.course.name}</td>
                  <td>{exam.name}</td>
                  <td>{exam.duration}</td>
                  <td>
                    {exam.questions.map((question, index) => (
                      <p key={index}>
                        <strong>Question {index + 1}:</strong>{" "}
                        {question.question}
                        <br />
                        <strong>Options:</strong> <br />
                        {question.options.map((el, index) => (
                          <>
                            {index + 1}. {el}
                            <br />{" "}
                          </>
                        ))}
                        <strong>Correct Answer:</strong>{" "}
                        {question.correctAnswer}
                        <br />
                        <br />
                      </p>
                    ))}
                  </td>
                  <td className="text-capitalize">{exam.status}</td>
                  <td className="text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => editExam(exam.id)}
                    >
                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                    </svg>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default CreateExam;
