import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../../utils";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

export default function CourseDetails() {
  const { id } = useParams();

  const navigate = useNavigate();
  const baseUrl = BASE_URL;
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const course_id = id;

  const [courseDetails, setCourseDetails] = useState(null);
  const [studentCourses, setStudentCourses] = useState([]);
  const [courseMaterials, setCourseMaterials] = useState([]);
  const [courseAssignments, setCourseAssignments] = useState([]);

  const [submissionFile, setSubmissionFile] = useState(null);
  const [submissions, setSubmissions] = useState([]);

  useEffect(() => {
    if (!course_id) {
      navigate(`/dashboard/${user.role}`);
    }
    if (!courseDetails) {
      getCourseDetails();
      getStudentCourses();
      getCourseMaterials();
      getCourseAssignments();
      getSubmissions();
    }
  });

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getCourseDetails = () => {
    axios
      .get(baseUrl + "/course/" + course_id, config)
      .then((response) => {
        setCourseDetails(response.data.course);
      })
      .catch(function (error) {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const getStudentCourses = () => {
    axios
      .get(baseUrl + "/admin/my-courses", config)
      .then((response) => {
        setStudentCourses(response.data.myCourses);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleCourseRegistration = (e) => {
    e.preventDefault();
    const crs = studentCourses.courses
      ? studentCourses.courses.map((cor) => cor.id)
      : [];
    const newStudent = {
      student_id: user.id,
      courses: [...crs, parseInt(course_id)],
    };
    if (crs.length > 0) {
      axios
        .post(
          baseUrl + "/admin/student-courses/" + studentCourses.id,
          newStudent,
          config
        )
        .then((response) => {
          toast.success(response.data.message);
          getStudentCourses();
        })
        .catch(function (error) {
          const tempdata = error.response.data;
          toast.error(`${tempdata.message}`);
        });
    } else {
      axios
        .post(baseUrl + "/admin/student-courses", newStudent, config)
        .then((response) => {
          toast.success(response.data.message);
          getStudentCourses();
        })
        .catch(function (error) {
          const tempdata = error.response.data;
          toast.error(`${tempdata.message}`);
        });
    }
  };

  const getCourseMaterials = () => {
    axios
      .get(baseUrl + "/materials/" + course_id, config)
      .then((response) => {
        setCourseMaterials(response.data.materials);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const getCourseAssignments = () => {
    axios
      .get(baseUrl + "/assignments/" + course_id, config)
      .then((response) => {
        setCourseAssignments(response.data.assignments);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleAssignmentSubmission = (assignmentId) => {
    if (!submissionFile) {
      toast.error("Please select a file!");
      return;
    }
    const formData = new FormData();
    formData.append("assignment_id", assignmentId);
    formData.append("student_id", user.id);
    formData.append("file", submissionFile);

    axios
      .post(baseUrl + "/submission", formData, config)
      .then((response) => {
        toast.success(response.data.message);
        getCourseAssignments();
        getSubmissions();
        setSubmissionFile(null);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const getSubmissions = () => {
    axios
      .get(baseUrl + "/submissions/" + course_id, config)
      .then((response) => {
        setSubmissions(response.data.submissions);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const [active, setActive] = useState(null);

  if (!courseDetails) {
    return (
      <div>
        {/* loading */}
        loading...
      </div>
    );
  }
  return (
    <div>
      <section className="pt-lg-8 pb-8 bg-primary">
        <div className="container pb-lg-8">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-7 col-md-12">
              <div>
                <h1 className="text-white display-4 fw-semibold">
                  {courseDetails.name}
                </h1>
                <p className="text-white mb-6 lead">
                  {courseDetails.description}
                </p>
                <div className="d-flex align-items-center">
                  <div>
                    <span className="fs-6 ms-4 align-text-top">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={12}
                        height={12}
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                      </svg>
                    </span>
                    <span className="text-white">
                      {Math.ceil(courseDetails.rating)}
                    </span>
                  </div>
                  <div>
                    <span className="fs-6 ms-4 align-text-top">
                      {/* user svg */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={12}
                        height={12}
                        fill="currentColor"
                        className="bi bi-person-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      </svg>
                    </span>
                    <span className="text-white ">
                      {courseDetails.instructor.name}
                    </span>
                  </div>
                  <div>
                    <span className="fs-6 ms-4 align-text-top">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="orange"
                          d="M2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6zm3.519 0L12 11.671L18.481 6H5.52zM20 7.329l-7.341 6.424a1 1 0 0 1-1.318 0L4 7.329V18h16V7.329z"
                        />
                      </svg>
                    </span>
                    <span className="text-white ">
                      {courseDetails.instructor.email}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-12 mt-n8 mb-4 mb-lg-0">
              {/* Card */}
              <div className="card rounded-3">
                {/* Card header */}
                <div className="card-header border-bottom-0 p-0">
                  <div>
                    {/* Nav */}
                    <ul className="nav nav-lb-tab" id="tab" role="tablist">
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          id="table-tab"
                          data-bs-toggle="pill"
                          to="#table"
                          role="tab"
                          aria-controls="table"
                          aria-selected="true"
                        >
                          Contents
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          id="description-tab"
                          data-bs-toggle="pill"
                          to="#description"
                          role="tab"
                          aria-controls="description"
                          aria-selected="false"
                        >
                          Description
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          id="faq-tab"
                          data-bs-toggle="pill"
                          to="#faq"
                          role="tab"
                          aria-controls="faq"
                          aria-selected="false"
                        >
                          Requirements
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          id="material-tab"
                          data-bs-toggle="pill"
                          to="#material"
                          role="tab"
                          aria-controls="material"
                          aria-selected="false"
                        >
                          Course Materials
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          id="ass-tab"
                          data-bs-toggle="pill"
                          to="#ass"
                          role="tab"
                          aria-controls="ass"
                          aria-selected="false"
                        >
                          Assignments
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* Card Body */}
                <div className="card-body">
                  <div className="tab-content" id="tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="table"
                      role="tabpanel"
                      aria-labelledby="table-tab"
                    >
                      {/* Card */}
                      <div className="accordion" id="courseAccordion">
                        <div>
                          {/* List group */}
                          <ul className="list-group list-group-flush">
                            {courseDetails.sections.map(
                              (section, sectionIdx) => (
                                <li
                                  key={section.name}
                                  onClick={() => {
                                    setActive(sectionIdx);
                                  }}
                                  id={"section-" + sectionIdx}
                                  className="list-group-item px-0 pt-3"
                                >
                                  <Link
                                    className={`h4 mb-0 d-flex align-items-center ${
                                      sectionIdx === active ? "active" : ""
                                    }`}
                                  >
                                    <div className="me-auto">
                                      {section.name}
                                    </div>
                                    {/* Chevron */}
                                    <span className="chevron-arrow ms-4">
                                      <i
                                        style={{ color: "#009688" }}
                                        className="fe fe-chevron-down fs-4"
                                      />
                                    </span>
                                  </Link>

                                  <div
                                    className={`collapse ${
                                      sectionIdx === active ? "show" : ""
                                    }`}
                                  >
                                    <div className="pt-3 pb-2">
                                      {courseDetails.lessons[sectionIdx].map(
                                        (lesson, lessonIdx) => (
                                          <Link
                                            key={lessonIdx}
                                            className="mb-2 d-flex justify-content-between align-items-center text-inherit"
                                          >
                                            <div className="text-truncate">
                                              <span className="icon-shape bg-light icon-sm rounded-circle me-2">
                                                {lessonIdx + 1}
                                              </span>
                                              <span>{lesson.name}</span>
                                            </div>
                                          </Link>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="description"
                      role="tabpanel"
                      aria-labelledby="description-tab"
                    >
                      {/* Description */}
                      <div className="mb-4">
                        <h3 className="mb-2">Course Descriptions</h3>
                        <p>{courseDetails.description}</p>
                      </div>
                      <h4 className="mb-3">What you'll learn</h4>
                      <div className="row mb-3">
                        <div className="col-12 col-md-6">
                          <ul className="list-unstyled">
                            <li className="d-flex mb-2">
                              <span className="me-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={14}
                                  height={14}
                                  fill="currentColor"
                                  className="bi bi-check-circle text-success"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                </svg>
                              </span>
                              <span>{courseDetails.learnings}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="faq"
                      role="tabpanel"
                      aria-labelledby="faq-tab"
                    >
                      {/* FAQ */}
                      <div>
                        <h3 className="mb-3">Course - Requirements</h3>
                        <div className="mb-4">{courseDetails.requirements}</div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="material"
                      role="tabpanel"
                      aria-labelledby="material-tab"
                    >
                      <div className="row">
                        {courseMaterials?.length > 0 ? (
                          courseMaterials.map((material) => (
                            <div className="col-12 mb-3" key={material.id}>
                              <div className="border border-light px-4 py-2 d-flex align-items-center justify-content-between ">
                                <h2 className="fw-700 font-sm mb-3 mt-1 pl-1 mb-3">
                                  {material.name}
                                </h2>

                                <Link
                                  to={material.file}
                                  target="_blank"
                                  className="btn btn-primary text-white fw-600 text-uppercase"
                                >
                                  Download
                                </Link>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="col-lg-12 mt-5 mb-5 text-center">
                            <h4>There are No Course Materials added yet</h4>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="ass"
                      role="tabpanel"
                      aria-labelledby="ass-tab"
                    >
                      <div>
                        <div className="row">
                          {courseAssignments?.length > 0 &&
                          courseAssignments.some(
                            (cass) => cass.status === "active"
                          ) ? (
                            courseAssignments.map((assignment) => (
                              <div className="col-12 mb-3" key={assignment.id}>
                                <div className="border px-4 pb-4 pt-2 border-light mt-1">
                                  <h2 className="fw-700 font-sm mb-3 mt-1 pl-1 mb-3">
                                    {assignment.title}
                                  </h2>
                                  <p className="font-xssss fw-500 lh-28 text-grey-600 ml-1">
                                    {assignment.description}
                                  </p>
                                  <p className="font-xssss fw-500 lh-28 ml-1 text-danger">
                                    Due Date :{" "}
                                    {new Date(assignment.due).toLocaleString()}
                                  </p>
                                  <p className="font-xsss fw-500 lh-28 ml-1 text-grey-600">
                                    <label>
                                      Upload your submission file here :<br />
                                      <span className="text-info font-xssss">
                                        Max File Size {"<="} 2 MB
                                      </span>
                                      <br />
                                      <span className="text-info font-xssss">
                                        Supported File Types : pdf, doc, docx
                                      </span>
                                    </label>
                                    <br />
                                    {submissions?.length > 0 &&
                                    submissions.some(
                                      (submission) =>
                                        submission.assignment_id ===
                                        assignment.id
                                    ) ? null : (
                                      <input
                                        className="font-xssss fw-500 lh-28 text-grey-600"
                                        type="file"
                                        name="material-file"
                                        accept=".pdf, .doc, .docx"
                                        onChange={(e) =>
                                          setSubmissionFile(e.target.files[0])
                                        }
                                      />
                                    )}
                                  </p>
                                  <hr />
                                  <div className="d-flex align-items-center justify-content-between">
                                    <Link
                                      to={assignment.file}
                                      target="_blank"
                                      className="btn btn-info text-white fw-600 text-uppercase font-xssss"
                                    >
                                      Download
                                    </Link>
                                    {submissions?.length > 0 &&
                                    submissions.some(
                                      (submission) =>
                                        submission.assignment_id ===
                                        assignment.id
                                    ) ? null : (
                                      <Link
                                        className="btn btn-success text-white fw-600 text-uppercase font-xssss float-right mr-1"
                                        onClick={() =>
                                          handleAssignmentSubmission(
                                            assignment.id
                                          )
                                        }
                                      >
                                        Submit Assignment
                                      </Link>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="col-lg-12 mt-5 mb-5 text-center">
                              <h4>There are No Assignments added yet</h4>
                            </div>
                          )}
                        </div>
                        {submissions?.length > 0 && (
                          <div className="card-body w-100 table-responsive">
                            <table className="table table-bordered data-table">
                              <thead>
                                <tr>
                                  <th>Submission ID</th>
                                  <th>Assignment</th>
                                  <th>File</th>
                                  <th>Feedback</th>
                                  <th>Grade</th>
                                  <th>Submitted At</th>
                                </tr>
                              </thead>
                              <tbody>
                                {submissions.map((submission) => (
                                  <tr key={submission.id}>
                                    <td>{submission.id}</td>
                                    <td>{submission.assignment.title}</td>
                                    <td>
                                      <Link
                                        className="text-primary"
                                        to={submission.file}
                                        target="_blank"
                                      >
                                        Download
                                      </Link>
                                    </td>
                                    <td>
                                      {submission.feedback ||
                                        "Yet to be reviewed"}
                                    </td>
                                    <td>
                                      {submission.grade || "Yet to be reviewed"}
                                    </td>
                                    <td>
                                      {new Date(
                                        submission.created_at
                                      ).toLocaleString()}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-12 mt-lg-n8">
              {/* Card */}
              <div className="card mb-3 mb-4">
                {/* Card body */}
                <div className="card-body">
                  {/* Price single page */}
                  <h4 className="pl-35 mb-4 font-xsss fw-600 text-grey-900 position-relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="15"
                      viewBox="0 0 640 512"
                    >
                      <path
                        fill="currentColor"
                        d="M0 128c0-35.3 28.7-64 64-64h512c35.3 0 64 28.7 64 64v256c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zm320 0v256h256V128H320zm-141.7 47.9c-3.2-7.2-10.4-11.9-18.3-11.9s-15.1 4.7-18.3 11.9l-64 144c-4.5 10.1.1 21.9 10.2 26.4s21.9-.1 26.4-10.2l8.9-20.1h73.6l8.9 20.1c4.5 10.1 16.3 14.6 26.4 10.2s14.6-16.3 10.2-26.4l-64-144zM160 233.2l19 42.8h-38l19-42.8zM448 164c11 0 20 9 20 20v4h60c11 0 20 9 20 20s-9 20-20 20h-2l-1.6 4.5c-8.9 24.4-22.4 46.6-39.6 65.4c.9.6 1.8 1.1 2.7 1.6l18.9 11.3c9.5 5.7 12.5 18 6.9 27.4s-18 12.5-27.4 6.9L467 333.8c-4.5-2.7-8.8-5.5-13.1-8.5c-10.6 7.5-21.9 14-34 19.4l-3.6 1.6c-10.1 4.5-21.9-.1-26.4-10.2s.1-21.9 10.2-26.4l3.6-1.6c6.4-2.9 12.6-6.1 18.5-9.8L410 286.1c-7.8-7.8-7.8-20.5 0-28.3s20.5-7.8 28.3 0l14.6 14.6l.5.5c12.4-13.1 22.5-28.3 29.8-45H376c-11 0-20-9-20-20s9-20 20-20h52v-4c0-11 9-20 20-20z"
                      />
                    </svg>
                    &nbsp; Language
                    <span className="d-block text-grey-500 mt-1 font-xssss">
                      &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                      {JSON.parse(courseDetails.languages).join(", ")}
                    </span>
                  </h4>
                  <h4 className="pl-35 mb-0 font-xsss fw-600 text-grey-900 position-relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        stroke-miterlimit="10"
                        stroke-width="32"
                        d="M256 64C150 64 64 150 64 256s86 192 192 192s192-86 192-192S362 64 256 64Z"
                      />
                      <path
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="32"
                        d="M256 128v144h96"
                      />
                    </svg>
                    &nbsp; Duration
                    <span className="d-block text-grey-500 mt-1 font-xssss">
                      &nbsp; &nbsp; &nbsp; {courseDetails.duration}
                    </span>
                  </h4>
                  <div className=" mt-4 d-grid">
                    {courseDetails.id &&
                    !studentCourses?.courses?.find(
                      (course) => course.id === courseDetails.id
                    ) ? (
                      <Link
                        className="btn btn-warning"
                        onClick={handleCourseRegistration}
                      >
                        Register Now
                      </Link>
                    ) : (
                      <Link className="btn btn-primary ">
                        <span>Registered</span>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
