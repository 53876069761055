import { Link } from 'react-router-dom'
import ManageAdmin from '../../pages/DashBoard/ManageAdmin'
import ManageStudent from '../../pages/DashBoard/student/ManageStudent'
import ManageCoordinator from '../../pages/DashBoard/coordinator/ManageCoordinator'
import ManageInstructor from '../../pages/DashBoard/instructor/ManageInstructor'
import { useSelector } from 'react-redux'
import Chat from '../../pages/DashBoard/Chat'

export default function NavBar() {
  const { messageCount } = useSelector(state => state.app)

  const getUserRole = () => {
    return localStorage.getItem('role')
  }
  const userRole = getUserRole()

  return (
    <nav className="navbar-vertical navbar">
      <div className="d-none">
        <Chat />
      </div>
      <div className="vh-00" data-simplebar>
        <Link className="navbar-brand" to={'/dashboard/' + userRole}>
          <img
            src="../../assets/images/brand/logo/logobz.png"
            alt="UTA CANVAS"
            style={{ marginRight: '1rem' }}
          />
          <span className="fs-3">
            <b>UTA CANVAS</b>
          </span>
        </Link>
        <ul className="navbar-nav flex-column" id="sideNavbar">
          {userRole === 'admin' ? <ManageAdmin /> : null}
          {userRole === 'student' ? <ManageStudent /> : null}
          {userRole === 'coordinator' ? <ManageCoordinator /> : null}
          {userRole === 'instructor' ? <ManageInstructor /> : null}

          <li className="nav-item">
            <Link className="position-relative nav-link" to="/dashboard/chat">
              <i className="nav-icon fe fe-message-square me-2" />
              Chat
              {messageCount ? (
                <span
                  className="position-absolute badge rounded-pill bg-danger"
                  style={{ right: 20 }}
                >
                  {messageCount}
                </span>
              ) : null}
            </Link>
          </li>

          <li className="nav-item">
            <div className="navbar-heading">Settings</div>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/dashboard/edit-profile">
              <i className="nav-icon fe fe-user me-2" />
              Profile
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/dashboard/security">
              <i className="nav-icon fe fe-shield me-2" />
              Security
            </Link>
          </li>
        </ul>
        <div className="card bg-dark-primary shadow-none text-center mx-4 my-8"></div>
      </div>
    </nav>
  )
}
