import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import validator from 'validator'
import { BASE_URL, LOGIN } from '../../../utils'
import axios from 'axios'
import useAuth from '../../../hooks/useAuth'

export default function Login() {
  const navigate = useNavigate()
  const baseUrl = BASE_URL
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  let isError = false
  const { setAuth } = useAuth()

  const handleSubmit = e => {
    e.preventDefault()

    let fieldName = []
    if (!email.length) {
      fieldName.push('Email')
    }
    if (!password.length) {
      fieldName.push('Password ')
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + ' is Required!')
      }
      isError = true
    }

    if (email.length && !validator.isEmail(email)) {
      toast.error('Email is Invalid!')
      isError = true
    }

    if (isError) {
      return
    }
    localStorage.setItem('email', email)
    loginUser()
  }
  function loginUser() {
    axios
      .post(baseUrl + LOGIN, {
        email: email,
        password: password,
      })
      .then(response => {
        const data = response.data
        localStorage.setItem('name', data.name)
        localStorage.setItem('token', data.token)
        localStorage.setItem('role', data.role)
        localStorage.setItem('user', JSON.stringify(data))
        setAuth({
          name: `${data.name}`,
          token: `${data.token}`,
          role: `${data.role}`,
        })
        toast.success('Logged In Successfully!')
        navigate('/dashboard/' + data.role, { replace: true })
      })
      .catch(function (error) {
        toast.error(error.response?.data?.message)
      })
  }
  return (
    <div>
      <main>
        <section className="container d-flex flex-column vh-100">
          <div className="row align-items-center justify-content-center g-0 h-lg-100 py-8">
            <div className="col-lg-5 col-md-8 py-8 py-xl-0">
              <div className="card shadow">
                <div className="card-body p-6">
                  <div className="mb-4">
                    <Link href="../index.html">
                      <img
                        src="../assets/images/brand/logo/logobz.png"
                        className="mb-4"
                        alt="logo-icon"
                      />
                    </Link>
                    <h1 className="mb-1 fw-bold">Sign in</h1>
                    <span>
                      Don't have an account?
                      <Link
                        to="/auth/signup"
                        className="ms-1"
                        style={{ color: '#009688' }}
                      >
                        Sign up
                      </Link>
                    </span>
                  </div>
                  <form className="needs-validation" noValidate>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="form-control"
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        placeholder="Email address here"
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter valid username.
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        name="password"
                        placeholder="**************"
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter valid password.
                      </div>
                    </div>
                    <div className="d-lg-flex justify-content-between align-items-center mb-4">
                      <div>
                        <Link
                          to="/auth/forgot-password"
                          style={{ color: '#009688' }}
                        >
                          Forgot your password?
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div className="d-grid">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={e => handleSubmit(e)}
                        >
                          Sign in
                        </button>
                      </div>
                    </div>
                    <hr className="my-4" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}
