import axios from "axios";
import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import { BASE_URL } from "../../../../utils";

function ListExams() {
  const baseUrl = BASE_URL;
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const student_id = user.id;

  const [exams, setExams] = useState([]);
  const [exam, setExam] = useState(null);
  const [question, setQuestion] = useState(null);
  const [questionCount, setQuestionCount] = useState(1);
  const [optionSelected, setOptionSelected] = useState(null);
  const [score, setScore] = useState(0);
  const [grades, setGrades] = useState([]);

  const { seconds, minutes, pause, restart } = useTimer({
    onExpire: () => {
      toast.error("Time Up!");
      setExam(null);
      saveExamScore();
    },
  });

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    if (!exams.length) {
      getMyCourses();
      getExams();
      getGrades();
    }
  });

  const getExams = () => {
    axios
      .get(baseUrl + "/exams", config)
      .then((response) => {
        setExams(response.data.exams);
      })
      .catch(function (error) {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const getGrades = () => {
    axios
      .get(baseUrl + "/exam-scores", config)
      .then((response) => {
        setGrades(response.data.grades);
      })
      .catch(function (error) {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const startExam = (item) => {
    setExam(item);
    setQuestion(item.questions[0]);
    restart(Date.now() + item.duration * 60 * 1000);
  };

  const nextQuestion = () => {
    setOptionSelected(null);
    if (questionCount < exam.questions.length) {
      setQuestion(exam.questions[questionCount]);
      setQuestionCount(questionCount + 1);
    } else {
      pause();
      setQuestion(null);
      saveExamScore();
    }
  };

  const handleOptionClick = (option, idx) => {
    console.log(idx);
    setOptionSelected(idx);
    if (option === exam.questions[questionCount - 1].correctAnswer) {
      setScore(parseInt(score) + 10);
    }
  };

  const saveExamScore = () => {
    const examScore = {
      student_id: student_id,
      course_id: exam.course_id,
      exam_id: exam.id,
      score: score,
    };

    axios
      .post(baseUrl + "/exam-score", examScore, config)
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch(function (error) {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const [studentCourses, setStudentCourses] = useState([]);

  const getMyCourses = () => {
    axios
      .get(baseUrl + "/admin/my-courses", config)
      .then((response) => {
        setStudentCourses(response.data.myCourses);
      })
      .catch((error) => {
        toast.error(`${error}`);
      });
  };

  return (
    <div className="col-xl-9 col-lg-9 col-md-12">
      <div className="row">
        {exams?.length > 0 &&
        !exam &&
        studentCourses?.courses?.length > 0 &&
        exams?.filter((exa) =>
          studentCourses.courses?.some(
            (cor) => cor.id === exa.course_id && cor.status === "active"
          )
        ).length > 0
          ? exams.map(
              (item) =>
                studentCourses.courses?.some(
                  (scor) => scor.id.toString() === item.course_id.toString()
                ) && (
                  <div className="col-xxl-4 col-xl-4 col-md-12" key={item.id}>
                    <div className="card mb-4 d-block w-100 shadow-xss rounded-lg p-md-5 p-4 border-0 text-center">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="45"
                          height="45"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M202.24 74C166.11 56.75 115.61 48.3 48 48a31.36 31.36 0 0 0-17.92 5.33A32 32 0 0 0 16 79.9V366c0 19.34 13.76 33.93 32 33.93c71.07 0 142.36 6.64 185.06 47a4.11 4.11 0 0 0 6.94-3V106.82a15.89 15.89 0 0 0-5.46-12A143 143 0 0 0 202.24 74Zm279.68-20.7A31.33 31.33 0 0 0 464 48c-67.61.3-118.11 8.71-154.24 26a143.31 143.31 0 0 0-32.31 20.78a15.93 15.93 0 0 0-5.45 12v337.13a3.93 3.93 0 0 0 6.68 2.81c25.67-25.5 70.72-46.82 185.36-46.81a32 32 0 0 0 32-32v-288a32 32 0 0 0-14.12-26.61Z"
                          />
                        </svg>
                      </div>
                      <h4 className="fw-700 font-xs mt-4">{item.name}</h4>
                      <div className="clearfix" />
                      <span className="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-info d-inline-block text-info">
                        Test Duration : {item.duration} Min
                      </span>
                      <div className="clearfix" />
                      <div className="card-body p-0 w250 ml-auto mr-auto">
                        <div className="timer mt-4 mb-2" />
                      </div>
                      {grades.filter(
                        (grade) =>
                          grade.exam_id === item.id &&
                          grade.student_id === student_id
                      ).length > 0 ? (
                        <div>
                          <Link className="btn btn-success py-2 px-5 btn-sm mt-4">
                            Your Score :{" "}
                            {
                              grades.filter(
                                (grade) =>
                                  grade.exam_id === item.id &&
                                  grade.student_id === student_id
                              )[0].score
                            }
                          </Link>
                        </div>
                      ) : (
                        <Link
                          className="btn btn-primary py-2 px-5 btn-sm mt-4"
                          onClick={() => startExam(item)}
                        >
                          Take Test
                        </Link>
                      )}
                    </div>
                  </div>
                )
            )
          : !exam && (
              <h4 className="mt-3 p-5 ml-5 font-lg text-grey-500">
                No Exams Available
              </h4>
            )}

        {exam && (
          <div>
            <div className="col-xl-12 col-xl-12 col-md-12">
              <div className="card mb-4 d-block w-100 shadow-xss rounded-lg p-3 border-0 text-left question-div">
                <div className="card-body p-0 d-flex align-items-center justify-content-between">
                  <h6 className="font-xssss text-uppercase text-current fw-700 ls-3 mt-2">
                    Please do not refresh the page or close the browser{" "}
                  </h6>
                  <h6
                    id="timer"
                    className="border border-dark p-2 text-center"
                    style={{ width: "150px" }}
                  >
                    Time Left : {minutes}:{seconds}
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-xxl-8 col-xl-12 col-md-12">
              <div className="card mb-4 d-block w-100 shadow-xss rounded-lg p-5 border-0 text-left question-div">
                {question && (
                  <div className="card-body p-0" id="question">
                    <span className="font-xs text-primary text-uppercase text-current fw-700 ls-3">
                      Question {questionCount}
                    </span>
                    <h3 className="font-sm text-grey-800 fw-700 lh-32 mt-4 mb-4">
                      {question.question}
                    </h3>
                    {question.options.map((option, idx) => (
                      <p
                        key={option}
                        onClick={() => handleOptionClick(option, idx)}
                        style={{ cursor: "pointer" }}
                        className={` p-4 mt-3 rounded-lg font-xs fw-600  mb-0 p-2
                          ${
                            optionSelected === idx
                              ? "bg-primary text-light"
                              : "bg-light text-grey-700"
                          }`}
                        onKeyDown={(e) => {}}
                      >
                        {option}
                      </p>
                    ))}
                    <Link
                      onClick={nextQuestion}
                      className="btn btn-primary mt-5 px-5"
                    >
                      NEXT
                    </Link>
                  </div>
                )}
                {!question && (
                  <div
                    className="card-body text-center p-3 bg-no-repeat bg-image-topcenter"
                    id="question4"
                    style={{
                      backgroundImage: "url(images/user-pattern.png)",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                    >
                      <g
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                      >
                        <path d="m24 4l5.253 3.832l6.503-.012l1.997 6.188l5.268 3.812L41 24l2.021 6.18l-5.268 3.812l-1.997 6.188l-6.503-.012L24 44l-5.253-3.832l-6.503.012l-1.997-6.188l-5.268-3.812L7 24l-2.021-6.18l5.268-3.812l1.997-6.188l6.503.012L24 4Z" />
                        <path d="m17 24l5 5l10-10" />
                      </g>
                    </svg>

                    <h2 className="fw-700 mt-5 text-grey-900 font-xxl">
                      Congratulations!
                    </h2>
                    <p className="font-xssss fw-600 lh-30 text-grey-500 mb-0 p-2">
                      You have completed the test. <br /> If you have any
                      queries, please talk to your coodinator.
                    </p>
                    <Link className="btn btn-primary mt-5 px-5">
                      YOUR SCORE : {score} / {exam.questions.length * 10}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ListExams;
