export default function Blog() {
  return (
    <main>
      <section className="mt-2">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <iframe
              src="https://sxr0508.uta.cloud/"
              title="Blog"
              style={{ width: "100%", height: "80vh", border: "none" }}
            />
          </div>
        </div>
      </section>
    </main>
  );
}
