import { Link, useLocation } from 'react-router-dom'

export default function ManageCoordinator() {
  const location = useLocation()

  const handleActive = link => {
    let className = 'nav-link'
    if (location.pathname.includes(link)) {
      className += ' active'
    }

    return className
  }

  return (
    <div>
      <li className="nav-item">
        <Link
          className={handleActive('program-implementation')}
          to="/dashboard/program-implementation"
        >
          <i className="nav-icon fe fe-grid me-2" />
          Program Overview and Alignment
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={handleActive('curriculum-development')}
          to="/dashboard/curriculum-development"
        >
          <i className="nav-icon fe fe-rss me-2" />
          Curriculum Development and Updates
        </Link>
        <Link
          className={handleActive('program-evaluation')}
          to="/dashboard/program-evaluation"
        >
          <i className="nav-icon fe fe-award me-2" />
          Program evaluation
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={handleActive('support-enquiries')}
          to="/dashboard/support-enquiries"
        >
          <i className="nav-icon fe fe-inbox me-2" />
          Support & Enquiries
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={handleActive('student-performancedata')}
          to="/dashboard/student-performancedata"
        >
          <i className="nav-icon fe fe-bar-chart me-2" />
          Student Performance Data
        </Link>
      </li>
    </div>
  )
}
