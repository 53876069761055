import { BASE_URL } from "../../../../utils";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

export default function EditProfile() {
  const baseUrl = BASE_URL;
  const token = localStorage.getItem("token");
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", user.name);
    formData.append("email", user.email);
    formData.append("phone", user.phone);
    formData.append("role", user.role);
    formData.append("address", user.address);

    if (user.image instanceof File) {
      formData.append("image", user.image);
    }

    axios
      .post(baseUrl + "/user/" + user.id, formData, config)
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response.data));
        toast.success("User Updated Successfully!");
        setUser(response.data);
      })
      .catch(function (error) {
        const tempdata = error.response.data;

        if (tempdata.errors) {
          const errorKeys = Object.keys(tempdata.errors);
          for (const errorKey of errorKeys) {
            for (const error of tempdata.errors[errorKey]) {
              toast.error(`${error}`);
            }
          }
        }
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };
  return (
    <div>
      <div className="col-lg-12 col-md-12 col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="mb-0">Profile Details</h3>
            <p className="mb-0">
              You have full control to manage your own account setting.
            </p>
          </div>
          <div className="card-body">
            <div className="d-lg-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center mb-4 mb-lg-0">
                <img
                  src={
                    user.image instanceof File
                      ? URL.createObjectURL(user.image)
                      : user.image
                  }
                  id="img-uploaded"
                  className="avatar-xl rounded-circle"
                  alt="avatar"
                />
                <div className="ms-3">
                  <h4 className="mb-0">Your avatar</h4>
                  <p className="mb-0">
                    PNG or JPG no bigger than 800px wide and tall.
                  </p>
                </div>
              </div>
              <div>
                <input
                  class="form-control"
                  type="file"
                  id="formFileMultiple"
                  onChange={(e) =>
                    setUser({ ...user, image: e.target.files[0] })
                  }
                />
              </div>
            </div>
            <hr className="my-5" />
            <div>
              <h4 className="mb-0">Personal Details</h4>
              <p className="mb-4">
                Edit your personal information and address.
              </p>
              <form className="row gx-3 needs-validation" noValidate>
                <div className="mb-3 col-12 col-md-6">
                  <label className="form-label" htmlFor="lname">
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="lname"
                    className="form-control"
                    placeholder="Full Name"
                    required
                    name="name"
                    onChange={handleChange}
                    value={user.name}
                  />
                </div>
                <div className="mb-3 col-12 col-md-6">
                  <label className="form-label" htmlFor="phone">
                    Phone
                  </label>
                  <input
                    type="text"
                    id="phone"
                    className="form-control"
                    placeholder="Phone"
                    required
                    name="phone"
                    onChange={handleChange}
                    value={user.phone}
                  />
                  <div className="invalid-feedback">
                    Please enter phone number.
                  </div>
                </div>
                <div className="mb-3 col-12 col-md-6">
                  <label className="form-label" htmlFor="birth">
                    Email
                  </label>
                  <input
                    className="form-control flatpickr"
                    type="email"
                    placeholder="Email"
                    id="birth"
                    name="email"
                    onChange={handleChange}
                    value={user.email}
                    disabled
                  />
                  <div className="invalid-feedback">Please choose a date.</div>
                </div>
                <div className="mb-3 col-12 col-md-6">
                  <label className="form-label" htmlFor="address">
                    Role
                  </label>
                  <input
                    type="text"
                    id="address"
                    className="form-control"
                    placeholder="Role"
                    required
                    name="role"
                    onChange={handleChange}
                    value={user.role}
                    disabled
                  />
                  <div className="invalid-feedback">Please enter address.</div>
                </div>
                <div className="mb-3 col-12 col-md-6">
                  <label className="form-label" htmlFor="address2">
                    Address
                  </label>
                  <input
                    type="text"
                    id="address2"
                    className="form-control"
                    placeholder="Address"
                    required
                    name="address"
                    onChange={handleChange}
                    value={user.address}
                  />
                  <div className="invalid-feedback">Please enter address.</div>
                </div>

                <div className="col-12">
                  <button
                    onClick={handleSubmit}
                    className="btn btn-primary"
                    type="submit"
                  >
                    Update Profile
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
