import { Link, useLocation } from 'react-router-dom'

export default function ManageInstructor() {
  const location = useLocation()

  const handleActive = link => {
    let className = 'nav-link'
    if (location.pathname.includes(link)) {
      className += ' active'
    }

    return className
  }

  return (
    <div>
      <li className="nav-item">
        <Link
          className={
            handleActive('create-courses') || handleActive('instructor')
          }
          to="/dashboard/create-courses"
        >
          <i className="nav-icon fe fe-grid me-2" />
          Course Management
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={handleActive('create-exam')}
          to="/dashboard/create-exam"
        >
          <i className="nav-icon fe fe-book me-2" />
          Create Exam
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={handleActive('course-content')}
          to="/dashboard/course-content"
        >
          <i className="nav-icon fe fe-sliders me-2" />
          Course Content
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={handleActive('course-material')}
          to="/dashboard/course-material"
        >
          <i className="nav-icon fe fe-database me-2" />
          Course Materials
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={handleActive('manage-assignment')}
          to="/dashboard/manage-assignment"
        >
          <i className="nav-icon fe fe-bookmark me-2" />
          Manage Assignments
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={handleActive('manage-submissions')}
          to="/dashboard/manage-submissions"
        >
          <i className="nav-icon fe fe-check-circle me-2" />
          Manage Submissions
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={handleActive('grade-students')}
          to="/dashboard/grade-students"
        >
          <i className="nav-icon fe fe-list me-2" />
          Grade Students
        </Link>
      </li>
    </div>
  )
}
