import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import validator from "validator";
import { BASE_URL } from "../../../utils";
import axios from "axios";

export default function ManageUsers(props) {
  const manage = props.manage;

  const location = useLocation();
  let isError = false;
  const [mode, setMode] = useState("add");
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({
    id: "",
    name: "",
    email: "",
    phone: "",
    address: "",
    image: "",
  });

  const baseUrl = BASE_URL;
  const token = localStorage.getItem("token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(baseUrl + "/role-users", {
        ...config,
        params: {
          role: manage.split(" ")[0].toString().toLowerCase(),
        },
      });
      setUsers(response.data.users);
    } catch (error) {
      console.error("Error fetching users list:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [location]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!user.name.length) {
      fieldName.push("Name");
    }
    if (!user.email.length) {
      fieldName.push("Email");
    }
    if (!user.phone.length) {
      fieldName.push("Phone");
    }
    if (!user.address.length) {
      fieldName.push("Address");
    }

    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }

    if (user.email.length && !validator.isEmail(user.email)) {
      toast.error("Email is Invalid!");
      isError = true;
    }

    const phoneNumberValidation = /^[+]?\d+$/;
    if (
      user.phone.length &&
      !validator.isMobilePhone(user.phone) &&
      !phoneNumberValidation.test(user.phone)
    ) {
      toast.error("Phone Number is Invalid!");
      isError = true;
    }

    if (isError) {
      return;
    }
    mode === "add" ? addUser() : updateUser(user);
  };

  const clearFields = () => {
    setUser({
      name: "",
      email: "",
      phone: "",
      address: "",
      image: "",
    });
  };

  const addUser = () => {
    const formData = new FormData();
    formData.append("name", user.name);
    formData.append("email", user.email);
    formData.append("password", "12345678");
    formData.append("phone", user.phone);
    formData.append("address", user.address);
    if (user.image instanceof File) {
      formData.append("image", user.image);
    }
    formData.append("role", manage.split(" ")[0].toString().toLowerCase());

    axios
      .post(baseUrl + "/user", formData, config)
      .then((response) => {
        toast.success(manage + " Added Successfully!");
        fetchData();
        clearFields();
      })
      .catch((error) => {
        console.log(error);
      });

    clearFields();
  };

  const editUser = (id) => {
    setMode("edit");
    const activeUser = users.find((user) => user.id === id);
    setUser({
      id: activeUser.id,
      name: activeUser.name,
      email: activeUser.email,
      phone: activeUser.phone,
      address: activeUser.address,
      image: activeUser.image,
    });
  };

  const changeUserStatus = (id) => {
    const activeUser = users.find((user) => user.id === id);
    activeUser.is_active = !activeUser.is_active;
    updateUser(activeUser);
  };

  const updateUser = (activeUser) => {
    const formData = new FormData();
    formData.append("name", activeUser.name);
    formData.append("email", activeUser.email);
    formData.append("phone", activeUser.phone);
    formData.append("address", activeUser.address);
    if (activeUser.image instanceof File) {
      formData.append("image", activeUser.image);
    }
    formData.append("is_active", activeUser.is_active);

    axios
      .post(baseUrl + "/user/" + activeUser.id, formData, config)
      .then((response) => {
        toast.success(manage + " Updated Successfully!");
        fetchData();
        clearFields();
        setMode("add");
      })
      .catch(function (error) {
        const tempdata = error.response.data;

        if (tempdata.errors) {
          const errorKeys = Object.keys(tempdata.errors);
          for (const errorKey of errorKeys) {
            for (const error of tempdata.errors[errorKey]) {
              toast.error(`${error}`);
            }
          }
        }
      });
  };

  const handleChange = (event) => {
    if (event.target.name === "image") {
      setUser({
        ...user,
        [event.target.name]: event.target.files[0],
      });
    } else {
      setUser({
        ...user,
        [event.target.name]: event.target.value,
      });
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">
          {/* page header */}
          <div className="border-bottom pb-3 mb-3 d-lg-flex justify-content-between align-items-center">
            <div className="mb-3 mb-lg-0">
              <h1 className="mb-0 h2 fw-bold">DashBoard</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header">
          <h3 className="mb-0">Manage {manage}</h3>
        </div>
        <div className="card-body">
          <form className="row">
            <div className="mb-3 col-12 col-md-6">
              <label className="form-label" htmlFor="lname">
                Full Name
              </label>
              <input
                type="text"
                name="name"
                className="form-control"
                onChange={handleChange}
                value={user.name}
                placeholder="Enter Name"
                required
              />
            </div>
            <div className="mb-3 col-12 col-md-6">
              <label className="form-label" htmlFor="phone">
                Phone Number
              </label>
              <input
                type="tel"
                name="phone"
                className="form-control"
                onChange={handleChange}
                value={user.phone}
                placeholder="Phone"
                required
                maxLength={10}
              />
            </div>
            <div className="mb-3 col-12 col-md-12">
              <label className="form-label" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                name="email"
                className="form-control"
                onChange={handleChange}
                value={user.email}
                placeholder="Email"
                required
              />
            </div>
            <div className="mb-3 col-12 col-md-12">
              <label className="form-label" htmlFor="address2">
                Address Line
              </label>

              <textarea
                name="address"
                className="form-control"
                id="Address"
                rows={3}
                placeholder="Adresss"
                value={user.address}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Profile Picture</label>
              <br />
              {user.image && (
                <figure className="avatar mb-2 mt-2 w100">
                  <img
                    src={
                      user.image instanceof File
                        ? URL.createObjectURL(user.image)
                        : user.image
                    }
                    alt="Profile"
                    className="shadow-sm rounded-lg w-100"
                  />
                </figure>
              )}
              <br />
              <br />
              <input
                name="image"
                className="form-control"
                type="file"
                id="formFile"
                onChange={handleChange}
              />
            </div>
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={(e) => handleSubmit(e)}
              >
                {mode === "add" ? "Add" : "Edit"} {manage}
              </button>
            </div>
          </form>
        </div>
        {users.length > 0 && (
          <div className="table-responsive p-4">
            <table className="table table-bordered text-nowrap mb-0 table-centered">
              <thead>
                <tr>
                  <th>Profile Picture</th>
                  <th>{manage} ID</th>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Address</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((student) => (
                  <tr key={student.id}>
                    <td className="text-center">
                      {student.image ? (
                        <figure className="avatar mb-2 mt-2 w100">
                          <img
                            src={
                              student.image instanceof File
                                ? URL.createObjectURL(student.image)
                                : student.image
                            }
                            alt="Profile"
                            className="shadow-sm rounded-lg w-100"
                          />
                        </figure>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>{student.id}</td>
                    <td>{student.name}</td>
                    <td>{student.email}</td>
                    <td>{student.phone}</td>
                    <td>{student.address}</td>
                    <td>
                      <div className="dropdown">
                        <Link
                          className="text-body text-primary-hover"
                          role="button"
                          id="dropdownThirtyOne"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i
                            style={{ color: "#009688" }}
                            className="fe fe-more-vertical"
                          />
                        </Link>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownThirtyOne"
                        >
                          <Link
                            className="dropdown-item"
                            onClick={() => editUser(student.id)}
                          >
                            Edit
                          </Link>
                          <Link
                            className="dropdown-item"
                            onClick={() => changeUserStatus(student.id)}
                          >
                            {student.is_active ? " Deactivate" : "Activate"}
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
