import { BASE_URL } from '../../../utils'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'

export default function Admin() {
  const baseUrl = BASE_URL
  const token = localStorage.getItem('token')

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const [analytics, setAnalytics] = useState(null)

  useEffect(() => {
    if (!analytics) {
      getAnalytics()
    }
  })

  const getAnalytics = () => {
    axios
      .get(baseUrl + '/analytics', config)
      .then(response => {
        setAnalytics(response.data)
      })
      .catch(error => {
        const tempdata = error.response.data
        toast.error(`${tempdata.message}`)
      })
  }
  return (
    <div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">
          <div className="border-bottom pb-3 mb-3 d-lg-flex justify-content-between align-items-center">
            <div className="mb-3 mb-lg-0">
              <h1 className="mb-0 h2 fw-bold">Dashboard</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3 col-lg-6 col-md-12 col-12">
          {/* Card */}
          <div className="card mb-4">
            {/* Card body */}
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                <div>
                  <span className="fs-6 text-uppercase fw-semibold ls-md">
                    USERS
                  </span>
                </div>
                <div>
                  <span
                    style={{ color: '#009688' }}
                    className="fe fe-users fs-3 text-primary"
                  />
                </div>
              </div>
              <h2 className="fw-bold mb-1">{analytics?.users}</h2>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12 col-12">
          {/* Card */}
          <div className="card mb-4">
            {/* Card body */}
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                <div>
                  <span className="fs-6 text-uppercase fw-semibold ls-md">
                    Courses
                  </span>
                </div>
                <div>
                  <span
                    style={{ color: '#009688' }}
                    className="fe fe-book-open fs-3 text-primary"
                  />
                </div>
              </div>
              <h2 className="fw-bold mb-1">{analytics?.courses}</h2>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12 col-12">
          {/* Card */}
          <div className="card mb-4">
            {/* Card body */}
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                <div>
                  <span className="fs-6 text-uppercase fw-semibold ls-md">
                    CLASSES
                  </span>
                </div>
                <div>
                  <span
                    style={{ color: '#009688' }}
                    className="fe fe-users fs-3 text-primary"
                  />
                </div>
              </div>
              <h2 className="fw-bold mb-1">{analytics?.classes}</h2>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12 col-12">
          {/* Card */}
          <div className="card mb-4">
            {/* Card body */}
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between mb-3 lh-1">
                <div>
                  <span className="fs-6 text-uppercase fw-semibold ls-md">
                    EXAMS
                  </span>
                </div>
                <div>
                  <span
                    style={{ color: '#009688' }}
                    className="fe fe-user-check fs-3 text-primary"
                  />
                </div>
              </div>
              <h2 className="fw-bold mb-1">{analytics?.exams}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
