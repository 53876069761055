export default function Home() {
  return (
    <main>
      <section className="py-lg-8 py-6">
        <div className="container my-lg-8">
          <div className="row d-flex justify-content-center  align-items-center">
            <div className="text-center col-xxl-5 col-xl-12 col-lg-12 col-12">
              <h1 className=" display-2 fw-bold mb-3">
                Learn today's most in-
                <u className="text-warning">
                  <span className="text-primary">demand-skills</span>
                </u>
              </h1>
              <p className="lead mb-4 ">
                Introducing our cutting-edge Educational Performance Management
                System (EPMS), a comprehensive platform designed to
                revolutionize the way academic programs are managed and
                assessed.
              </p>
              <div className="d-flex justify-content-center">
                <ul
                  style={{ textAlign: "left" }}
                  className="list-unstyled mb-5"
                >
                  <li className="mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={12}
                      height={12}
                      fill="var(--gk-success)"
                      className="bi bi-check-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <span className="ms-2">No credit card required</span>
                  </li>
                  <li className="mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={12}
                      height={12}
                      fill="var(--gk-success)"
                      className="bi bi-check-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <span className="ms-2">Customer service 24/7</span>
                  </li>
                  <li className="mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={12}
                      height={12}
                      fill="var(--gk-success)"
                      className="bi bi-check-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <span className="ms-2">No setup fee</span>
                  </li>
                  <li className="mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={12}
                      height={12}
                      fill="var(--gk-success)"
                      className="bi bi-check-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <span className="ms-2">Cancel any time</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="py-6">
        <div className="container text-center">
          <div className="row">
            <div className="col-xl-12 col-md-12 col-12">
              <div className="text-center mb-6">
                <span className="text-uppercase text-gray-400 ls-md fw-semibold">
                  Trusted by over 12,500 great teams
                </span>
              </div>
            </div>
            <div className="col-xl-10 offset-xl-1">
              <div className="table-responsive-lg">
                <div className="row row-cols-lg-5 row-cols-md-3 row-cols-2 g-4 flex-nowrap">
                  <div className="col">
                    <div className="text-center mb-3">
                      <img
                        src="../../assets/images/brand/gray-logo-airbnb.svg"
                        alt="airbnb"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-center mb-3">
                      <img
                        src="../../assets/images/brand/gray-logo-digitalocean.svg"
                        alt="digitalocean"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-center mb-3">
                      <img
                        src="../../assets/images/brand/gray-logo-discord.svg"
                        alt="discord"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-center mb-3">
                      <img
                        src="../../assets/images/brand/gray-logo-intercom.svg"
                        alt="intercom"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-center mb-3">
                      <img
                        src="../../assets/images/brand/gray-logo-netflix.svg"
                        alt="netflix"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr className="my-0 bg-transparent text-white" />
      <div className="py-4 bg-gray-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-md-12 col-12">
              <div className="row">
                <div className="col-xl-4 col-md-4 col-12">
                  <div className="d-flex mb-4 mb-md-0">
                    <div className="icon icon-shape rounded icon-md bg-gray-300 p-4">
                      <i className="bi bi-play-fill fs-4 text-gray-600" />
                    </div>
                    <div className="ms-3">
                      <p className="mb-0 text-dark fw-medium">
                        Learn in- skills with over 24,000 video courses
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-4 col-12">
                  <div className="d-flex mb-4 mb-md-0">
                    <div className="icon icon-shape rounded icon-md bg-gray-300 p-4">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={14}
                          height={14}
                          fill="currentColor"
                          className="bi bi-star-fill text-gray-600"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                      </span>
                    </div>
                    <div className="ms-3">
                      <p className="mb-0 text-dark fw-medium">
                        Choose courses taught by real-world experts
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-4 col-12">
                  <div className="d-flex">
                    <div className="icon icon-shape rounded icon-lg bg-gray-300 p-4">
                      <i className="bi bi-infinity fs-4 text-gray-600" />
                    </div>
                    <div className="ms-3">
                      <p className="mb-0 text-dark fw-medium">
                        Learn at your own pace, with lifetime access on mobile
                        and desktop
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="pb-lg-8">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-md-12 col-12">
              <div className="bg-primary py-6 px-6 px-xl-0 rounded-4">
                <div className="row align-items-center">
                  <div className="offset-xl-1 col-xl-5 col-md-6 col-12">
                    <div>
                      <h2 className="h1 text-white mb-3">
                        Let's find the right course for you!
                      </h2>
                      <p className="text-white-50 fs-4">
                        …and achieve their learning goals. With our expert
                        tutors, your goals are closer than ever!
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-6 col-12">
                    <div className="text-center d-none d-md-block">
                      <img
                        src="../../assets/images/education/course.png"
                        alt="learning"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
