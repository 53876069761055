import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export default function Reports() {
  let isError = false;
  const [id, setId] = useState(1);
  /*const [activeId, setActiveId] = useState(-1);*/
  const [reports, setReports] = useState([]);
  const [reportName, setReportName] = useState("");
  const [reportType, setReportType] = useState("");
  const [studentId, setStudentId] = useState("");
  const [instructorId, setInstructorId] = useState("");
  const [coordianatorId, setCoordinatorId] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!reportName.length) {
      fieldName.push("Report Name");
    }
    if (!reportType.length) {
      fieldName.push("Report Type");
    }
    if (!studentId.length) {
      fieldName.push("Student ID");
    }
    if (!instructorId.length) {
      fieldName.push("Instructor ID");
    }
    if (!coordianatorId.length) {
      fieldName.push("Coordinator ID");
    }

    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }

    if (isError) {
      return;
    }
    generateReport();
  };
  function convertToCSV(report) {
    const rows = [];
    const headers = Object.keys(report);
    rows.push(headers.map((header) => `"${header}"`).join(",")); // Add headers

    const values = headers.map((header) => report[header]);
    rows.push(values.map((value) => `"${value}"`).join(",")); // Add values

    return rows.join("\n");
  }
  function downloadCSV(csv, fileName) {
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const clearFields = () => {
    setReportName("");
    setReportType("");
    setStudentId("");
    setCoordinatorId("");

    setInstructorId("");
  };

  const generateReport = () => {
    const newReport = {
      id: id,
      name: reportName,
      type: reportType,
      studentId: studentId,
      coordinatorId: coordianatorId,

      instructorId: instructorId,
    };

    setReports((prevReports) => [...prevReports, newReport]);

    clearFields();
    setId(id + 1);
  };
  return (
    <div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">
          {/* page header */}
          <div className="border-bottom pb-3 mb-3 d-lg-flex justify-content-between align-items-center">
            <div className="mb-3 mb-lg-0">
              <h1 className="mb-0 h2 fw-bold">DashBoard</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header">
          <h3 className="mb-0">Reports </h3>
        </div>
        <div className="card-body">
          <form className="row">
            <div className="mb-3 col-12 col-md-6">
              <label className="form-label" htmlFor="fname">
                Report Name
              </label>
              <input
                type="text"
                name="report-name"
                className="form-control"
                onChange={(e) => setReportName(e.target.value)}
                value={reportName}
                placeholder="Report Name"
                required
              />
            </div>
            <div className="mb-3 col-12 col-md-6">
              <label className="form-label" htmlFor="lname">
                Report Type
              </label>
              <input
                type="text"
                name="report-type"
                className="form-control"
                onChange={(e) => setReportType(e.target.value)}
                value={reportType}
                placeholder="Report Type"
                required
              />
            </div>
            <div className="mb-3 col-12 col-md-6">
              <label className="form-label" htmlFor="lname">
                Student ID
              </label>
              <input
                type="text"
                name="student-id"
                className="form-control"
                onChange={(e) => setStudentId(e.target.value)}
                value={studentId}
                placeholder="Student ID"
                required
              />
            </div>
            <div className="mb-3 col-12 col-md-6">
              <label className="form-label" htmlFor="lname">
                Instructor ID
              </label>
              <input
                type="text"
                name="instructor-id"
                className="form-control"
                onChange={(e) => setInstructorId(e.target.value)}
                value={instructorId}
                placeholder="Insructor ID"
                required
              />
            </div>
            <div className="mb-3 col-12 col-md-6">
              <label className="form-label" htmlFor="lname">
                Coordinator ID
              </label>
              <input
                type="text"
                name="coordiantor-id"
                className="form-control"
                onChange={(e) => setCoordinatorId(e.target.value)}
                value={coordianatorId}
                placeholder="Report Type"
                required
              />
            </div>
            <div className="col-12">
              <Link
                type="submit"
                className="btn btn-primary"
                onClick={(e) => handleSubmit(e)}
              >
                Generate Report
              </Link>
            </div>
          </form>
        </div>
        {reports.length > 0 && (
          <div className="table-responsive">
            <table className="table table-bordered text-nowrap mb-0 table-centered">
              <thead>
                <tr>
                  <th> ID</th>
                  <th>Report Name</th>
                  <th>Report Type</th>
                  <th>StudentID</th>
                  <th>InstructorID</th>
                  <th>CoordinatorID</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {reports.map((report) => (
                  <tr key={report.id}>
                    <td>{report.id}</td>
                    <td>{report.name}</td>
                    <td>{report.type}</td>
                    <td>{report.studentId}</td>
                    <td>{report.instructorId}</td>
                    <td>{report.coordinatorId}</td>
                    <td>
                      <div className="dropdown">
                        <Link
                          className="text-body text-primary-hover"
                          role="button"
                          id="dropdownThirtyOne"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i
                            style={{ color: "#009688" }}
                            className="fe fe-more-vertical"
                          />
                        </Link>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownThirtyOne"
                        >
                          <Link
                            className="dropdown-item"
                            onClick={() => {
                              const csvData = convertToCSV(report);
                              downloadCSV(csvData, `report_${report.id}.csv`);
                            }}
                          >
                            Download
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
