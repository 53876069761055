import axios from 'axios'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import validator from 'validator'
import { BASE_URL } from '../../../utils'
export default function ForgotPassword() {
  const [email, setEmail] = useState('')
  let isError = false
  const baseUrl = BASE_URL
  const handleSubmit = e => {
    e.preventDefault()

    if (!email.length) {
      toast.error('Email is Required!')
      isError = true
    }

    if (email.length && !validator.isEmail(email)) {
      toast.error('Email is Invalid!')
      isError = true
    }

    if (isError) {
      return
    }
    sendPasswordResetLink()
  }
  function sendPasswordResetLink() {
    axios
      .post(
        baseUrl + '/forgot-password',
        {
          email: email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(response => {
        toast.success(response.data?.message)
      })
      .catch(function (error) {
        console.log(error)
        toast.error(`${error.response?.data?.message}`)
      })
  }
  return (
    <div>
      <section className="container d-flex flex-column vh-100">
        <div className="row align-items-center justify-content-center g-0 h-lg-100 py-8">
          <div className="col-lg-5 col-md-8 py-8 py-xl-0">
            {/* Card */}
            <div className="card shadow">
              {/* Card body */}
              <div className="card-body p-6">
                <div className="mb-4">
                  <Link to="/">
                    <img
                      src="../assets/images/brand/logo/logobz.png"
                      className="mb-4"
                      alt="logo-icon"
                    />
                  </Link>
                  <h1 className="mb-1 fw-bold">Forgot Password</h1>
                  <p>Fill the form to reset your password.</p>
                </div>
                {/* Form */}
                <form className="needs-validation" noValidate>
                  {/* Email */}
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      onChange={e => setEmail(e.target.value)}
                      value={email}
                      className="form-control"
                      name="email"
                      placeholder="Enter Your Email "
                      required
                    />
                    <div className="invalid-feedback">
                      Please enter valid email.
                    </div>
                  </div>
                  {/* Button */}
                  <div className="mb-3 d-grid">
                    <Link
                      type="submit"
                      className="btn btn-primary"
                      onClick={e => handleSubmit(e)}
                    >
                      Send Resest Link
                    </Link>
                  </div>
                  <span>
                    Return to
                    <Link to="/auth/Login" style={{ color: '#009688' }}>
                      sign in
                    </Link>
                  </span>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
