import { Link } from "react-router-dom";
import { LogIn, UserPlus } from "react-feather"; // Import Feather Icons

export default function AuthHeader() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg shadow-none">
        <div className="container px-0">
          <Link className="navbar-brand" to="/">
            <img src="../../assets/images/brand/logo/logoz.jpg" alt="Geeks" />
            &nbsp;&nbsp;
            <span className="fs-3">
              <b>UTA CANVAS</b>
            </span>
          </Link>
          {}
          <div className="ms-auto d-flex align-items-center order-lg-3"></div>
          <div className="d-flex align-items-center order-lg-3">
            <div className="d-flex align-items-center">
              <nav className="nav nav-footer justify-content-center">
                <Link className="nav-link" to="/">
                  Home
                </Link>
                <span className="my-2 vr opacity-50"></span>
                <Link className="nav-link" to="/About">
                  About
                </Link>
                <span className="my-2 vr opacity-50"></span>
                <Link className="nav-link" to="/Services">
                  Services
                </Link>
                <span className="my-2 vr opacity-50"></span>
                <Link className="nav-link" to="/Contact">
                  Contact
                </Link>
                <span className="my-2 vr opacity-50"></span>
                <Link className="nav-link" to="/Blog">
                  Blog
                </Link>
              </nav>
              <Link
                className="btn btn-outline-primary ms-2 d-none d-lg-block"
                to="/auth/Login"
              >
                Sign in
              </Link>
              <Link
                className="btn btn-primary ms-2 d-none d-lg-block"
                to="/auth/SignUp"
              >
                Sign up
              </Link>
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbar-default"
                aria-controls="navbar-default"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar mt-0" />
                <span className="icon-bar middle-bar" />
                <span className="icon-bar bottom-bar" />
              </button>
              <div className="collapse navbar-collapse" id="navbar-default">
                <ul className="navbar-nav d-lg-none">
                  <li className="nav-item">
                    <Link className="nav-link" to="/auth/Login">
                      <LogIn size={16} /> Sign in
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/auth/SignUp">
                      <UserPlus size={16} /> Sign up
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
