import { Link } from "react-router-dom";

export default function AuthFooter() {
  return (
    <div>
      <footer className="pt-lg-8 pt-5 footer bg-white">
        <div className="container mt-lg-2">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="mb-4">
                <img
                  src="../assets/images/brand/logo/logoz.jpg"
                  alt="UTA CANVAS"
                  className="logo-inverse"
                />
                &nbsp;&nbsp;
                <span className="fs-3">
                  <b>UTA CANVAS</b>
                </span>
                <div className="mt-4">
                  <p>
                    {" "}
                    UTA CANVAS simplifies the process of measuring and assessing
                    program performance by seamlessly mapping Program Objectives
                    to individual courses, tracking student progress, and
                    facilitating real-time exam and assessment creation
                  </p>
                </div>
              </div>
            </div>
            <div className="offset-lg-2 col-lg-2 col-md-3 col-6">
              <div className="mb-4">
                <h3 className="fw-bold mb-3">Links</h3>
                <ul className="list-unstyled nav nav-footer flex-column nav-x-0">
                  <li>
                    <Link to="/About" className="nav-link">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/Blog" className="nav-link">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/Courses" className="nav-link">
                      Courses
                    </Link>
                  </li>
                  <li>
                    <Link to="/Contact" className="nav-link">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-12">
              <div className="mb-4">
                <h3 className="fw-bold mb-3">Get in touch</h3>
                <p> 701 S Nedderman Dr, Arlington, TX 76019, USA</p>
                <p className="mb-1">
                  Email:
                  <Link style={{ color: "#009688" }}>support@mail.com</Link>
                </p>
                <p>
                  Phone:
                  <span className="text-dark fw-semibold">1-987-654-3210</span>
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center g-0 border-top py-2 mt-6">
            <div className="col-lg-4 col-md-5 col-12">
              <span>
                ©<span id="copyright2"></span>
                UTA, Inc. All Rights Reserved
              </span>
            </div>
            <div className="col-12 col-md-7 col-lg-8 d-md-flex justify-content-end">
              <nav className="nav nav-footer">
                <Link className="nav-link ps-0">Privacy Policy</Link>
                <Link className="nav-link px-2 px-md-3">Cookie Notice</Link>
                <Link className="nav-link">Terms of Use</Link>
              </nav>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
