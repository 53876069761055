import axios from 'axios'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import validator from 'validator'
import { BASE_URL } from '../../utils'
export default function Contact() {
  const baseUrl = BASE_URL

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const handleSubmit = e => {
    e.preventDefault()
    let error = false

    let fieldName = []

    if (!name.length) {
      fieldName.push('Name')
    }
    if (!email.length) {
      fieldName.push('Email')
    }
    if (!message.length) {
      fieldName.push('Message')
    }

    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + ' is Required!')
      }
      error = true
    }

    if (email.length && !validator.isEmail(email)) {
      toast.error('Email is Invalid!')
      error = true
    }

    if (!error) {
      axios
        .post(baseUrl + '/support', {
          name,
          email,
          query: message,
        })
        .then(response => {
          toast.success(response.data.message)
          clearFields()
        })
        .catch(error => {
          const tempdata = error.response.data
          toast.error(`${tempdata.message}`)
        })
    }
  }
  function clearFields() {
    setName('')
    setEmail('')
    setMessage('')
  }

  return (
    <main>
      <section className="container-fluid">
        <div className="row align-items-center min-vh-100">
          <div className="offset-xl-1 col-xl-5 col-lg-6 col-md-12 col-12">
            <div className="py-8 me-xl-8 pe-xl-8">
              <div className="d-flex justify-content-between mb-7 align-items-center">
                <Link href="/">
                  <img
                    src="../assets/images/brand/logo/logobz.png"
                    alt="logo"
                    className="logo-inverse"
                  />
                </Link>
                <div className="form-check form-switch theme-switch d-none">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckDefault"
                  />
                </div>
              </div>
              <div>
                <h1 className="display-4 fw-bold">Get In Touch.</h1>
                <p className="lead text-dark">
                  Fill in the form to the right to get in touch with someone on
                  our team, and we will reach out shortly.
                </p>
                <div className="mt-8 fs-4">
                  <p className="mb-4">
                    If you are seeking support please visit our support portal
                    before reaching out directly.
                  </p>
                  <p>
                    <i className="bi bi-telephone text-primary me-2" />
                    1-987-654-3210
                  </p>
                  <p>
                    <i className="bi bi-envelope-open text-primary me-2" />
                    support@mail.com
                  </p>
                  <p className="d-flex">
                    <i className="bi bi-geo-alt text-primary me-2" />
                    701 S Nedderman Dr, Arlington, TX 76019, USA
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-lg-flex align-items-center w-lg-50 min-vh-lg-100 position-fixed-lg bg-cover bg-light top-0 right-0">
            <div className="px-4 px-xl-8 mx-xl-8 py-8 py-lg-0">
              <div>
                <form className="row needs-validation">
                  <div className="mb-3 col-12 col-md-6">
                    <label className="form-label" htmlFor="fname">
                      Name:
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      id="fname"
                      value={name}
                      onChange={e => setName(e.target.value)}
                      placeholder="Name"
                      required
                    />
                  </div>

                  <div className="mb-3 col-12 col-md-6">
                    <label className="form-label" htmlFor="email">
                      Email:
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      id="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      placeholder="Email"
                      required
                    />
                    <div className="invalid-feedback">
                      Please enter an email
                    </div>
                  </div>

                  <div className="mb-3 col-12">
                    <label className="text-dark form-label" htmlFor="messages">
                      Message:<span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      id="messages"
                      rows={3}
                      placeholder="Message"
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      defaultValue={''}
                      required
                    />
                    <div className="invalid-feedback">Please enter Message</div>
                  </div>
                  <div className="col-12">
                    <Link
                      type="submit"
                      className="btn btn-primary"
                      onClick={e => handleSubmit(e)}
                    >
                      Submit
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}
