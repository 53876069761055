import Footer from "./Footer";
import Header from "./Header";
import { Outlet, useNavigate } from "react-router-dom";
export default function Layout()
{
    useNavigate();
    return(
        <div>
            <Header />
            <Outlet/>
            <Footer/>
        </div>
    )
}