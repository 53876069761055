import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import { Line } from "rc-progress";

export default function Student() {
  const navigate = useNavigate();
  const baseUrl = BASE_URL;
  const token = localStorage.getItem("token");

  const cgpa = localStorage.getItem("CGPA");

  const [studentCourses, setStudentCourses] = useState([]);
  const [programs, setPrograms] = useState({});

  useEffect(() => {
    if (!Object.keys(programs).length) {
      getStudentCourses();
      getPrograms();
    }

    if (!cgpa) {
      localStorage.setItem(
        "CGPA",
        (Math.random().toFixed(2) * 4 + 1).toFixed(2)
      );
    }
  });

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getStudentCourses = () => {
    axios
      .get(baseUrl + "/admin/my-courses", config)
      .then((response) => {
        const studCourses = response.data.myCourses;
        if (studCourses && studCourses.courses?.length > 0) {
          for (let i = 0; i < studCourses.courses.length; i++) {
            studCourses.courses[i].completion = Math.round(
              Math.random().toFixed(2) * 100
            );
          }
        }
        setStudentCourses(studCourses);
      })
      .catch((error) => {
        toast.error(`${error}`);
      });
  };

  const getPrograms = () => {
    axios
      .get(baseUrl + "/programs", config)
      .then((response) => {
        if (response?.data?.programs?.length)
          setPrograms(response.data.programs[0]);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 bg-primary p-5 card shadow mb-5 ">
          <h2 className="text-light ml-5">Academic Program Overview</h2>
          <h3 className="text-light mt-4">{programs.name}</h3>
          <p className="text-light">{programs.description}</p>

          <h3 className="text-light mt-4">Curriculum</h3>
          <p className="text-light">{programs.curriculum}</p>

          <h3 className="text-light">Duration</h3>
          <p className="text-light">{programs.duration}</p>

          <h3 className="text-light">CGPA</h3>

          <p className="text-light">{cgpa}</p>
        </div>
        <div className="col-lg-12 col-md-12 col-12">
          <div className="border-bottom pb-3 mb-3 d-lg-flex justify-content-between align-items-center">
            <div className="mb-3 mb-lg-0">
              <h1 className="mb-0 h2 fw-bold">My Courses</h1>
            </div>
          </div>
          <div className="row">
            {studentCourses && Object.keys(studentCourses).length > 0 ? (
              studentCourses.courses?.map(
                (el) =>
                  el.status === "active" && (
                    <div
                      onClick={() =>
                        navigate(`/dashboard/course-details/${el.id}`)
                      }
                      key={el.name}
                      className="cursor-pointer col-lg-3 col-md-6 col-12"
                    >
                      <div className=" card mb-4 card-hover">
                        <div className="card-body">
                          <h3 className="h4 mb-2 text-truncate-line-2">
                            <Link
                              className="text-inherit"
                              to={`/dashboard/course-details/${el.id}`}
                            >
                              {el.name}
                            </Link>
                          </h3>
                          <ul className="mb-3 list-inline">
                            <li className="list-inline-item">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-clock align-baseline"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                </svg>
                              </span>
                              <span>&nbsp; {el.duration}</span>
                            </li>
                          </ul>
                          <div className="lh-1">
                            <span className="align-text-top">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </span>
                            &nbsp;
                            <span className="text-warning">
                              {Math.ceil(el.rating)}
                            </span>
                          </div>
                          <Line
                            percent={el.completion}
                            strokeWidth={2}
                            strokeColor="green"
                          />
                          <span>{el.completion}% completed</span>
                        </div>
                      </div>
                    </div>
                  )
              )
            ) : (
              <h4 className="mt-3 p-5 ml-5 font-lg text-grey-500">
                No Courses Available
              </h4>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
