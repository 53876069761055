import ApexCharts from "react-apexcharts";
export default function Analytics() {
  const supports = setSupportOptions();
  const sessionOptions = setSessionOptions();
  const trafficOptions = setTrafficOptions();
  const operatingOptions = setOperatingOptions();

  const supportData = [
    {
      data: [
        25, 66, 41, 70, 63, 25, 44, 22, 36, 19, 54, 44, 32, 36, 29, 54, 25, 66,
        41, 65, 63, 25, 44, 12, 36, 39, 25, 44, 42, 36, 54,
      ],
    },
  ];
  const sessionData = [
    {
      name: "Session Duration",
      data: [600, 1e3, 400, 2e3, 500, 900, 2500, 1800, 3800],
      colors: [window.theme.primary],
    },
    {
      name: "Page Views",
      data: [1e3, 2e3, 800, 1200, 300, 1900, 1600, 2e3, 1e3],
    },
    {
      name: "Total Visits",
      data: [2200, 1e3, 3400, 900, 500, 2500, 3e3, 1e3, 2500],
    },
  ];
  function setSupportOptions() {
    return {
      chart: { type: "bar", height: 302, sparkline: { enabled: !0 } },
      states: {
        normal: { filter: { type: "none", value: 0 } },
        hover: { filter: { type: "darken", value: 0.55 } },
        active: {
          allowMultipleDataPointsSelection: !1,
          filter: { type: "darken", value: 0.55 },
        },
      },
      colors: [window.theme.primary],
      plotOptions: { bar: { borderRadius: 4, columnWidth: "50%" } },
      series: [
        {
          data: [
            25, 66, 41, 70, 63, 25, 44, 22, 36, 19, 54, 44, 32, 36, 29, 54, 25,
            66, 41, 65, 63, 25, 44, 12, 36, 39, 25, 44, 42, 36, 54,
          ],
        },
      ],
      xaxis: { crosshairs: { width: 1 } },
      tooltip: {
        fixed: { enabled: !1 },
        x: { show: !1 },
        y: {
          title: {
            formatter: function (e) {
              return "Active User";
            },
          },
        },
        marker: { show: !1 },
      },
    };
  }
  function setSessionOptions() {
    return {
      chart: {
        toolbar: { show: !1 },
        height: 300,
        type: "line",
        zoom: { enabled: !1 },
      },
      dataLabels: { enabled: !1 },
      stroke: { width: [4, 3, 3], curve: "smooth", dashArray: [0, 5, 4] },
      legend: { show: !1 },
      colors: [
        window.theme.primary,
        window.theme.success,
        window.theme.warning,
      ],
      markers: { size: 0, hover: { sizeOffset: 6 } },
      xaxis: {
        categories: [
          "01 Jan",
          "02 Jan",
          "03 Jan",
          "04 Jan",
          "05 Jan",
          "06 Jan",
          "07 Jan",
          "08 Jan",
          "09 Jan",
          "10 Jan",
          "11 Jan",
          "12 Jan",
        ],
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "Inter",
            cssClass: "apexcharts-xaxis-label",
            colors: window.theme.gray500,
          },
        },
        axisBorder: {
          show: !0,
          color: window.theme.borderColor,
          height: 0,
          width: "100%",
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: !0,
          borderType: "solid",
          color: window.theme.borderColor,
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "Inter",
            cssClass: "apexcharts-xaxis-label",
          },
          offsetX: -12,
          offsetY: 0,
        },
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (e) {
                return e + " (mins)";
              },
            },
          },
          {
            title: {
              formatter: function (e) {
                return e + " per session";
              },
            },
          },
          {
            title: {
              formatter: function (e) {
                return e;
              },
            },
          },
        ],
      },
      grid: { borderColor: window.theme.borderColor },
    };
  }
  function setTrafficOptions() {
    return {
      series: [60, 55, 12, 17],
      labels: ["Organic Search", "Direct", "Refferrals", "Social Media"],
      colors: [
        window.theme.primary,
        window.theme.success,
        window.theme.danger,
        window.theme.warning,
      ],
      chart: { type: "donut", height: 260 },
      legend: { show: !1 },
      dataLabels: { enabled: !1 },
      plotOptions: { pie: { donut: { size: "50%" } } },
      stroke: { width: 2, colors: [window.theme.transparent] },
    };
  }
  function setOperatingOptions() {
    return {
      series: [55, 88, 80, 75],
      labels: ["Window", "macOS", "Linux", "Android"],
      chart: { type: "polarArea", height: 350 },
      colors: [
        window.theme.danger,
        window.theme.success,
        window.theme.primary,
        window.theme.info,
      ],
      legend: { show: !1 },
      stroke: { colors: [window.theme.borderColor] },
      fill: { opacity: 0.9 },
      plotOptions: {
        polarArea: {
          rings: {
            strokeWidth: 1,
            strokeColor: [window.theme.borderColor],
          },
          spokes: {
            strokeWidth: 1,
            connectorColors: [
              window.theme.borderColor,
              window.theme.borderColor,
              window.theme.borderColor,
              window.theme.borderColor,
            ],
          },
        },
      },
    };
  }
  const setOperatingData = [55, 88, 80, 75];
  const trafficData = [60, 55, 12, 17];
  return (
    <div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">
          {/* page header */}
          <div className="border-bottom pb-3 mb-3 d-lg-flex justify-content-between align-items-center">
            <div className="mb-3 mb-lg-0">
              <h1 className="mb-0 h2 fw-bold">Analytics</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3 col-lg-6 col-md-12 col-12 mb-4">
          {/* card */}
          <div className="card h-100">
            {/* card body */}
            <div className="card-body">
              <div className="row">
                {/* col */}
                <div className="col-12">
                  <span className="fw-semibold text-uppercase fs-6 ls-md">
                    User
                  </span>
                </div>
                {/* col */}
                <div className="col-6">
                  <h1 className="fw-bold mt-2 mb-0 h2">30.6k</h1>
                  <p className="text-success fw-semibold mb-0">
                    <i
                      style={{ color: "#009688" }}
                      className="fe fe-trending-up me-1"
                    />
                    +20.9$
                  </p>
                </div>
                {/* chart */}
                <div className="col-6 d-flex align-items-center">
                  <div id="userChart" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12 col-12 mb-4">
          {/* card */}
          <div className="card h-100">
            {/* card body */}
            <div className="card-body">
              <div className="row">
                {/* col */}
                <div className="col-12">
                  <span className="fw-semibold text-uppercase fs-6 ls-md">
                    Unique Visitors
                  </span>
                </div>
                {/* col */}
                <div className="col-6">
                  <h1 className="fw-bold mt-2 mb-0 h2">256k</h1>
                  <p className="text-danger fw-semibold mb-0">
                    <i
                      style={{ color: "#009688" }}
                      className="fe fe-trending-down me-1"
                    />
                    5%
                  </p>
                </div>
                {/* chart */}
                <div className="col-6 d-flex align-items-center">
                  <div id="visitorChart" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12 col-12 mb-4">
          {/* card */}
          <div className="card h-100">
            {/* card body */}
            <div className="card-body">
              <div className="row">
                {/* col */}
                <div className="col-12">
                  <span className="fw-semibold text-uppercase fs-6 ls-md">
                    Bounce Rate
                  </span>
                </div>
                {/* col */}
                <div className="col-6">
                  <h1 className="fw-bold mt-2 mb-0 h2">51.46%</h1>
                  <p className="text-success fw-semibold mb-0">
                    <i
                      style={{ color: "#009688" }}
                      className="fe fe-trending-up me-1"
                    />
                    +1200
                  </p>
                </div>
                {/* chart */}
                <div className="col-6 d-flex align-items-center">
                  <div id="bounceChart" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12 col-12 mb-4">
          {/* card */}
          <div className="card h-100">
            {/* card body */}
            <div className="card-body">
              <div className="row">
                {/* col */}
                <div className="col-12">
                  <span className="fw-semibold text-uppercase fs-6 ls-md">
                    Average visit time
                  </span>
                </div>
                {/* col */}
                <div className="col-6">
                  <h1 className="fw-bold mt-2 mb-0 h2">1m:17s</h1>
                  <p className="text-success fw-semibold mb-0">
                    <i
                      style={{ color: "#009688" }}
                      className="fe fe-trending-up me-1"
                    />
                    12%
                  </p>
                </div>
                {/* chart */}
                <div className="col-6 d-flex align-items-center">
                  <div id="userChartExample" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-8 col-lg-12 col-md-12 col-12 mb-4">
          {/* card */}
          <div className="card h-100">
            <div className="card-header">
              <h4 className="mb-0">Sessions</h4>
            </div>
            {/* card body */}
            <div className="card-body pb-0">
              {/* chart */}
              <ApexCharts
                options={sessionOptions}
                series={sessionData}
                height={"150%"}
                width={"100%"}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-12 col-md-12 col-12 mb-4">
          {/* card */}
          <div className="card h-100">
            {/* card header */}
            <div className="card-header">
              <h4 className="mb-0">Active User</h4>
            </div>
            {/* card body */}
            <div className="card-body">
              <div className="row">
                {/* col */}
                <div className="col-4">
                  <span className="fw-semibold">30 days</span>
                  <h1 className="fw-bold mt-2 mb-0 h2">12,698</h1>
                  <p className="text-success fw-semibold mb-0">
                    <i
                      style={{ color: "#009688" }}
                      className="fe fe-trending-up me-1"
                    />
                    4.6%
                  </p>
                </div>
                {/* col */}
                <div className="col-4">
                  <span className="fw-semibold">7 days</span>
                  <h1 className="fw-bold mt-2 mb-0 h2">2.65K</h1>
                  <p className="text-danger fw-semibold mb-0">
                    <i
                      style={{ color: "#009688" }}
                      className="fe fe-trending-down me-1"
                    />
                    4.6%
                  </p>
                </div>
                {/* col */}
                <div className="col-4">
                  <span className="fw-semibold">1 days</span>
                  <h1 className="fw-bold mt-2 mb-0 h2">1.34K</h1>
                  <p className="text-success fw-semibold mb-0">
                    <i
                      style={{ color: "#009688" }}
                      className="fe fe-trending-up me-1"
                    />
                    4.6%
                  </p>
                </div>
              </div>
              {/* chart */}
              <ApexCharts
                options={supports}
                series={supportData}
                height={"100%"}
                width={"100%"}
              />
              <div className="fs-6 d-flex justify-content-between mt-2 ps-2">
                <div className="chart-label">01 Jan, 2020</div>
                <div className="chart-label">30 Jan, 2020</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12 col-xl-4 mb-4">
          {/* card */}
          <div className="card h-100">
            {/* card header */}
            <div className="card-header">
              <h4 className="mb-0">Traffic Channel</h4>
            </div>
            {/* card body */}
            <div className="card-body">
              {/* chart */}
              <ApexCharts
                options={trafficOptions}
                series={trafficData}
                type="donut"
                height={"100%"}
                width={"100%"}
              />
              {/* table */}
              <table className="table table-sm table-borderless mb-0 mt-5">
                <tbody>
                  <tr>
                    <td>
                      <span className="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={10}
                          height={10}
                          fill="currentColor"
                          className="bi bi-square-fill text-primary"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"></path>
                        </svg>
                      </span>
                      <span>Organic Search</span>
                    </td>
                    <td>2,120</td>
                    <td>4.54%</td>
                  </tr>
                  <tr>
                    <td>
                      <span className="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={10}
                          height={10}
                          fill="currentColor"
                          className="bi bi-square-fill text-success"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"></path>
                        </svg>
                      </span>
                      <span>Direct</span>
                    </td>
                    <td>639</td>
                    <td>4.37%</td>
                  </tr>
                  <tr>
                    <td>
                      <span className="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={10}
                          height={10}
                          fill="currentColor"
                          className="bi bi-square-fill text-danger"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"></path>
                        </svg>
                      </span>
                      <span>Refferrals</span>
                    </td>
                    <td>520</td>
                    <td>45.14%</td>
                  </tr>
                  <tr>
                    <td>
                      <span className="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={10}
                          height={10}
                          fill="currentColor"
                          className="bi bi-square-fill text-info"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"></path>
                        </svg>
                      </span>
                      <span>Social Media</span>
                    </td>
                    <td>116</td>
                    <td>12.24%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-12 col-xl-4 mb-4">
          {/* card */}
          <div className="card h-100">
            {/* card header */}
            <div className="card-header">
              <h4 className="mb-0">Operating System</h4>
            </div>
            {/* card body */}
            <div className="card-body">
              <ApexCharts
                options={operatingOptions}
                series={setOperatingData}
                type="polarArea"
                height={"80%"}
                width={"80%"}
              />
              <div className="mt-4 d-flex justify-content-center">
                {/* list */}
                <ul className="list-inline mb-0">
                  <li className="list-inline-item mx-3">
                    <h5 className="mb-0 d-flex align-items-center lh-1">
                      <span className="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={10}
                          height={10}
                          fill="currentColor"
                          className="bi bi-square-fill text-danger"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"></path>
                        </svg>
                      </span>
                      <span>Window</span>
                    </h5>
                  </li>
                  <li className="list-inline-item mx-3">
                    <h5 className="mb-0 d-flex align-items-center lh-1">
                      <span className="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={10}
                          height={10}
                          fill="currentColor"
                          className="bi bi-square-fill text-success"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"></path>
                        </svg>
                      </span>
                      <span>macOS</span>
                    </h5>
                  </li>
                  <li className="list-inline-item mx-3">
                    <h5 className="mb-0 d-flex align-items-center lh-1">
                      <span className="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={10}
                          height={10}
                          fill="currentColor"
                          className="bi bi-square-fill text-primary"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"></path>
                        </svg>
                      </span>
                      <span>Linux</span>
                    </h5>
                  </li>
                  <li className="list-inline-item ms-3">
                    <h5 className="mb-0 d-flex align-items-center fs-5 lh-1">
                      <span className="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={10}
                          height={10}
                          fill="currentColor"
                          className="bi bi-square-fill text-info"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"></path>
                        </svg>
                      </span>
                      <span>Android</span>
                    </h5>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-12 col-xl-4 mb-4">
          {/* card */}
          <div className="card h-100">
            {/* card header */}
            <div className="card-header">
              <h4 className="mb-0">Browsers</h4>
            </div>
            {/* table */}
            <table className="table mb-0 table-hover table-centered">
              <tbody>
                <tr>
                  <td>
                    <img
                      src="../../assets/images/browser-svg/chrome.svg"
                      alt="chrome"
                      className="me-2"
                    />
                    <span className="align-middle">Google Chrome</span>
                  </td>
                  <td>21.54%</td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="../../assets/images/browser-svg/firefox.svg"
                      alt="firefox"
                      className="me-2"
                    />
                    <span className="align-middle">Mozilla Firefox</span>
                  </td>
                  <td>14.43%</td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="../../assets/images/browser-svg/brave.svg"
                      alt="brave"
                      className="me-2"
                    />
                    <span className="align-middle">Brave</span>
                  </td>
                  <td>14.43%</td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="../../assets/images/browser-svg/safari.svg"
                      alt="safari"
                      className="me-2"
                    />
                    <span className="align-middle">Apple Safari</span>
                  </td>
                  <td>8.54%</td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="../../assets/images/browser-svg/ie.svg"
                      alt="ie"
                      className="me-2"
                    />
                    <span className="align-middle">Internet Export</span>
                  </td>
                  <td>6.21%</td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="../../assets/images/browser-svg/opera.svg"
                      alt="opera"
                      className="me-2"
                    />
                    <span className="align-middle">Opera Mini</span>
                  </td>
                  <td>8%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row"></div>
      </div>
    </div>
  );
}
