import { Link } from "react-router-dom";
import { BASE_URL } from "../../../utils";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { setMessageCount } from "../../../redux/slices/app";
import { useDispatch } from "react-redux";

export default function Chat() {
  const dispatch = useDispatch();

  const baseUrl = BASE_URL;
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const [chatsLoaded, setChatsLoaded] = useState(false);
  const [allChats, setAllChats] = useState([]);
  const [chats, setChats] = useState([]);
  const [conversation, setConversation] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [chatHeader, setChatHeader] = useState(null);
  const [message, setMessage] = useState("");
  const ref = useRef(null);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    if (!chatsLoaded) {
      fetchChats();
    }
    if (activeChat) {
      if (ref.current) scrollToBottom();
    }
  });

  const fetchUsers = () => {
    const groupedChats = [];
    axios
      .get(baseUrl + "/users", config)
      .then((response) => {
        const usersList = response.data.users;
        for (const userItem of usersList) {
          if (userItem.id !== user.id) {
            groupedChats.push({
              id: userItem.id,
              user: userItem,
              message: "",
              unread: 0,
            });
          }
        }
        setChats(groupedChats);
      })
      .catch(function (error) {
        const tempdata = error?.response?.data;
        if (tempdata) toast.error(`${tempdata.message}`);
      });
  };

  const fetchChats = () => {
    axios
      .get(baseUrl + "/chats", config)
      .then((response) => {
        if (!response.data.chats.length) {
          fetchUsers();
        } else {
          setAllChats(response.data.chats);
          groupChats(response.data.chats);
        }
      })
      .catch(function (error) {
        const tempdata = error?.response?.data;
        if (tempdata) toast.error(`${tempdata.message}`);
      });
    setChatsLoaded(true);
  };

  const groupChats = (chats) => {
    const groupedChats = [];
    for (const chat of chats) {
      const senderId = chat.sender_id;
      const receiverId = chat.receiver_id;

      if (senderId === user.id) {
        handleSenderChat(groupedChats, receiverId, chat);
      }

      if (receiverId === user.id) {
        handleReceiverChat(groupedChats, senderId, chat);
      }
    }
    setChats(groupedChats);
  };

  const handleSenderChat = (groupedChats, receiverId, chat) => {
    const chatExist = groupedChats.some(
      (groupedChat) => groupedChat.id === receiverId
    );
    if (!chatExist) {
      groupedChats.push({
        id: receiverId,
        user: chat.receiver,
        message: chat.message,
        unread: 0,
      });
    }
  };

  const handleReceiverChat = (groupedChats, senderId, chat) => {
    const chatExist = groupedChats.some(
      (groupedChat) => groupedChat.id === senderId
    );
    if (!chatExist) {
      groupedChats.push({
        id: senderId,
        user: chat.sender,
        message: chat.message,
        unread: chat.status === "unread" ? 1 : 0,
      });
    } else {
      groupedChats.find((groupedChat) => groupedChat.id === senderId).unread +=
        chat.status === "unread" ? 1 : 0;
    }
  };

  const selectChat = (id) => {
    let clone = [...chats];
    clone.find((chat) => chat.id === id).unread = 0;
    setChats(clone);

    setActiveChat(id);
    setChatHeader(chats.find((chat) => chat.id === id).user);
    fetchConversation(id);
  };

  const fetchConversation = (id) => {
    config.params = {
      receiver_id: id,
    };

    axios
      .get(baseUrl + "/conversation", config)
      .then((response) => {
        setConversation(response.data.conversation);
      })
      .catch(function (error) {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const sendMessage = () => {
    const formData = new FormData();
    formData.append("sender_id", user.id);
    formData.append("receiver_id", activeChat);
    formData.append("message", message);

    axios
      .post(baseUrl + "/chat", formData, config)
      .then((response) => {
        setMessage("");
        fetchConversation(activeChat);
        fetchChats();
      })
      .catch(function (error) {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const scrollToBottom = () => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  };

  useEffect(() => {
    // get sum of unread messages using reduce
    const sum = chats.reduce((a, b) => a + b.unread, 0);
    dispatch(setMessageCount(sum));
  }, [chats, dispatch]);

  return (
    <div>
      <div className="chat-layout" id="db-wrapper">
        <section className="container-fluid px-0">
          {/* row */}
          <div className="row g-0">
            <div className="col-xl-3 col-lg-12 col-md-12 col-12">
              <div className="bg-white border-end border-top vh-100">
                {/* chat list */}
                <div className="chat-window">
                  <div className="chat-sticky-header sticky-top bg-white">
                    <div className="px-4 pt-3 pb-4">
                      {/* heading */}
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <h1 className="mb-0 fw-bold h2">Chat</h1>
                        </div>
                      </div>
                      {/* search */}
                      <div className="mt-4">
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search people, group and messages"
                          onChange={(e) => {
                            if (e.target.value === "") {
                              groupChats(allChats);
                            } else if (
                              chats.length > 0 &&
                              e.target.value.length > 1
                            ) {
                              setChats(
                                chats.filter(
                                  (chat) =>
                                    chat.id !== user.id &&
                                    chat.user.name
                                      .toLowerCase()
                                      .startsWith(e.target.value.toLowerCase())
                                )
                              );
                            } else {
                              fetchUsers();
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <ul className="list-unstyled contacts-list">
                    {chats.map((chat) => (
                      <li
                        onClick={() => selectChat(chat.id)}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        className="py-3 px-4 chat-item contacts-item"
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div className=" text-link">
                            <div className="d-flex w-100">
                              <div className="avatar avatar-md avatar-indicators avatar-online">
                                {chat.user.image ? (
                                  <img
                                    src={chat.user.image}
                                    alt=""
                                    className="rounded-circle"
                                  />
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="45"
                                    height="45"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="currentColor"
                                      fill-rule="evenodd"
                                      d="M12 4a8 8 0 0 0-6.96 11.947A4.99 4.99 0 0 1 9 14h6a4.99 4.99 0 0 1 3.96 1.947A8 8 0 0 0 12 4Zm7.943 14.076A9.959 9.959 0 0 0 22 12c0-5.523-4.477-10-10-10S2 6.477 2 12a9.958 9.958 0 0 0 2.057 6.076l-.005.018l.355.413A9.98 9.98 0 0 0 12 22a9.947 9.947 0 0 0 5.675-1.765a10.055 10.055 0 0 0 1.918-1.728l.355-.413l-.005-.018ZM12 6a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                )}
                              </div>
                              {/* media body */}
                              <div className="ms-2 ">
                                <h5 className="mb-0 fw-bold">
                                  {chat.user.name}
                                </h5>
                                <p className="mb-0 w-50 text-truncate">
                                  {chat.message}
                                </p>
                              </div>
                            </div>
                          </div>

                          {chat.unread ? (
                            <div
                              className="text-end position-absolute"
                              style={{ right: 10 }}
                            >
                              <span className="icon-shape icon-xs text-white bg-danger rounded-circle fw-bold fs-6">
                                {chat.unread}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-12 col-md-12 col-12">
              {/* chat list */}

              {activeChat ? (
                <div className="chat-body w-100 vh-100 " data-simplebar>
                  <div className="bg-white border-top border-bottom px-4 py-3 sticky-top">
                    <div className="d-flex justify-content-between align-items-center">
                      {/* media */}
                      <div className="d-flex align-items-center">
                        <Link className="me-2 d-xl-none d-block" data-close>
                          <i
                            style={{ color: "#009688" }}
                            className="fe fe-arrow-left"
                          />
                        </Link>
                        <div className="avatar avatar-md avatar-indicators avatar-online">
                          <img
                            src={chatHeader.image}
                            alt=""
                            className="rounded-circle"
                          />
                        </div>
                        {/* media body */}
                        <div className="ms-2">
                          <h4 className="mb-0">{chatHeader.name}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-4 vh-100 overflow-hidden">
                    {conversation?.map((conv) => (
                      <>
                        {conv.sender_id === user.id ? (
                          <div className="d-flex justify-content-end mb-4">
                            {/* media */}
                            <div className="d-flex">
                              {/* media body */}
                              <div className="me-3 text-end">
                                <small>
                                  {new Date(
                                    conv.created_at
                                  ).toLocaleTimeString()}
                                </small>
                                <div className="d-flex">
                                  <div className="card mt-2 rounded-top-md-end-0 bg-primary text-white">
                                    <div className="card-body text-start p-3">
                                      <p className="mb-0">{conv.message}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* img */}
                              <img
                                src={conv.sender.image}
                                alt=""
                                className="rounded-circle avatar-md"
                              />
                            </div>
                          </div>
                        ) : (
                          <div className={"d-flex w-lg-40 mb-4"}>
                            <img
                              src={conv.sender.image}
                              alt=""
                              className="rounded-circle avatar-md"
                            />
                            {/* media body */}
                            <div className="ms-3">
                              <small>
                                {conv.sender.name} ,{" "}
                                {new Date(conv.created_at).toLocaleTimeString()}
                              </small>
                              <div className="d-flex">
                                <div className="card mt-2 rounded-top-md-left-0">
                                  <div className="card-body p-3">
                                    <p className="mb-0 text-dark">
                                      {conv.message}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                  </div>

                  <div className="bg-light px-4 py-3 chat-footer mt-auto">
                    <div className="bg-white p-2 rounded-3 shadow-sm">
                      <form>
                        <div className="position-relative">
                          <input
                            className="form-control border-0 form-control-simple no-resize"
                            placeholder="Type a New Message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                sendMessage();
                              }
                            }}
                          />
                        </div>
                        <div className="position-absolute end-0 mt-n7 me-4">
                          <button
                            onClick={sendMessage}
                            type="submit"
                            className="fs-3 btn text-primary btn-focus-none"
                          >
                            <i
                              style={{ color: "#009688" }}
                              className="fe fe-send"
                            />
                          </button>
                        </div>
                      </form>
                    </div>
                    &nbsp; &nbsp; &nbsp;
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
