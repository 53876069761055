export default function Services() {
  return (
    <main>
      <section className="py-8 bg-white">
        <div className="container my-lg-4">
          <div className="row">
            <div className="offset-lg-2 col-lg-8 col-md-12 col-12 mb-8">
              <h1 className="display-2 fw-bold mb-3">Our Services</h1>
              <p className="h2 mb-3">
                <ul>
                  <li>Streamlined Performance Measurement</li>
                  <li>Data-Driven Insights</li>
                  <li>Comprehensive Program Overview</li>
                  <li>Efficient Communication</li>
                  <li>Secure user roles</li>
                  <li>Accessiblity and Responsiveness</li>
                  <li>Continous Improvement</li>
                  <li>Comprehensive Program Overview</li>
                  <li>Efficient Communication</li>
                  <li>Secure user roles</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
