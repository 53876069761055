import { Link } from "react-router-dom";

export default function Courses() {
  return (
    <main>
      <section className="py-4 py-lg-6 bg-primary">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div>
                <h1 className="text-white mb-1 display-4">Courses</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-7">
        <div className="container">
          <div className="row mb-6">
            <div className="col-md-12">
              <div className="tab-content" id="tabContent">
                <div
                  className="tab-pane fade show active"
                  id="most-popular"
                  role="tabpanel"
                  aria-labelledby="most-popular-tab"
                >
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-12">
                      <div className="card mb-4 card-hover">
                        <Link>
                          <img
                            src="../assets/images/course/course-javascript.jpg"
                            alt="course"
                            className="card-img-top"
                          />
                        </Link>

                        <div className="card-body">
                          <h3 className="h4 mb-2 text-truncate-line-2">
                            <Link className="text-inherit">
                              Creating a Custom Event in Javascript
                            </Link>
                          </h3>
                          <ul className="mb-3 list-inline">
                            <li className="list-inline-item">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-clock align-baseline"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                </svg>
                              </span>
                              <span>3h 56m</span>
                            </li>
                            <li className="list-inline-item">
                              <svg
                                className="me-1 mt-n1"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="3"
                                  y="8"
                                  width="2"
                                  height="6"
                                  rx="1"
                                  fill="#009688"
                                ></rect>
                                <rect
                                  x="7"
                                  y="5"
                                  width="2"
                                  height="9"
                                  rx="1"
                                  fill="#DBD8E9"
                                ></rect>
                                <rect
                                  x="11"
                                  y="2"
                                  width="2"
                                  height="12"
                                  rx="1"
                                  fill="#DBD8E9"
                                ></rect>
                              </svg>
                              Beginner
                            </li>
                          </ul>
                          <div className="lh-1">
                            <span className="align-text-top">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </span>
                            <span className="text-warning">4.5</span>
                            <span className="fs-6">(9,300)</span>
                          </div>
                        </div>

                        <div className="card-footer">
                          <div className="row align-items-center g-0">
                            <div className="col-auto">
                              <img
                                src="../assets/images/avatar/avatar-3.jpg"
                                className="rounded-circle avatar-xs"
                                alt="avatar"
                              />
                            </div>
                            <div className="col ms-2">
                              <span>Morris Mccoy</span>
                            </div>
                            <div className="col-auto">
                              <Link className="text-reset bookmark">
                                <i className="fe fe-bookmark fs-4"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-12">
                      <div className="card mb-4 card-hover">
                        <Link>
                          <img
                            src="../assets/images/course/course-javascript.jpg"
                            alt="course"
                            className="card-img-top"
                          />
                        </Link>

                        <div className="card-body">
                          <h3 className="h4 mb-2 text-truncate-line-2">
                            <Link className="text-inherit">
                              Morden JavaScript Beginner Tutorial - Simple
                            </Link>
                          </h3>
                          <ul className="mb-3 list-inline">
                            <li className="list-inline-item">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-clock align-baseline"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                </svg>
                              </span>
                              <span>2h 46m</span>
                            </li>
                            <li className="list-inline-item">
                              <svg
                                className="me-1 mt-n1"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="3"
                                  y="8"
                                  width="2"
                                  height="6"
                                  rx="1"
                                  fill="#009688"
                                ></rect>
                                <rect
                                  x="7"
                                  y="5"
                                  width="2"
                                  height="9"
                                  rx="1"
                                  fill="#009688"
                                ></rect>
                                <rect
                                  x="11"
                                  y="2"
                                  width="2"
                                  height="12"
                                  rx="1"
                                  fill="#009688"
                                ></rect>
                              </svg>
                              Advance
                            </li>
                          </ul>
                          <div className="lh-1">
                            <span className="align-text-top">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </span>
                            <span className="text-warning">4.5</span>
                            <span className="fs-6">(7,800)</span>
                          </div>
                        </div>

                        <div className="card-footer">
                          <div className="row align-items-center g-0">
                            <div className="col-auto">
                              <img
                                src="../assets/images/avatar/avatar-2.jpg"
                                className="rounded-circle avatar-xs"
                                alt="avatar"
                              />
                            </div>
                            <div className="col ms-2">
                              <span>Ted Hawkins</span>
                            </div>
                            <div className="col-auto">
                              <Link className="text-reset bookmark">
                                <i className="fe fe-bookmark fs-4"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                      <div className="card mb-4 card-hover">
                        <Link>
                          <img
                            src="../assets/images/course/course-javascript.jpg"
                            alt="course"
                            className="card-img-top"
                          />
                        </Link>

                        <div className="card-body">
                          <h3 className="h4 mb-2 text-truncate-line-2">
                            <Link className="text-inherit">
                              Applying JavaScript and using the console.
                            </Link>
                          </h3>

                          <ul className="mb-3 list-inline">
                            <li className="list-inline-item">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-clock align-baseline"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                </svg>
                              </span>
                              <span>1h 30m</span>
                            </li>
                            <li className="list-inline-item">
                              <svg
                                className="me-1 mt-n1"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="3"
                                  y="8"
                                  width="2"
                                  height="6"
                                  rx="1"
                                  fill="#009688"
                                ></rect>
                                <rect
                                  x="7"
                                  y="5"
                                  width="2"
                                  height="9"
                                  rx="1"
                                  fill="#DBD8E9"
                                ></rect>
                                <rect
                                  x="11"
                                  y="2"
                                  width="2"
                                  height="12"
                                  rx="1"
                                  fill="#DBD8E9"
                                ></rect>
                              </svg>
                              Beginner
                            </li>
                          </ul>
                          <div className="lh-1">
                            <span className="align-text-top">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </span>
                            <span className="text-warning">4.5</span>
                            <span className="fs-6">(8,245)</span>
                          </div>
                        </div>

                        <div className="card-footer">
                          <div className="row align-items-center g-0">
                            <div className="col-auto">
                              <img
                                src="../assets/images/avatar/avatar-4.jpg"
                                className="rounded-circle avatar-xs"
                                alt="avatar"
                              />
                            </div>
                            <div className="col ms-2">
                              <span>Juanita Bell</span>
                            </div>
                            <div className="col-auto">
                              <Link className="text-reset bookmark">
                                <i className="fe fe-bookmark fs-4"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                      <div className="card mb-4 card-hover">
                        <Link>
                          <img
                            src="../assets/images/course/course-javascript.jpg"
                            alt="course"
                            className="card-img-top"
                          />
                        </Link>

                        <div className="card-body">
                          <h3 className="h4 mb-2 text-truncate-line-2">
                            <Link className="text-inherit">
                              Creating functions, using them, passing data in
                              and out.
                            </Link>
                          </h3>

                          <ul className="mb-3 list-inline">
                            <li className="list-inline-item">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-clock align-baseline"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                </svg>
                              </span>
                              <span>2h 30m</span>
                            </li>
                            <li className="list-inline-item">
                              <svg
                                className="me-1 mt-n1"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="3"
                                  y="8"
                                  width="2"
                                  height="6"
                                  rx="1"
                                  fill="#009688"
                                ></rect>
                                <rect
                                  x="7"
                                  y="5"
                                  width="2"
                                  height="9"
                                  rx="1"
                                  fill="#009688"
                                ></rect>
                                <rect
                                  x="11"
                                  y="2"
                                  width="2"
                                  height="12"
                                  rx="1"
                                  fill="#DBD8E9"
                                ></rect>
                              </svg>
                              Intermediate
                            </li>
                          </ul>
                          <div className="lh-1">
                            <span className="align-text-top">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </span>
                            <span className="text-warning">4.5</span>
                            <span className="fs-6">(3,245)</span>
                          </div>
                        </div>

                        <div className="card-footer">
                          <div className="row align-items-center g-0">
                            <div className="col-auto">
                              <img
                                src="../assets/images/avatar/avatar-5.jpg"
                                className="rounded-circle avatar-xs"
                                alt="avatar"
                              />
                            </div>
                            <div className="col ms-2">
                              <span>Claire Robertson</span>
                            </div>
                            <div className="col-auto">
                              <Link className="text-reset bookmark">
                                <i className="fe fe-bookmark fs-4"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="trending"
                  role="tabpanel"
                  aria-labelledby="trending-tab"
                >
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-12">
                      <div className="card mb-4 card-hover">
                        <Link>
                          <img
                            src="../assets/images/course/course-react.jpg"
                            alt="course"
                            className="card-img-top"
                          />
                        </Link>

                        <div className="card-body">
                          <h3 className="h4 mb-2 text-truncate-line-2">
                            <Link className="text-inherit">
                              How to easily create a website with React
                            </Link>
                          </h3>
                          <ul className="mb-3 list-inline">
                            <li className="list-inline-item">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-clock align-baseline"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                </svg>
                              </span>
                              3h 56m
                            </li>
                            <li className="list-inline-item">
                              <svg
                                className="me-1 mt-n1"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="3"
                                  y="8"
                                  width="2"
                                  height="6"
                                  rx="1"
                                  fill="#009688"
                                ></rect>
                                <rect
                                  x="7"
                                  y="5"
                                  width="2"
                                  height="9"
                                  rx="1"
                                  fill="#DBD8E9"
                                ></rect>
                                <rect
                                  x="11"
                                  y="2"
                                  width="2"
                                  height="12"
                                  rx="1"
                                  fill="#DBD8E9"
                                ></rect>
                              </svg>
                              Beginner
                            </li>
                          </ul>
                          <div className="lh-1">
                            <span className="align-text-top">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </span>
                            <span className="text-warning">4.5</span>
                            <span className="fs-6">(6,300)</span>
                          </div>
                        </div>

                        <div className="card-footer">
                          <div className="row align-items-center g-0">
                            <div className="col-auto">
                              <img
                                src="../assets/images/avatar/avatar-6.jpg"
                                className="rounded-circle avatar-xs"
                                alt="avatar"
                              />
                            </div>
                            <div className="col ms-2">
                              <span>Morris Mccoy</span>
                            </div>
                            <div className="col-auto">
                              <Link className="text-reset bookmark">
                                <i className="fe fe-bookmark fs-4"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                      <div className="card mb-4 card-hover">
                        <Link>
                          <img
                            src="../assets/images/course/course-graphql.jpg"
                            alt="course"
                            className="card-img-top"
                          />
                        </Link>

                        <div className="card-body">
                          <h3 className="h4 mb-2 text-truncate-line-2">
                            <Link className="text-inherit">
                              GraphQL: introduction to graphQL for beginners
                            </Link>
                          </h3>
                          <ul className="mb-3 list-inline">
                            <li className="list-inline-item">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-clock align-baseline"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                </svg>
                              </span>
                              2h 46m
                            </li>
                            <li className="list-inline-item">
                              <svg
                                className="me-1 mt-n1"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="3"
                                  y="8"
                                  width="2"
                                  height="6"
                                  rx="1"
                                  fill="#009688"
                                ></rect>
                                <rect
                                  x="7"
                                  y="5"
                                  width="2"
                                  height="9"
                                  rx="1"
                                  fill="#009688"
                                ></rect>
                                <rect
                                  x="11"
                                  y="2"
                                  width="2"
                                  height="12"
                                  rx="1"
                                  fill="#009688"
                                ></rect>
                              </svg>
                              Advance
                            </li>
                          </ul>
                          <div className="lh-1">
                            <span className="align-text-top">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </span>
                            <span className="text-warning">4.5</span>
                            <span className="fs-6">(4,300)</span>
                          </div>
                        </div>

                        <div className="card-footer">
                          <div className="row align-items-center g-0">
                            <div className="col-auto">
                              <img
                                src="../assets/images/avatar/avatar-7.jpg"
                                className="rounded-circle avatar-xs"
                                alt="avatar"
                              />
                            </div>
                            <div className="col ms-2">
                              <span>Ted Hawkins</span>
                            </div>
                            <div className="col-auto">
                              <Link className="text-reset bookmark">
                                <i className="fe fe-bookmark fs-4"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                      <div className="card mb-4 card-hover">
                        <Link>
                          <img
                            src="../assets/images/course/course-angular.jpg"
                            alt="course"
                            className="card-img-top"
                          />
                        </Link>

                        <div className="card-body">
                          <h3 className="h4 mb-2 text-truncate-line-2">
                            <Link className="text-inherit">
                              Angular - the complete guide for beginner
                            </Link>
                          </h3>

                          <ul className="mb-3 list-inline">
                            <li className="list-inline-item">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-clock align-baseline"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                </svg>
                              </span>
                              1h 30m
                            </li>
                            <li className="list-inline-item">
                              <svg
                                className="me-1 mt-n1"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="3"
                                  y="8"
                                  width="2"
                                  height="6"
                                  rx="1"
                                  fill="#009688"
                                ></rect>
                                <rect
                                  x="7"
                                  y="5"
                                  width="2"
                                  height="9"
                                  rx="1"
                                  fill="#DBD8E9"
                                ></rect>
                                <rect
                                  x="11"
                                  y="2"
                                  width="2"
                                  height="12"
                                  rx="1"
                                  fill="#DBD8E9"
                                ></rect>
                              </svg>
                              Beginner
                            </li>
                          </ul>
                          <div className="lh-1">
                            <span className="align-text-top">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </span>
                            <span className="text-warning">4.5</span>
                            <span className="fs-6">(5,410)</span>
                          </div>
                        </div>

                        <div className="card-footer">
                          <div className="row align-items-center g-0">
                            <div className="col-auto">
                              <img
                                src="../assets/images/avatar/avatar-8.jpg"
                                className="rounded-circle avatar-xs"
                                alt="avatar"
                              />
                            </div>
                            <div className="col ms-2">
                              <span>Juanita Bell</span>
                            </div>
                            <div className="col-auto">
                              <Link className="text-reset bookmark">
                                <i className="fe fe-bookmark fs-4"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                      <div className="card mb-4 card-hover">
                        <Link>
                          <img
                            src="../assets/images/course/course-python.jpg"
                            alt="course"
                            className="card-img-top"
                          />
                        </Link>

                        <div className="card-body">
                          <h3 className="h4 mb-2 text-truncate-line-2">
                            <Link className="text-inherit">
                              The Python Course: build web application
                            </Link>
                          </h3>

                          <ul className="mb-3 list-inline">
                            <li className="list-inline-item">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-clock align-baseline"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                </svg>
                              </span>
                              2h 30m
                            </li>
                            <li className="list-inline-item">
                              <svg
                                className="me-1 mt-n1"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="3"
                                  y="8"
                                  width="2"
                                  height="6"
                                  rx="1"
                                  fill="#009688"
                                ></rect>
                                <rect
                                  x="7"
                                  y="5"
                                  width="2"
                                  height="9"
                                  rx="1"
                                  fill="#009688"
                                ></rect>
                                <rect
                                  x="11"
                                  y="2"
                                  width="2"
                                  height="12"
                                  rx="1"
                                  fill="#DBD8E9"
                                ></rect>
                              </svg>
                              Intermediate
                            </li>
                          </ul>
                          <div className="lh-1">
                            <span className="align-text-top">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill text-warning"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </span>
                            <span className="text-warning">4.5</span>
                            <span className="fs-6">(9,300)</span>
                          </div>
                        </div>

                        <div className="card-footer">
                          <div className="row align-items-center g-0">
                            <div className="col-auto">
                              <img
                                src="../assets/images/avatar/avatar-9.jpg"
                                className="rounded-circle avatar-xs"
                                alt="avatar"
                              />
                            </div>
                            <div className="col ms-2">
                              <span>Claire Robertson</span>
                            </div>
                            <div className="col-auto">
                              <Link className="text-reset bookmark">
                                <i className="fe fe-bookmark fs-4"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="mb-5">
                <h2 className="mb-1">Free JavaScript lessons</h2>
                <p className="mb-0">Bite-sized learning in minutes</p>
              </div>
            </div>
          </div>
          <div className="row mb-6">
            <div className="col-lg-3 col-md-6 col-12">
              <div className="card mb-4 card-hover">
                <Link>
                  <img
                    src="../assets/images/course/course-javascript.jpg"
                    alt="course"
                    className="card-img-top"
                  />
                </Link>

                <div className="card-body">
                  <h3 className="h4 mb-2 text-truncate-line-2">
                    <Link className="text-inherit">
                      Creating a Custom Event in Javascript
                    </Link>
                  </h3>
                  <ul className="mb-3 list-inline">
                    <li className="list-inline-item">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          className="bi bi-clock align-baseline"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                        </svg>
                      </span>
                      <span>3h 56m</span>
                    </li>
                    <li className="list-inline-item">
                      <svg
                        className="me-1 mt-n1"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3"
                          y="8"
                          width="2"
                          height="6"
                          rx="1"
                          fill="#009688"
                        ></rect>
                        <rect
                          x="7"
                          y="5"
                          width="2"
                          height="9"
                          rx="1"
                          fill="#DBD8E9"
                        ></rect>
                        <rect
                          x="11"
                          y="2"
                          width="2"
                          height="12"
                          rx="1"
                          fill="#DBD8E9"
                        ></rect>
                      </svg>
                      Beginner
                    </li>
                  </ul>
                  <div className="lh-1">
                    <span className="align-text-top">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                    </span>
                    <span className="text-warning">4.5</span>
                    <span className="fs-6">(9,300)</span>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="row align-items-center g-0">
                    <div className="col-auto">
                      <img
                        src="../assets/images/avatar/avatar-3.jpg"
                        className="rounded-circle avatar-xs"
                        alt="avatar"
                      />
                    </div>
                    <div className="col ms-2">
                      <span>Morris Mccoy</span>
                    </div>
                    <div className="col-auto">
                      <Link className="text-reset bookmark">
                        <i className="fe fe-bookmark fs-4"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="card mb-4 card-hover">
                <Link>
                  <img
                    src="../assets/images/course/course-javascript.jpg"
                    alt="course"
                    className="card-img-top"
                  />
                </Link>

                <div className="card-body">
                  <h3 className="h4 mb-2 text-truncate-line-2">
                    <Link className="text-inherit">
                      Morden JavaScript Beginner Tutorial - Simple
                    </Link>
                  </h3>
                  <ul className="mb-3 list-inline">
                    <li className="list-inline-item">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          className="bi bi-clock align-baseline"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                        </svg>
                      </span>
                      <span>2h 46m</span>
                    </li>
                    <li className="list-inline-item">
                      <svg
                        className="me-1 mt-n1"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3"
                          y="8"
                          width="2"
                          height="6"
                          rx="1"
                          fill="#009688"
                        ></rect>
                        <rect
                          x="7"
                          y="5"
                          width="2"
                          height="9"
                          rx="1"
                          fill="#009688"
                        ></rect>
                        <rect
                          x="11"
                          y="2"
                          width="2"
                          height="12"
                          rx="1"
                          fill="#009688"
                        ></rect>
                      </svg>
                      Advance
                    </li>
                  </ul>
                  <div className="lh-1">
                    <span className="align-text-top">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                    </span>
                    <span className="text-warning">4.5</span>
                    <span className="fs-6">(7,800)</span>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="row align-items-center g-0">
                    <div className="col-auto">
                      <img
                        src="../assets/images/avatar/avatar-2.jpg"
                        className="rounded-circle avatar-xs"
                        alt="avatar"
                      />
                    </div>
                    <div className="col ms-2">
                      <span>Ted Hawkins</span>
                    </div>
                    <div className="col-auto">
                      <Link className="text-reset bookmark">
                        <i className="fe fe-bookmark fs-4"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="card mb-4 card-hover">
                <Link>
                  <img
                    src="../assets/images/course/course-javascript.jpg"
                    alt="course"
                    className="card-img-top"
                  />
                </Link>

                <div className="card-body">
                  <h3 className="h4 mb-2 text-truncate-line-2">
                    <Link className="text-inherit">
                      Applying JavaScript and using the console.
                    </Link>
                  </h3>
                  <ul className="mb-3 list-inline">
                    <li className="list-inline-item">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          className="bi bi-clock align-baseline"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                        </svg>
                      </span>
                      <span>1h 30m</span>
                    </li>
                    <li className="list-inline-item">
                      <svg
                        className="me-1 mt-n1"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3"
                          y="8"
                          width="2"
                          height="6"
                          rx="1"
                          fill="#009688"
                        ></rect>
                        <rect
                          x="7"
                          y="5"
                          width="2"
                          height="9"
                          rx="1"
                          fill="#DBD8E9"
                        ></rect>
                        <rect
                          x="11"
                          y="2"
                          width="2"
                          height="12"
                          rx="1"
                          fill="#DBD8E9"
                        ></rect>
                      </svg>
                      Beginner
                    </li>
                  </ul>
                  <div className="lh-1">
                    <span className="align-text-top">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                    </span>
                    <span className="text-warning">4.5</span>
                    <span className="fs-6">(8,245)</span>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="row align-items-center g-0">
                    <div className="col-auto">
                      <img
                        src="../assets/images/avatar/avatar-4.jpg"
                        className="rounded-circle avatar-xs"
                        alt="avatar"
                      />
                    </div>
                    <div className="col ms-2">
                      <span>Juanita Bell</span>
                    </div>
                    <div className="col-auto">
                      <Link className="text-reset bookmark">
                        <i className="fe fe-bookmark fs-4"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="card mb-4 card-hover">
                <Link>
                  <img
                    src="../assets/images/course/course-javascript.jpg"
                    alt="course"
                    className="card-img-top"
                  />
                </Link>

                <div className="card-body">
                  <h3 className="h4 mb-2 text-truncate-line-2">
                    <Link className="text-inherit">
                      Creating functions, using them, passing data in and out.
                    </Link>
                  </h3>
                  <ul className="mb-3 list-inline">
                    <li className="list-inline-item">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          className="bi bi-clock align-baseline"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                        </svg>
                      </span>
                      <span>2h 30m</span>
                    </li>
                    <li className="list-inline-item">
                      <svg
                        className="me-1 mt-n1"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3"
                          y="8"
                          width="2"
                          height="6"
                          rx="1"
                          fill="#009688"
                        ></rect>
                        <rect
                          x="7"
                          y="5"
                          width="2"
                          height="9"
                          rx="1"
                          fill="#009688"
                        ></rect>
                        <rect
                          x="11"
                          y="2"
                          width="2"
                          height="12"
                          rx="1"
                          fill="#DBD8E9"
                        ></rect>
                      </svg>
                      Intermediate
                    </li>
                  </ul>
                  <div className="lh-1">
                    <span className="align-text-top">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                    </span>
                    <span className="text-warning">4.5</span>
                    <span className="fs-6">(3,245)</span>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="row align-items-center g-0">
                    <div className="col-auto">
                      <img
                        src="../assets/images/avatar/avatar-5.jpg"
                        className="rounded-circle avatar-xs"
                        alt="avatar"
                      />
                    </div>
                    <div className="col ms-2">
                      <span>Claire Robertson</span>
                    </div>
                    <div className="col-auto">
                      <Link className="text-reset bookmark">
                        <i className="fe fe-bookmark fs-4"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="mb-5">
                <h2 className="mb-1">All JavaScript courses</h2>
                <p className="mb-0">
                  UTA instructors specialize in teaching the whole scope of
                  JavaScript—beginner to advanced. Whether you're interested in
                  back-end development, or app and website building, Udemy has a
                  JavaScript course for you.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <div className="card mb-4 card-hover">
                <Link>
                  <img
                    src="../assets/images/course/course-react.jpg"
                    alt="course"
                    className="card-img-top"
                  />
                </Link>

                <div className="card-body">
                  <h3 className="h4 mb-2 text-truncate-line-2">
                    <Link className="text-inherit">
                      How to easily create a website with React
                    </Link>
                  </h3>
                  <ul className="mb-3 list-inline">
                    <li className="list-inline-item">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          className="bi bi-clock align-baseline"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                        </svg>
                      </span>
                      <span>3h 56m</span>
                    </li>
                    <li className="list-inline-item">
                      <svg
                        className="me-1 mt-n1"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3"
                          y="8"
                          width="2"
                          height="6"
                          rx="1"
                          fill="#009688"
                        ></rect>
                        <rect
                          x="7"
                          y="5"
                          width="2"
                          height="9"
                          rx="1"
                          fill="#DBD8E9"
                        ></rect>
                        <rect
                          x="11"
                          y="2"
                          width="2"
                          height="12"
                          rx="1"
                          fill="#DBD8E9"
                        ></rect>
                      </svg>
                      Beginner
                    </li>
                  </ul>
                  <div className="lh-1">
                    <span className="align-text-top">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                    </span>
                    <span className="text-warning">4.5</span>
                    <span className="fs-6">(6,300)</span>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="row align-items-center g-0">
                    <div className="col-auto">
                      <img
                        src="../assets/images/avatar/avatar-6.jpg"
                        className="rounded-circle avatar-xs"
                        alt="avatar"
                      />
                    </div>
                    <div className="col ms-2">
                      <span>Morris Mccoy</span>
                    </div>
                    <div className="col-auto">
                      <Link className="text-reset bookmark">
                        <i className="fe fe-bookmark fs-4"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="card mb-4 card-hover">
                <Link>
                  <img
                    src="../assets/images/course/course-graphql.jpg"
                    alt="course"
                    className="card-img-top"
                  />
                </Link>

                <div className="card-body">
                  <h3 className="h4 mb-2 text-truncate-line-2">
                    <Link className="text-inherit">
                      GraphQL: introduction to graphQL for beginners
                    </Link>
                  </h3>
                  <ul className="mb-3 list-inline">
                    <li className="list-inline-item">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          className="bi bi-clock align-baseline"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                        </svg>
                      </span>
                      <span>2h 46m</span>
                    </li>
                    <li className="list-inline-item">
                      <svg
                        className="me-1 mt-n1"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3"
                          y="8"
                          width="2"
                          height="6"
                          rx="1"
                          fill="#009688"
                        ></rect>
                        <rect
                          x="7"
                          y="5"
                          width="2"
                          height="9"
                          rx="1"
                          fill="#009688"
                        ></rect>
                        <rect
                          x="11"
                          y="2"
                          width="2"
                          height="12"
                          rx="1"
                          fill="#009688"
                        ></rect>
                      </svg>
                      Advance
                    </li>
                  </ul>
                  <div className="lh-1">
                    <span className="align-text-top">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                    </span>
                    <span className="text-warning">4.5</span>
                    <span className="fs-6">(4,300)</span>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="row align-items-center g-0">
                    <div className="col-auto">
                      <img
                        src="../assets/images/avatar/avatar-7.jpg"
                        className="rounded-circle avatar-xs"
                        alt="avatar"
                      />
                    </div>
                    <div className="col ms-2">
                      <span>Ted Hawkins</span>
                    </div>
                    <div className="col-auto">
                      <Link className="text-reset bookmark">
                        <i className="fe fe-bookmark fs-4"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="card mb-4 card-hover">
                <Link>
                  <img
                    src="../assets/images/course/course-angular.jpg"
                    alt="course"
                    className="card-img-top"
                  />
                </Link>

                <div className="card-body">
                  <h3 className="h4 mb-2 text-truncate-line-2">
                    <Link className="text-inherit">
                      Angular - the complete guide for beginner
                    </Link>
                  </h3>
                  <ul className="mb-3 list-inline">
                    <li className="list-inline-item">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          className="bi bi-clock align-baseline"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                        </svg>
                      </span>
                      <span>1h 30m</span>
                    </li>
                    <li className="list-inline-item">
                      <svg
                        className="me-1 mt-n1"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3"
                          y="8"
                          width="2"
                          height="6"
                          rx="1"
                          fill="#009688"
                        ></rect>
                        <rect
                          x="7"
                          y="5"
                          width="2"
                          height="9"
                          rx="1"
                          fill="#DBD8E9"
                        ></rect>
                        <rect
                          x="11"
                          y="2"
                          width="2"
                          height="12"
                          rx="1"
                          fill="#DBD8E9"
                        ></rect>
                      </svg>
                      Beginner
                    </li>
                  </ul>
                  <div className="lh-1">
                    <span className="align-text-top">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                    </span>
                    <span className="text-warning">4.5</span>
                    <span className="fs-6">(5,410)</span>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="row align-items-center g-0">
                    <div className="col-auto">
                      <img
                        src="../assets/images/avatar/avatar-8.jpg"
                        className="rounded-circle avatar-xs"
                        alt="avatar"
                      />
                    </div>
                    <div className="col ms-2">
                      <span>Juanita Bell</span>
                    </div>
                    <div className="col-auto">
                      <Link className="text-reset bookmark">
                        <i className="fe fe-bookmark fs-4"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="card mb-4 card-hover">
                <Link>
                  <img
                    src="../assets/images/course/course-python.jpg"
                    alt="course"
                    className="card-img-top"
                  />
                </Link>

                <div className="card-body">
                  <h3 className="h4 mb-2 text-truncate-line-2">
                    <Link className="text-inherit">
                      The Python Course: build web application
                    </Link>
                  </h3>
                  <ul className="mb-3 list-inline">
                    <li className="list-inline-item">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          className="bi bi-clock align-baseline"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                        </svg>
                      </span>
                      <span>2h 30m</span>
                    </li>
                    <li className="list-inline-item">
                      <svg
                        className="me-1 mt-n1"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3"
                          y="8"
                          width="2"
                          height="6"
                          rx="1"
                          fill="#009688"
                        ></rect>
                        <rect
                          x="7"
                          y="5"
                          width="2"
                          height="9"
                          rx="1"
                          fill="#009688"
                        ></rect>
                        <rect
                          x="11"
                          y="2"
                          width="2"
                          height="12"
                          rx="1"
                          fill="#DBD8E9"
                        ></rect>
                      </svg>
                      Intermediate
                    </li>
                  </ul>
                  <div className="lh-1">
                    <span className="align-text-top">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                    </span>
                    <span className="text-warning">4.5</span>
                    <span className="fs-6">(9,300)</span>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="row align-items-center g-0">
                    <div className="col-auto">
                      <img
                        src="../assets/images/avatar/avatar-9.jpg"
                        className="rounded-circle avatar-xs"
                        alt="avatar"
                      />
                    </div>
                    <div className="col ms-2">
                      <span>Claire Robertson</span>
                    </div>
                    <div className="col-auto">
                      <Link className="text-reset bookmark">
                        <i className="fe fe-bookmark fs-4"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <div className="card mb-4 card-hover">
                <Link>
                  <img
                    src="../assets/images/course/course-gatsby.jpg"
                    alt="course"
                    className="card-img-top"
                  />
                </Link>

                <div className="card-body">
                  <h4 className="mb-2 text-truncate-line-2">
                    <Link className="text-inherit">
                      Gatsby JS: build blog with GraphQL and React
                    </Link>
                  </h4>
                  <ul className="mb-3 list-inline">
                    <li className="list-inline-item">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          className="bi bi-clock align-baseline"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                        </svg>
                      </span>
                      <span>3h 56m</span>
                    </li>
                    <li className="list-inline-item">
                      <svg
                        className="me-1 mt-n1"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3"
                          y="8"
                          width="2"
                          height="6"
                          rx="1"
                          fill="#009688"
                        ></rect>
                        <rect
                          x="7"
                          y="5"
                          width="2"
                          height="9"
                          rx="1"
                          fill="#DBD8E9"
                        ></rect>
                        <rect
                          x="11"
                          y="2"
                          width="2"
                          height="12"
                          rx="1"
                          fill="#DBD8E9"
                        ></rect>
                      </svg>
                      Beginner
                    </li>
                  </ul>
                  <div className="lh-1">
                    <span className="align-text-top">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                    </span>
                    <span className="text-warning">4.5</span>
                    <span className="fs-6">(9,370)</span>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="row align-items-center g-0">
                    <div className="col-auto">
                      <img
                        src="../assets/images/avatar/avatar-5.jpg"
                        className="rounded-circle avatar-xs"
                        alt="avatar"
                      />
                    </div>
                    <div className="col ms-2">
                      <span>Morris Mccoy</span>
                    </div>
                    <div className="col-auto">
                      <Link className="text-reset bookmark">
                        <i className="fe fe-bookmark fs-4"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="card mb-4 card-hover">
                <Link>
                  <img
                    src="../assets/images/course/course-javascript.jpg"
                    alt="course"
                    className="card-img-top"
                  />
                </Link>

                <div className="card-body">
                  <h4 className="mb-2 text-truncate-line-2">
                    <Link className="text-inherit">
                      JavaScript: modern javaScript from the beginning
                    </Link>
                  </h4>
                  <ul className="mb-3 list-inline">
                    <li className="list-inline-item">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          className="bi bi-clock align-baseline"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                        </svg>
                      </span>
                      <span>2h 46m</span>
                    </li>
                    <li className="list-inline-item">
                      <svg
                        className="me-1 mt-n1"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3"
                          y="8"
                          width="2"
                          height="6"
                          rx="1"
                          fill="#009688"
                        ></rect>
                        <rect
                          x="7"
                          y="5"
                          width="2"
                          height="9"
                          rx="1"
                          fill="#009688"
                        ></rect>
                        <rect
                          x="11"
                          y="2"
                          width="2"
                          height="12"
                          rx="1"
                          fill="#009688"
                        ></rect>
                      </svg>
                      Advance
                    </li>
                  </ul>
                  <div className="lh-1">
                    <span className="align-text-top">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                    </span>
                    <span className="text-warning">4.5</span>
                    <span className="fs-6">(5,245)</span>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="row align-items-center g-0">
                    <div className="col-auto">
                      <img
                        src="../assets/images/avatar/avatar-6.jpg"
                        className="rounded-circle avatar-xs"
                        alt="avatar"
                      />
                    </div>
                    <div className="col ms-2">
                      <span>Ted Hawkins</span>
                    </div>
                    <div className="col-auto">
                      <Link className="text-reset bookmark">
                        <i className="fe fe-bookmark fs-4"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="card mb-4 card-hover">
                <Link>
                  <img
                    src="../assets/images/course/course-css.jpg"
                    alt="course"
                    className="card-img-top"
                  />
                </Link>

                <div className="card-body">
                  <h4 className="mb-2 text-truncate-line-2">
                    <Link className="text-inherit">
                      CSS: ultimate CSS course from beginner to advanced
                    </Link>
                  </h4>
                  <ul className="mb-3 list-inline">
                    <li className="list-inline-item">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          className="bi bi-clock align-baseline"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                        </svg>
                      </span>
                      <span>1h 30m</span>
                    </li>
                    <li className="list-inline-item">
                      <svg
                        className="me-1 mt-n1"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3"
                          y="8"
                          width="2"
                          height="6"
                          rx="1"
                          fill="#009688"
                        ></rect>
                        <rect
                          x="7"
                          y="5"
                          width="2"
                          height="9"
                          rx="1"
                          fill="#DBD8E9"
                        ></rect>
                        <rect
                          x="11"
                          y="2"
                          width="2"
                          height="12"
                          rx="1"
                          fill="#DBD8E9"
                        ></rect>
                      </svg>
                      Beginner
                    </li>
                  </ul>
                  <div className="lh-1">
                    <span className="align-text-top">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                    </span>
                    <span className="text-warning">4.5</span>
                    <span className="fs-6">(17,000)</span>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="row align-items-center g-0">
                    <div className="col-auto">
                      <img
                        src="../assets/images/avatar/avatar-7.jpg"
                        className="rounded-circle avatar-xs"
                        alt="avatar"
                      />
                    </div>
                    <div className="col ms-2">
                      <span>Juanita Bell</span>
                    </div>
                    <div className="col-auto">
                      <Link className="text-reset bookmark">
                        <i className="fe fe-bookmark fs-4"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="card mb-4 card-hover">
                <Link>
                  <img
                    src="../assets/images/course/course-wordpress.jpg"
                    alt="course"
                    className="card-img-top"
                  />
                </Link>

                <div className="card-body">
                  <h4 className="mb-2 text-truncate-line-2">
                    <Link className="text-inherit">
                      Wordpress: complete WordPress theme &amp; plugin
                      development
                    </Link>
                  </h4>
                  <ul className="mb-3 list-inline">
                    <li className="list-inline-item">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          className="bi bi-clock align-baseline"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                        </svg>
                      </span>
                      <span>2h 30m</span>
                    </li>
                    <li className="list-inline-item">
                      <svg
                        className="me-1 mt-n1"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3"
                          y="8"
                          width="2"
                          height="6"
                          rx="1"
                          fill="#009688"
                        ></rect>
                        <rect
                          x="7"
                          y="5"
                          width="2"
                          height="9"
                          rx="1"
                          fill="#009688"
                        ></rect>
                        <rect
                          x="11"
                          y="2"
                          width="2"
                          height="12"
                          rx="1"
                          fill="#DBD8E9"
                        ></rect>
                      </svg>
                      Intermediate
                    </li>
                  </ul>
                  <div className="lh-1">
                    <span className="align-text-top">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                    </span>
                    <span className="text-warning">4.5</span>
                    <span className="fs-6">(16,500)</span>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="row align-items-center g-0">
                    <div className="col-auto">
                      <img
                        src="../assets/images/avatar/avatar-8.jpg"
                        className="rounded-circle avatar-xs"
                        alt="avatar"
                      />
                    </div>
                    <div className="col ms-2">
                      <span>Claire Robertson</span>
                    </div>
                    <div className="col-auto">
                      <Link className="text-reset bookmark">
                        <i className="fe fe-bookmark fs-4"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
