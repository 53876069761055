import axios from "axios";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../utils";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

export default function DashBoardHeader(props) {
  function handleToggle() {
    props.setToggleMenu(!props.toggleMenu);
  }
  const baseUrl = BASE_URL;
  const user = JSON.parse(localStorage.getItem("user"));

  const { setAuth } = useAuth();
  const [recording, setRecording] = useState(false);

  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };

  const commands = [
    {
      command: "light",
      callback: ({ command }) => updateUI("light", true),
      matchInterim: true,
    },
    {
      command: "dark",
      callback: ({ command }) => updateUI("dark", true),
      matchInterim: true,
    },
    {
      command: "auto",
      callback: ({ command }) => updateUI("auto", true),
      matchInterim: true,
    },
  ];

  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition({
      commands,
    });

  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      return <span>Browser doesn't support speech recognition.</span>;
    }
    let theme = localStorage.getItem("theme");
    if (!theme) {
      localStorage.setItem("theme", "light");
      theme = "light";
    }
    updateUI(theme, true);
  });

  const handleMicrophone = () => {
    if (transcript) resetTranscript();
    if (!recording) {
      SpeechRecognition.startListening({ continuous: true });
    } else {
      SpeechRecognition.abortListening();
      SpeechRecognition.stopListening();
      if (transcript.includes("light")) {
        updateUI("light", true);
      } else if (transcript.includes("dark")) {
        updateUI("dark", true);
      } else if (transcript.includes("auto")) {
        updateUI("auto", true);
      }
    }
    setRecording(!recording);
  };

  function logout() {
    localStorage.clear();
    axios
      .post(baseUrl + "/logout", {}, { headers })
      .then((response) => {
        setAuth({});
        toast.success("Logged out Successfully!");
      })
      .catch(function (error) {
        toast.error(error.response?.data?.message);
      });
  }

  const updateUI = (theme, focus = false) => {
    localStorage.setItem("theme", theme);
    const themeText = document.querySelector(".bs-theme-text");
    const themeButton = document.querySelector(
      `[data-bs-theme-value="${theme}"]`
    );

    if (themeText && themeButton) {
      document.querySelectorAll("[data-bs-theme-value]").forEach((button) => {
        button.classList.remove("active");
        button.setAttribute("aria-pressed", "false");
      });

      themeButton.classList.add("active");
      themeButton.setAttribute("aria-pressed", "true");
      themeText.textContent = themeButton.dataset.bsThemeValue;

      theme = themeButton.dataset.bsThemeValue;
      const activeIcon = document.querySelector(".theme-icon-active");
      const newIcon = document.querySelector(
        `[data-bs-theme-value="${theme}"] .theme-icon`
      );

      if (activeIcon && newIcon) {
        activeIcon.innerHTML = newIcon.outerHTML;
      }

      if (focus) {
        themeButton.focus();
      }
    }
    setTheme(theme);
  };

  const setTheme = (theme) => {
    if (
      theme === "auto" &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      document.documentElement.setAttribute("data-bs-theme", "dark");
    } else {
      document.documentElement.setAttribute("data-bs-theme", theme);
    }
  };

  return (
    <div>
      <div className="header">
        {/* navbar */}
        <nav className="navbar-default navbar navbar-expand-lg">
          <Link className="nav-toggle" onClick={handleToggle}>
            <i style={{ color: "#009688" }} className="fe fe-menu" />
          </Link>
          <div className="ms-lg-3 d-none d-md-none d-lg-block"></div>
          {/*Navbar nav */}
          <div className="ms-auto d-flex">
            <button
              className="btn btn-light btn-icon rounded-circle d-flex align-items-center"
              type="button"
              aria-expanded="false"
              aria-label="Toggle theme (auto)"
              onClick={handleMicrophone}
            >
              <i
                className={"bi bi-mic " + (recording ? " text-danger" : "")}
              ></i>
            </button>
            <ul className="navbar-nav navbar-right-wrap ms-2 d-flex nav-top-wrap">
              {/* List */}
              <li className="dropdown ms-2">
                <Link
                  className="rounded-circle"
                  role="button"
                  id="dropdownUser"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="avatar avatar-md avatar-indicators avatar-online">
                    <img
                      alt="avatar"
                      src={user.image}
                      className="rounded-circle"
                    />
                  </div>
                </Link>
                <div
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdownUser"
                >
                  <div className="dropdown-item">
                    <div className="d-flex">
                      <div className="avatar avatar-md avatar-indicators avatar-online">
                        <img
                          alt="avatar"
                          src={user.image}
                          className="rounded-circle"
                        />
                      </div>
                      <div className="ms-3 lh-1">
                        <h5 className="mb-1">{localStorage.getItem("name")}</h5>
                        <p className="mb-0">{localStorage.getItem("email")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="dropdown-divider" />
                  <ul className="list-unstyled">
                    <li>
                      <Link className="dropdown-item" to="/" onClick={logout}>
                        <i
                          style={{ color: "#009688" }}
                          className="fe fe-log-out me-2"
                        />{" "}
                        Sign Out
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
}
