import { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { BASE_URL } from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
export default function VerifyEmail() {
  const baseUrl = BASE_URL;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const url = searchParams.get("url");
  const expires = searchParams.get("expires");
  const signature = searchParams.get("signature");

  let verified = useRef(false);
  useEffect(() => {
    const apiCall = async () => {
      const parseUrl = baseUrl + "/email/verify" + url;
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };

      const verifyUrl = `${parseUrl}?expires=${expires}&signature=${signature}`;

      try {
        const response = await axios.get(verifyUrl, {
          headers,
        });
        toast.success(response.data.message);
      } catch (error) {
        toast.error(error);
      }
    };
    if (!verified.current) {
      apiCall();
      verified.current = true;
    }
  });
  return (
    <div>
      <section className="container d-flex flex-column vh-100">
        <div className="row align-items-center justify-content-center g-0 h-lg-100 py-8">
          <div className="col-lg-5 col-md-8 py-8 py-xl-0">
            {/* Card */}
            <div className="card shadow">
              {/* Card body */}
              <div className="card-body p-6">
                <div className="mb-4">
                  <Link to="/">
                    <img
                      src="../assets/images/brand/logo/logobz.png"
                      className="mb-4"
                      alt="logo-icon"
                    />
                  </Link>
                  <h1 className="mb-1 fw-bold">Email Verified</h1>
                  <p className="fw-600">
                    Thank you for verifying your email.
                    <br />
                    Your account is now activated.
                    <br />
                    Please proceed to log in to begin using your account.
                  </p>
                </div>
                {/* Form */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
